import * as documentsConst from '../actions/const/documents.const';
import * as commonConst from '../actions/const/common.const';

export const initState = {
  investmentCurrentDocuments: [],
  investmentRequiredDocuments: [],
  beginDocuments: [],
  updateDocuments: [],
  previewDocuments: [],
  uploading: false,
};

const documents = (state = initState, action) => {
  switch (action.type) {
    case commonConst.CLEAR_LOGOUT:
    case commonConst.INIT_STATE:
      return {
        ...initState,
      };

    case documentsConst.INIT_DOCUMENTS_STATE: {
      return {
        ...initState,
      };
    }
    case documentsConst.GET_REQUIRED_DOCUMENTS: {
      return {
        ...state,
        investmentRequiredDocuments: action.documentsToShow,
      };
    }
    case documentsConst.SET_CURRENT_DOCUMENTS: {
      return {
        ...state,
        investmentCurrentDocuments: action.documents,
      };
    }
    case documentsConst.SET_BEGIN_DOCUMENTS_LIST: {
      return {
        ...state,
        beginDocuments: action.documents,
      };
    }
    case documentsConst.SET_UPDATE_DOCUMENTS_LIST: {
      return {
        ...state,
        updateDocuments: action.documents,
      };
    }
    case documentsConst.SET_PREVIEW_DOCUMENTS_LIST: {
      return {
        ...state,
        previewDocuments: action.documents,
      };
    }
    case documentsConst.SET_UPLOAD_START: {
      return {
        ...state,
        uploading: true,
      };
    }
    case documentsConst.SET_UPLOAD_STOP: {
      return {
        ...state,
        uploading: false,
      };
    }
    default:
      return state;
  }
};

export default documents;
