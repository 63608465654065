export const GET_SSO_STATUS = 'SSO_GET_SSO_STATUS';
export const GET_SSO_STATUS_PENDING = 'SSO_GET_SSO_STATUS_PENDING';
export const GET_SSO_STATUS_FULFILLED = 'SSO_GET_SSO_STATUS_FULFILLED';
export const GET_SSO_STATUS_REJECTED = 'SSO_GET_SSO_STATUS_REJECTED';

export const GET_SSO_STATUS_BY_EMAIL = 'SSO_GET_SSO_STATUS_BY_EMAIL';
export const GET_SSO_STATUS_BY_EMAIL_PENDING = 'SSO_GET_SSO_STATUS_BY_EMAIL_PENDING';
export const GET_SSO_STATUS_BY_EMAIL_FULFILLED = 'SSO_GET_SSO_STATUS_BY_EMAIL_FULFILLED';
export const GET_SSO_STATUS_BY_EMAIL_REJECTED = 'SSO_GET_SSO_STATUS_BY_EMAIL_REJECTED';

export const CONNECT_SSO = 'CONNECT_SSO';
export const CONNECT_SSO_PENDING = 'CONNECT_SSO_PENDING';
export const CONNECT_SSO_FULFILLED = 'CONNECT_SSO_FULFILLED';
export const CONNECT_SSO_REJECTED = 'CONNECT_SSO_REJECTED';
