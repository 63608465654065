import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Api
import SetPassword from '../../../components/SetPassword';
import Footer from '../../../components/Footer/Footer';

// Assets
import CatalizrSquareLogo from '../../../iconsPictos/iconCatalizrSquare';

import '../styles.scss';

const Password = props => {
  useEffect(() => {
    const { initView } = props;
    initView();
  }, []);

  return (
    <div className="login__main">
      <CatalizrSquareLogo width="100" height="100" alt="Catalizr logo" title="Catalizr logo" />
      <h1>Catalizr</h1>
      <SetPassword {...props} />
      <Footer />
    </div>
  );
};

Password.propTypes = {
  // PROPERTIES
  // provided by react-router to interact the browser history API
  history: PropTypes.instanceOf(Object).isRequired,
  // provided by react-router to have the path params
  match: PropTypes.instanceOf(Object).isRequired,

  // ACTIONS
  // trigger redux action for keeping the original journey id in the URL
  setOriginalJourneyId: PropTypes.func,
  // trigger redux action to keep the email address used to login
  setUserEmail: PropTypes.func.isRequired,
  // trigger redux action to keep the current journey
  setJourney: PropTypes.func,
  // init view for get bank logo path
  initView: PropTypes.func.isRequired,
};

export default Password;
