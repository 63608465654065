import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'baseui/input';
import Tooltip from '../Tooltip';
import IconQuestionMark from '../../iconsPictos/iconQuestionMark';
import './styles.scss';
import styles from './styles';

const InputField = props => {
  const myRef = useRef();

  // called every time the input changes
  const internalOnChange = e => {
    props.onChange(e);
  };

  // called on key press to make sure the content of the field abides to a regex
  // if not the key press will do nothing
  const restrictTo = e => {
    const regex = new RegExp(props.restrictTo, 'g');
    // if the key does not pass the regex, prevent it
    if (!regex.test(e.key)) e.preventDefault();
  };

  const ifDisabledClass = props.disabled ? 'input-field__disabled' : '';
  const ifDisabledClassLabel = props.disabled
    ? 'input-field__label--disabled'
    : 'input-field__label';
  const inputWithIconClass = props.passwordCanBeShown ? 'input-field--with-icon' : '';
  const { name, register, defaultValue } = props;
  const { ...field } = props;

  return (
    <div className={`${props.divCss} ${props.topClass}`} title={props.title} ref={myRef}>
      <div className={ifDisabledClassLabel} htmlFor={props.cssId}>
        {props.label}
        {props.tooltipContent && (
          <div>
            <Tooltip
              content={props.tooltipContent}
              placement={props.placement}
              mountNode={myRef.current}
              overrides={styles.tooltip}
            >
              <IconQuestionMark
                width="28px"
                height="18px"
                title={props.tooltipTitle}
                alt={props.tooltipAlt}
              />
            </Tooltip>
          </div>
        )}
      </div>

      <div className="input-field__password-wrapper">
        <Input
          inputRef={props.innerRef}
          name={name}
          className={`input-field form-control ${ifDisabledClass} ${inputWithIconClass}`}
          data-testid={props.cssId}
          disabled={props.disabled}
          id={props.cssId}
          onBlur={props.onBlur}
          error={props.error}
          onChange={internalOnChange}
          onKeyPress={e => {
            if (props.preventEnterKeyPress) {
              e.key === 'Enter' && e.preventDefault();
            }
            restrictTo(e);
          }}
          placeholder={props.placeholder}
          type={props.type}
          value={props.initialValue}
          required={props.required}
          maxLength={props.maxLength}
          defaultValue={defaultValue}
          clearable={props.clearable}
          clearOnEscape={props.clearOnEscape}
          autoFocus={props.autoFocus}
          aria-label={props.ariaLabel}
          endEnhancer={props.endEnhancer}
          {...register(name)}
          {...field.input}
          overrides={styles.input}
        />
      </div>
      <small className={`form-text text-muted ${props.feedback.level}`}>{props.feedback.msg}</small>

      {props.errorHookFormMessage && <p className={`error`}>{props.errorHookFormMessage}</p>}
    </div>
  );
};

InputField.defaultProps = {
  topClass: '',
  placeholder: '',
  label: '',
  initialValue: undefined,
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  restrictTo: '',
  labelIcon: null,
  labelIconTitle: '',
  feedback: { level: '', msg: '' },
  passwordCanBeShown: false,
  maxLength: 255,
  preventEnterKeyPress: false,
  register: () => ({}),
  errorHookFormMessage: null,
};

InputField.propTypes = {
  innerRef: PropTypes.object,
  // CSS classes to apply on top element of the component
  topClass: PropTypes.string,
  // placeholder to add in the input field
  placeholder: PropTypes.string,
  // label to explain the content of input field
  label: PropTypes.string,
  // Icon to display next to the label
  labelIcon: PropTypes.string, // eslint-disable-line
  // Title text associated to the icon
  labelIconTitle: PropTypes.string,
  // CSS ID for this HTML input field
  cssId: PropTypes.string.isRequired,
  // type of the HTML input
  type: PropTypes.string.isRequired,
  // initialValue if you need a default one
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // called every time the input value changes
  onChange: PropTypes.func,
  // called once when we leave focus on input field
  onBlur: PropTypes.func,
  // true to disable the field
  // register your input into the hook by invoking the "register" function
  register: PropTypes.func,
  disabled: PropTypes.bool,
  // restrict to a given regex
  restrictTo: PropTypes.string,
  feedback: PropTypes.shape({
    level: PropTypes.string,
    msg: PropTypes.string,
  }),
  // props used to register the input to react form hook
  name: PropTypes.string,
  // used by react hook form to set default value
  defaultValue: PropTypes.any,
  tooltip: PropTypes.string,
  passwordCanBeShown: PropTypes.bool,
  divCss: PropTypes.string,
  title: PropTypes.string,
  preventEnterKeyPress: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  tooltipContent: PropTypes.node,
  tooltipTitle: PropTypes.string,
  tooltipAlt: PropTypes.string,
  placement: PropTypes.placement,
  error: PropTypes.bool,
  errorHookFormMessage: PropTypes.string,
  clearOnEscape: PropTypes.bool,
  clearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  ariaLabel: PropTypes.string,
  endEnhancer: PropTypes.string,
};

const ForwardRefInputField = React.forwardRef((props, ref) => (
  <InputField innerRef={ref} {...props} />
));

ForwardRefInputField.propTypes = Input.propTypes;

export default ForwardRefInputField;
