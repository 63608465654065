import * as React from 'react';
import PropTypes from 'prop-types';

const CatalizrSquareSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      id={title}
      data-name="Calque 1"
      viewBox="0 0 418.64 418.64"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <title>{title}</title>
      <path
        className="cls-1"
        d="M221.55,100.47a108.16,108.16,0,0,1,77,31.89,14.27,14.27,0,1,0,20.19-20.19,137.33,137.33,0,1,0,0,194.3,14.27,14.27,0,0,0-20.19-20.19,109,109,0,0,1-153.93,0,107.68,107.68,0,0,1-30.48-62.7h42.43a66.58,66.58,0,0,0,112.19,32.9,14.27,14.27,0,1,0-20.19-20.19c-14.42,14.42-39.55,14.42-54,0a38.17,38.17,0,0,1,54-54,14.27,14.27,0,1,0,20.19-20.19A66.49,66.49,0,0,0,156.54,195H114.11A107.88,107.88,0,0,1,221.55,100.47Z"
      />
      <path
        className="cls-1"
        d="M310.06,0H108.58A108.7,108.7,0,0,0,0,108.58V310.06A108.7,108.7,0,0,0,108.58,418.64H310.06A108.7,108.7,0,0,0,418.64,310.06V108.58A108.7,108.7,0,0,0,310.06,0Zm80,310.06a80.12,80.12,0,0,1-80,80H108.58a80.12,80.12,0,0,1-80-80V108.58a80.12,80.12,0,0,1,80-80H310.06a80.12,80.12,0,0,1,80,80V310.06Z"
      />
    </svg>
  );
};

CatalizrSquareSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CatalizrSquareSvg;
