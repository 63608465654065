import * as surveyConst from '../actions/survey.const';
import * as commonConst from '../../../shared/actions/const/common.const';

const initState = {
  hasRated: false,
  errorMessage: '',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE: {
      return initState;
    }
    case surveyConst.INIT_SURVEY_VIEW:
    case surveyConst.SURVEY_ANSWER_PENDING: {
      return {
        ...state,
        hasRated: false,
        errorMessage: '',
      };
    }
    case surveyConst.SURVEY_ANSWER_FULFILLED:
    case surveyConst.SURVEY_ANSWER_REJECTED: {
      return {
        ...state,
        hasRated: true,
        errorMessage: '',
      };
    }

    default:
      return state;
  }
};

export default reducer;
