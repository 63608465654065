import React from 'react';
import PropTypes from 'prop-types';
import IconEmailClickedSvg from './svg/icon-email-clicked-svg';
import './styles.scss';

const IconEmailClicked = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="email__clicked">
      <IconEmailClickedSvg
        className="email__clicked__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconEmailClicked.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailClicked;
