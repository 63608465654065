import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { utils, Enum } from '@utocat/catalizr-const-sharing-lib';

// Components
import IconLegalNotice from '../../iconsPictos/iconLegalNotice';
import IconSupport from '../../iconsPictos/iconSupport';
import IconUser from '../../iconsPictos/iconUser';
import IconPower from '../../iconsPictos/iconPower';
import IconHelpCircle from '../../iconsPictos/iconHelpCircle';

// Utils
import { isFeatureEnabled } from '../../shared/utils/CatalizrUtils';
import { FEATURE_FLAG } from '../../shared/utils/featureFlag';
import { FAQ_URL, ROLE_TO_NAME } from '../../shared/utils/CatalizrConstants';
import { getEntityPathFromUserType } from '../../shared/utils/user';

import { generateMailSupport } from './navbar.actions';
import './styles.scss';
import { FormattedMessage } from 'react-intl';

const NavbarAccount = () => {
  const currentUser = useSelector(state => state.user);
  const history = useHistory();
  const { email, firstname, lastname, type, title, siren, company_name } = currentUser;

  const entityPath = getEntityPathFromUserType(type);
  // Adding quick start guide pdf path according to subdomain and user type
  const displayQuickStartGuide =
    isFeatureEnabled(FEATURE_FLAG.display_quick_start_guide) ||
    type === Enum.User.Role.COMPANY_ROLE;
  const quickStartGuidePath = displayQuickStartGuide ? FAQ_URL[entityPath.toUpperCase()] : null;
  const isNotBankUser = !utils.users.isBankRole(type);

  // testing the lastname and firstname is the only way to check if the account exists in the mysql db..
  // temporary hack waiting myaccount refactoring => prevent corporation investor to access to account
  const hasAccessToAccount =
    (type === Enum.User.Role.INVESTOR_ROLE &&
      firstname &&
      lastname &&
      !utils.users.isLegalEntity(title)) ||
    type === Enum.User.Role.COMPANY_ROLE;

  let identity = '';
  // For investor : display company name and siren if he is legal person
  if (type === Enum.User.Role.INVESTOR_ROLE && utils.users.isLegalEntity(title)) {
    identity = `${company_name} (${siren})`;
  } else if (firstname && lastname) {
    identity = `${firstname} ${lastname}`;
  }

  const manageRedirection = icon => {
    let redirection = '';
    switch (icon) {
      case 'IconLegalNotice':
        return window.open('/legal-notice');
      case 'IconHelpCircle':
        return window.open(quickStartGuidePath);
      case 'IconUser':
        redirection = '/account';
        break;
      case 'IconPower':
        redirection = '/login';
        break;
    }
    return history.push(redirection);
  };

  return (
    <div className="navbar__account">
      <div className="navbar__infos">
        <div className="type">
          {ROLE_TO_NAME[type] || `${type.charAt(0).toUpperCase()}${type.slice(1)}`}
        </div>
        {isNotBankUser && <div className="identity">{identity}</div>}
        <div className="email">{email}</div>
        {isFeatureEnabled(FEATURE_FLAG.display_support_phone) && (
          <div>
            <div className="mt-1">Support Catalizr : 03 74 09 54 99</div>
          </div>
        )}
      </div>
      <div className="navbar__links">
        {displayQuickStartGuide && (
          <div className="icon" title="Guide / FAQ">
            <IconHelpCircle
              title="Aide"
              alt="Aide"
              width="30px"
              height="30px"
              onClick={() => manageRedirection('IconHelpCircle')}
            />
          </div>
        )}
        {hasAccessToAccount ? (
          <div className="icon" title="Mon compte">
            <IconUser
              title="Mon compte"
              alt="Mon compte"
              width="30px"
              height="30px"
              onClick={() => manageRedirection('IconUser')}
            />
          </div>
        ) : null}
        <div className="icon" title="Mentions Légales">
          <IconLegalNotice
            alt="Mentions Légales"
            title="Mentions Légales"
            width="30px"
            height="30px"
            onClick={() => manageRedirection('IconLegalNotice')}
          />
        </div>
        <div
          className="icon"
          title="Template Mail au Support Catalizr"
          data-testid="support_mail"
          onClick={() => generateMailSupport()}
        >
          <IconSupport
            alt="Template Mail au Support Catalizr"
            title="Template Mail au Support Catalizr"
            width="30px"
            height="30px"
          />
        </div>
      </div>
      <div
        className="navbar__disconnection"
        data-testid="disconnection"
        onClick={() => manageRedirection('IconPower')}
      >
        <IconPower alt="Déconnexion" title="Déconnexion" width="30px" height="30px" />
        <FormattedMessage id="topbar.disconnect" />
      </div>
    </div>
  );
};

export default NavbarAccount;
