export const getPlacesDetails = details => {
  const address = details.find(e => e.types[0] === 'street_number')
    ? `${details.find(e => e.types[0] === 'street_number').long_name} ${
        details.find(e => e.types[0] === 'route').long_name
      }`
    : details.find(e => e.types[0] === 'route').long_name;

  const zip = details.find(e => e.types[0] === 'postal_code')
    ? details.find(e => e.types[0] === 'postal_code').long_name
    : '';

  const country_isocode = details.find(e => e.types[0] === 'country')
    ? details.find(e => e.types[0] === 'country').short_name
    : '';

  let city = '';
  if (country_isocode === 'GB') {
    city = details.find(e => e.types[0] === 'postal_town')
      ? details.find(e => e.types[0] === 'postal_town').long_name
      : '';
  } else {
    city = details.find(e => e.types[0] === 'locality')
      ? details.find(e => e.types[0] === 'locality').long_name
      : '';
  }

  const country = details.find(e => e.types[0] === 'country')
    ? details.find(e => e.types[0] === 'country').long_name
    : '';

  return { address, zip, city, country };
};

const places = { getPlacesDetails };
export default places;
