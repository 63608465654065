export default {
  dropdown: {
    Placeholder: {
      style: () => ({
        fontSize: '14px',
      }),
    },
    SingleValue: {
      style: () => ({ fontSize: '14px' }),
    },
    Popover: {
      props: {
        overrides: {
          Body: {
            style: () => ({
              zIndex: '101',
              maxHeight: '200px',
            }),
          },
        },
      },
    },
    ControlContainer: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.white,
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        marginTop: '0px',
      }),
    },
    Tag: {
      props: {
        overrides: {
          Root: {
            style: () => ({
              outline: 'none',
            }),
          },
        },
      },
    },
  },
  bankNameDropdown: {
    Placeholder: {
      style: () => ({
        fontSize: '14px',
      }),
    },
    SingleValue: {
      style: () => ({ fontSize: '14px' }),
    },
    Popover: {
      props: {
        overrides: {
          Body: {
            style: () => ({
              zIndex: '101',
              maxHeight: '100px',
              position: 'absolute',
              top: '8px',
            }),
          },
        },
      },
    },
    DropdownContainer: {
      style: () => ({
        position: 'relative',
        top: '0px',
        left: '0px',
      }),
    },
    ControlContainer: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.white,
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        marginTop: '0px',
      }),
    },
    ValueContainer: {
      style: ({ $theme }) => ({
        backgroundColor: $theme.colors.white,
      }),
    },
    Tag: {
      props: {
        overrides: {
          Root: {
            style: () => ({
              outline: 'none',
            }),
          },
        },
      },
    },
  },
  tooltip: {
    Body: {
      style: () => ({
        width: '560px',
        marginLeft: '20px',
        opacity: 0.9,
        zIndex: '999',
      }),
    },
  },
};
