export const REDUX_FORM_MY_ACCOUNT_ID = 'account_my-account_form';
export const REDUX_FORM_PARAMETERS_ID = 'account_parameters_form';
export const MY_ACCOUNT_FORM_FIELDS_CSS_ID_PREFIX = 'account-form-';

export const INIT_FORM_PENDING = 'ACCOUNT_INIT_FORM_PENDING';
export const INIT_FORM_FULFILLED = 'ACCOUNT_INIT_FORM_FULFILLED';
export const INIT_FORM_REJECTED = 'ACCOUNT_INIT_FORM_REJECTED';

export const SUBMIT_FORM_PENDING = 'SUBMIT_ACCOUNT_FORM_PENDING';
export const SUBMIT_FORM_FULFILLED = 'SUBMIT_ACCOUNT_FORM_FULFILLED';
export const SUBMIT_FORM_REJECTED = 'SUBMIT_ACCOUNT_FORM_REJECTED';

export const MSG_ACCOUNT_SUCCESSFULLY_UPDATED = 'Vos informations ont bien été mises à jour';
export const MSG_ERROR_UPDATING_ACCOUNT =
  'Un problème est survenu lors de la mise à jour de vos données, veuillez réessayer ultérieurement';
export const MSG_ERROR_EMAIL_ALREADY_IN_USE =
  'Adresse email déjà utilisée par un autre utilisateur';
export const MSG_ERROR_IBAN_ALREADY_USED_ANOTHER_ACCOUNT =
  'Vous ne pouvez pas utiliser un même IBAN pour plusieurs types de compte';
export const TIMEOUT_BEFORE_CLEANING_MSG = 5000;
