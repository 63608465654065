import * as uploadDocumentsFormConst from '../actions/uploadDocumentsForm.const';
import { dateChecker } from '../../../components/InputField/helper';
import * as commonConst from '../../../shared/actions/const/common.const';

const defaultState = {
  newUrl: '',
  currentStep: 3,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case uploadDocumentsFormConst.INIT_VIEW: {
      return defaultState;
    }
    case uploadDocumentsFormConst.SET_CURRENT_STEP: {
      let stateToReturn = state;
      if (action.step === 1) {
        stateToReturn = { ...state, currentStep: action.step, newUrl: '/landing' };
      } else if (action.step === 2 || action.step === 3) {
        stateToReturn = { ...state, currentStep: action.step };
      } else if (action.step === 4) {
        stateToReturn = { ...state, currentStep: action.step, newUrl: '/sign-documents' };
      }
      return stateToReturn;
    }
    case uploadDocumentsFormConst.SET_NEW_URL: {
      return {
        ...state,
        newUrl: action.url,
      };
    }
    case uploadDocumentsFormConst.CHECK_INVEST_DATE: {
      const feedback = dateChecker(action.investDate);
      return {
        ...state,
        investDate: {
          value: feedback.inError ? null : action.investDate,
          feedback,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
