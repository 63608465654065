import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function mailChecker(emailValue) {
  if (!emailValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (
    !emailValue.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ) // eslint-disable-line no-useless-escape
  ) {
    return {
      level: 'error',
      msg: 'Veuillez remplir ce champ avec une adresse mail valide',
      inError: true,
    };
  }
  return { level: '', msg: '', inError: false };
}

export function integerChecker(amountValue) {
  if (!amountValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (!/^[0-9]+$/.test(amountValue)) {
    return {
      level: 'error',
      msg: 'Veuillez renseigner un nombre entier',
      inError: true,
    };
  } else if (!(amountValue > 0)) {
    return {
      level: 'error',
      msg: 'Veuillez renseigner un nombre strictement positif',
      inError: true,
    };
  }
  return { level: '', msg: '', inError: false };
}

export function amountChecker(amountValue) {
  if (!amountValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (!(amountValue > 0)) {
    return {
      level: 'error',
      msg: 'Veuillez renseigner un nombre strictement positif',
      inError: true,
    };
  } else if (!/^[0-9]+[.|,]?[0-9]{0,12}$/.test(amountValue)) {
    return {
      level: 'error',
      msg: 'Veuillez renseigner un nombre à 12 décimales maximum',
      inError: true,
    };
  }
  return { level: '', msg: '', inError: false };
}

export function nbBonsChecker(amountValue, valueType) {
  if (valueType === "Exercice de bon de souscription d'action") {
    if (!amountValue) {
      return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
    } else if (!/^[0-9]+$/.test(amountValue)) {
      return {
        level: 'error',
        msg: 'Veuillez renseigner un nombre entier',
        inError: true,
      };
    } else if (!(amountValue > 0)) {
      return {
        level: 'error',
        msg: 'Veuillez renseigner un nombre strictement positif',
        inError: true,
      };
    }
  }
  return { level: '', msg: '', inError: false };
}

export function notEmptyChecker(fieldValue) {
  if (!fieldValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  }
  return { level: '', msg: '', inError: false };
}

export function lengthChecker(fieldValue, minLen, maxLen) {
  if (!fieldValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (minLen && fieldValue.length < minLen) {
    return { level: 'error', msg: `Saisissez au minimum ${minLen} caractères`, inError: true };
  } else if (maxLen && fieldValue.length > maxLen) {
    return { level: 'error', msg: `Saisissez au maximum ${maxLen} caractères`, inError: true };
  }
  return { level: '', msg: '', inError: false };
}

export function dateChecker(dateValue) {
  if (!dateValue) {
    return { level: 'error', msg: 'Veuillez remplir ce champ', inError: true };
  } else if (!dayjs(dateValue, 'DD/MM/YYYY').isValid()) {
    return {
      level: 'error',
      msg: 'Veuillez entrer une date valide',
      inError: true,
    };
  }
  return { level: '', msg: '', inError: false };
}
