import * as investorConst from '../../../../shared/actions/const/investor.const';

// API
import { getInvestorByEmail } from '../../../../shared/api/investor.api';

// Actions
import {
  getCounters,
  getUserInvestmentsByCurrentTab,
} from '../../common/actions/dashboard.actions';
import { setOriginalJourneyId } from '../../../../redux/actions';
import { clearRedux } from '../../../../shared/actions/actions/common.actions';
import { getCurrentPortfolioType } from '../../../../shared/utils/CatalizrUtils';
import { initViewNewOperation } from '../../Bank/actions/dashboard-bank.actions';

const initInvestor = investor => {
  return {
    type: investorConst.INIT_INVESTOR,
    investor: { ...investor, currentPortfolioType: getCurrentPortfolioType() },
  };
};

export const initView = email => {
  return async dispatch => {
    dispatch(clearRedux());
    dispatch(setOriginalJourneyId(''));
    getInvestorByEmail(email).then(res => {
      if (res.data) {
        dispatch(initInvestor(res.data));
      }
    });

    // INIT MODAL NEW OPERATION
    dispatch(initViewNewOperation());

    // INIT DASHBOARD OPERATIONS
    const getDashboardCounters = await dispatch(getCounters());
    const getOperation = await dispatch(getUserInvestmentsByCurrentTab());

    Promise.all([getDashboardCounters, getOperation]);
  };
};
