import React from 'react';

import './styles.scss';

const CatalizrLoader = () => (
  <div className="loader-content">
    <div className="vertical-align-center">
      <div className="text-align-center">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <br />
        Chargement en cours...
      </div>
    </div>
  </div>
);

export default CatalizrLoader;
