import { createUrlFromDomain } from '../../components/NavbarAccount/navbar.actions';
import { getEntityPathFromUserType, User } from './user';
import Tooltip from '../../components/Tooltip';

export const OPERATION_TEMPLATES_PATH = {
  BY_BANK: 'bank-operation-templates',
  BY_COMPANY: 'company-operation-templates',
};

export const createOperationLink = (
  labelText,
  userType,
  operationUuid,
  operationBankDomain,
  tooltipContent = '',
) => {
  let url;
  let userTypeForPath = getEntityPathFromUserType(userType);
  switch (userTypeForPath) {
    case User.INVESTOR:
      url = createUrlFromDomain(
        operationBankDomain,
        `/investor/dashboard/investment/${operationUuid}`,
      );
      break;
    case User.COMPANY:
      url = createUrlFromDomain(
        operationBankDomain,
        `/company/dashboard/investment/${operationUuid}`,
      );
      break;
    case User.BANK:
      url = createUrlFromDomain(operationBankDomain, `/bank/dashboard/investment/${operationUuid}`);
      break;
    default:
      throw new Error('unknown user type : ' + userTypeForPath);
  }
  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Tooltip content={tooltipContent} placement={'right'}>
          {labelText}
        </Tooltip>
      </a>
    </div>
  );
};
