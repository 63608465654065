import React from 'react';
import PropTypes from 'prop-types';
import IconLegalNoticeSvg from './svg/icon-legal-notice-svg';
import './styles.scss';

const IconLegalNotice = props => {
  const { title, alt, width, height, onClick } = props;
  return (
    <div className="legal__notice" data-testid="legal_notice" onClick={onClick}>
      <IconLegalNoticeSvg
        className="legal__notice__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconLegalNotice.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default IconLegalNotice;
