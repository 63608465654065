export const INIT_DOCUMENTS_PENDING = 'DOCUMENTS_INIT_LIST_PENDING';
export const INIT_DOCUMENTS_FULFILLED = 'DOCUMENTS_INIT_LIST_FULFILLED';
export const INIT_DOCUMENTS_REJECTED = 'DOCUMENTS_INIT_LIST_REJECTED';
export const INIT_DOCUMENTS_STATE = 'DOCUMENTS_INIT_DOCUMENTS_STATE';

export const INIT_DOCUMENTS_ARCHIVE_PENDING = 'INIT_DOCUMENTS_ARCHIVE_PENDING';
export const INIT_DOCUMENTS_ARCHIVE_FULFILLED = 'INIT_DOCUMENTS_ARCHIVE_FULFILLED';
export const INIT_DOCUMENTS_ARCHIVE_REJECTED = 'INIT_DOCUMENTS_ARCHIVE_REJECTED';

export const GET_REQUIRED_DOCUMENTS = 'DOCUMENTS_GET_REQUIRED_DOCUMENTS';
export const SET_CURRENT_DOCUMENTS = 'DOCUMENTS_SET_CURRENT_DOCUMENTS';
export const SET_BEGIN_DOCUMENTS_LIST = 'DOCUMENTS_SET_BEGIN_DOCUMENTS_LIST';
export const SET_UPDATE_DOCUMENTS_LIST = 'DOCUMENTS_SET_UPDATE_DOCUMENTS_LIST';
export const SET_PREVIEW_DOCUMENTS_LIST = 'DOCUMENTS_SET_PREVIEW_DOCUMENTS_LIST';

export const SET_UPLOAD_START = 'DOCUMENTS_SET_UPLOAD_START';
export const SET_UPLOAD_STOP = 'DOCUMENTS_SET_UPLOAD_STOP';
export const INVESTMENT_STAGE_1 = 1;
export const INVESTMENT_STAGE_2 = 2;

export const DOCUMENT_TO_SIGN = 'signature';
export const DOCUMENT_TO_CONFIRM = 'read';
export const DOCUMENT_TO_PREVIEW = 'preview';
