export default {
  input: {
    Root: {
      style: () => ({
        marginTop: '0.4rem',
        marginBottom: '10px',
        marginRight: '0px',
        paddingRight: 0,
        borderTopWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
        borderBottomWidth: '1px',
        height: '43px',
      }),
    },
    InputContainer: {
      style: ({ $theme }) => ({ backgroundColor: $theme.colors.white, paddingRight: '5px' }),
    },
    Input: {
      style: () => ({ fontSize: '14px' }),
    },
  },
  tooltip: {
    Body: {
      style: () => ({
        width: '560px',
        marginRight: '20px',
        height: '20px',
        opacity: 0.9,
        zIndex: '999',
      }),
    },
  },
};
