export const INIT_VIEW = 'UPLOAD_DOCUMENTS_FORM_INIT_VIEW';
export const SET_CURRENT_STEP = 'UPLOAD_DOCUMENTS_FORM_SET_CURRENT_STEP';
export const SET_NEW_URL = 'UPLOAD_DOCUMENTS_FORM_SET_NEW_URL';
export const SET_PART_LEVEL = 'UPLOAD_DOCUMENTS_FORM_SET_PART_LEVEL';
export const SET_INVEST_DATE = 'UPLOAD_DOCUMENTS_FORM_SET_INVEST_DATE';
export const CHECK_INVEST_DATE = 'UPLOAD_DOCUMENTS_FORM_CHECK_INVEST_DATE';
export const SET_PHONE = 'UPLOAD_DOCUMENTS_FORM_SET_PHONE';
export const SUBMIT_FORM_PENDING = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_FORM_PENDING';
export const SUBMIT_FORM_FULFILLED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_FORM_FULFILLED';
export const SUBMIT_FORM_REJECTED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_FORM_REJECTED';
