import React from 'react';
import * as PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';

const CustomHeader = props => {
  const { sorted, sortDesc, tooltip, header } = props;
  if (sorted === false) {
    return <div title={tooltip}>{header}</div>;
  }
  return (
    <div title={tooltip}>
      {header}
      {sortDesc ? <ChevronDown /> : <ChevronUp />}
    </div>
  );
};

CustomHeader.propTypes = {
  sorted: PropTypes.bool.isRequired,
  sortDesc: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  header: PropTypes.node,
};

export default CustomHeader;
