import { get, post } from './apicaller';
import { getStore } from '../../store';

export const getBankList = () => get(`${process.env.REACT_APP_BACKEND_URL}/api/banks`, []);

export const getBankByName = name =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/${name}`, []);

export const saveBank = bank => post(`${process.env.REACT_APP_BACKEND_URL}/api/banks`, bank, []);

export const getBankListByUser = () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/by-user`, []);

export const getAgencyList = bankSubdomain => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/${bankSubdomain}/agencies`, []);
};

export const getBankKit = async subdomain => {
  let payload = await get(`${process.env.REACT_APP_BACKEND_URL}/init?subdomain=${subdomain}`, [
    404,
  ]);
  payload = { ...payload, user: getStore().getState().user };
  return payload;
};
export const getBankParams = domain => {
  let params = '';
  if (domain) {
    params = `?origin=${domain}`;
  }
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/params${params}`, [404]);
};

export const getPortfolioTypes = () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/portfolio-types`, []);

/** @function getUsersBankByBankName
 * @description Get list of user's type for a company|bank
 * @param {string} bankName
 */
export const getUsersBankByBankName = bankName => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/${bankName}/users/`, []);
};
