import { post } from '../../../shared/api/apicaller';
import { getSubdomain } from '../../../shared/utils/helper';

/**
 * Creates a esignature
 * @param {array} documents
 * @param {string} investmentLinkId
 * @param {string} signatoryType
 * @param {object} user
 * @param {string} envelope_id
 * @param {number} stage
 */
export function postSignatureLink(
  documents,
  investmentLinkId,
  signatoryType,
  user,
  envelope_id,
  stage,
) {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/api/signatures/links`,
    {
      documents,
      investment_link_id: investmentLinkId,
      signatory_type: signatoryType,
      user,
      envelope_id: envelope_id ? envelope_id : undefined,
      stage,
    },
    [],
  );
}

/**
 * Generates the docuSign envelope related to the given eSignature
 * @param {string} userEmail - connected user email
 * @param {string} esignatureId - the UUID of the esignature
 * @param {string} investmentLinkId - the UUID of the operationLink linked to the esignature
 * @param investmentUuid
 * @param signatureOffsetX
 * @param signatureOffsetY
 * @param noSignatureDate
 */
export function generateEnvelop(
  userEmail,
  esignatureId,
  investmentLinkId,
  investmentUuid,
  signatureOffsetX,
  signatureOffsetY,
  noSignatureDate = false,
) {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/api/signatures/${esignatureId}/envelope`,
    {
      // URL called back after the envelope is signed
      callback: `${window.location.origin}/result-sign?investmentLinkId=${investmentLinkId}&investmentUuid=${investmentUuid}`,
      link_id: investmentLinkId,
      signatureOffsetX,
      signatureOffsetY,
      noSignatureDate,
      userEmail,
    },
    [],
  );
}
/** Explicit call to the Docusign callback
 * used to fake a signature when at the end of a journey there is no documents left to sign
 */
export const postMockDocusign = UUID =>
  post(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/api/documents/sign?domain=${getSubdomain()}&&provider=mock`,
    {
      envelope_id: UUID,
    },
  );
