import styled from 'styled-components';

export const CustomWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center !important;
  position: ${props => props.isFullScreen === true && 'fixed'};
  z-index: ${props => props.isFullScreen === true && 9999};
  top: ${props => props.isFullScreen === true && 0};
  left: ${props => props.isFullScreen === true && 0};
  overflow: ${props => props.isFullScreen === true && 'visible'};
  margin: ${props => props.isFullScreen === true && 'auto'};
  background: ${props =>
    props.isFullScreen === true && 'linear-gradient(to top, #000000a6 20%, #000000a6 30%)'};
`;
