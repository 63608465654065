import * as registrationConst from './registration.const';
import { getUserStatusByEmail } from '../../../shared/api/user.api';
import { createAdvisor } from '../../../shared/api/advisor.api';
import { fetchBankKit } from '../../../shared/actions/actions/bank.actions';
import { clearMessagesEveryXTime } from '../../../shared/actions/actions/common.actions';

export const checkAccountIsActive = email => ({
  type: registrationConst.CHECK_ACCOUNT_IS_ACTIVE,
  payload: getUserStatusByEmail(email),
});

export const setMessage = msg => ({
  type: registrationConst.SET_MESSAGE,
  msg,
});

export const setIsLoading = () => ({
  type: registrationConst.CHECK_ACCOUNT_IS_ACTIVE_PENDING,
});

export const handleForm = (email, history, options) => dispatch => {
  dispatch(checkAccountIsActive(email))
    .then(() => {
      dispatch(clearMessagesEveryXTime(2000));
      // L'utilisateur existe, il est redirigé sur la page login
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 404') {
        // MOCHE ! Cas "success" en catch - Créer une route dédiée à cet usage dans le backend
        // L'utilisateur n'existe pas - On créé le compte
        dispatch(createAdvisorAccount(email, options))
          .then(() => {
            // Le compte est créé
            dispatch(clearMessagesEveryXTime(4000));
          })
          .catch(() => {
            // Le compte n'est pas créé
            dispatch(clearMessagesEveryXTime(10000));
          });
      } else {
        dispatch(setMessage('Le service est momentanément indisponible.'));
        dispatch(clearMessagesEveryXTime(4000));
      }
    });
};

export const createAdvisorAccount = (email, options) => ({
  type: registrationConst.CREATE_ADVISOR,
  payload: createAdvisor(email, options),
});

export const initView = (location, history) => {
  return async dispatch => {
    dispatch({ type: registrationConst.INIT_VIEW });
    const getBankDatas = await dispatch(fetchBankKit());
    const bankDatas = getBankDatas.value.data.data;
    // urlRequired is provided for 'cmcic' subdomain and equal '/portal'
    // to prevent users from trying to create account on cmcic.catalizr.eu/login
    if (bankDatas.urlRequired && bankDatas.urlRequired !== location.pathname) {
      history.push(bankDatas.urlRequired);
    }
  };
};
