export const SET_USER_EMAIL = 'USER_SET_EMAIL'; // eslint-disable-line
export const SET_USER_EMAIL_LOGIN = 'USER_SET_EMAIL_LOGIN';
export const SET_USER_DATA = 'USER_SET_DATA'; // eslint-disable-line
export const GET_USER_INFOS_FULFILLED = 'GET_USER_INFOS_FULFILLED'; // eslint-disable-line
export const GET_USER_INFOS_PENDING = 'GET_USER_INFOS_PENDING';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const ADD_THEME_TO_USER = 'ADD_THEME_TO_USER';

export const USER_LOGOUT_FULFILLED = 'USER_LOGOUT_FULFILLED';

export const SET_USER_LOCALE = 'SET_USER_LOCALE';
