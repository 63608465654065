import { get, post } from '../../../shared/api/apicaller';

export const apiRequireJwt = (email, password, options) => {
  return post(`${process.env.REACT_APP_BACKEND_URL}/login`, { email, password, ...options }, [401]);
};

export const apiResetPassword = (email, options) => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/users/password/request`,
    {
      email,
      ...options,
      // Save the url used for the reset request
      // This url is use for redirect the user after the effective reset
      redirection_url: window.location.href,
    },
    [],
  );
};

/**
 * Get feature is enable or not by feature name and user role
 * @param {string} feature [mandatory] feature set as an argument of checkFeaturetIsActive function
 * @param {string} role [mandatory] user role get by state
 * @returns true or false
 */

export const getFeatureEnableByUserRole = (feature, role) => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/banks/features/${feature}/role/${role}`, [
    404,
  ]);
};
