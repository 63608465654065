import * as React from 'react';
import PropTypes from 'prop-types';

const IconHelpCircleSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 512 512" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M256 80a176 176 0 10176 176A176 176 0 00256 80z"
      />
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={28}
        d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296"
      />
      <path
        strokeLinecap="round"
        strokeWidth={18}
        d="M270 348 A20 20 0 0 1 250 368 A20 20 0 0 1 230 348 A20 20 0 0 1 270 348 z"
      />
    </svg>
  );
};

IconHelpCircleSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconHelpCircleSvg;
