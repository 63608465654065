import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

// Reduce bundle size with suspense and its lazy component loading state management
const LazyWrapper = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
};

LazyWrapper.propTypes = {
  children: PropTypes.node,
};

export default LazyWrapper;
