import * as React from 'react';
import PropTypes from 'prop-types';

const IconSupportSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 001-1v-5.143a1 1 0 00-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 00-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z" />
    </svg>
  );
};
IconSupportSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconSupportSvg;
