import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { userInfos } from '../../shared/actions/actions/user.actions';
import { withRouter } from 'react-router-dom';

/**
 * Wrap main router to catch user and redirect this if isn't logged
 */
const User = props => {
  useEffect(() => {
    /** Initialiazing & Retrieving user informations */
    checkUser();
  }, []);

  const checkUser = () => {
    const { userInfos, user, history } = props;
    if (user.logged === true) {
      userInfos().catch(() => {
        history.push('/login?redirect=true');
      });
    }
  };

  /** Wrap router */
  const { children } = props;
  return <div>{children}</div>;
};

User.propTypes = {
  userInfos: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  children: PropTypes.node,
};

export default withRouter(
  connect(
    state => ({
      journey: state.default.journey,
      user: state.user,
    }),
    dispatch => ({
      userInfos: () => dispatch(userInfos()),
    }),
  )(User),
);
