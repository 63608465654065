import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'baseui/select';
import _ from 'lodash';

// Components
import Tooltip from '../Tooltip/index';
import IconQuestionmark from '../../iconsPictos/iconQuestionMark';

// Assets
import './styles.scss';
import styles from './styles';
import { isBankTemplate } from '../../shared/utils/JourneyUtils';

const DropdownSelect = props => {
  const myRef = useRef();

  const setValue = () => {
    const { selectedValue, selectedValueId, input, options } = props;
    if (selectedValue && selectedValue.length === 1 && Array.isArray(selectedValue[0])) {
      return selectedValue[0];
    }
    if (input && input.value) {
      if (typeof input.value === 'string' && setList().length) {
        return [setList().find(option => option.label === input.value)];
      }
      if (Array.isArray(input.value)) {
        return input.value;
      }
      return [input.value];
    }
    if (
      selectedValue &&
      selectedValue.length &&
      Array.isArray(selectedValue) &&
      typeof selectedValue === 'string'
    ) {
      return [
        {
          label: selectedValue[0],
          id: 1,
        },
      ];
    }

    if (selectedValue && typeof selectedValue === 'string' && Array.isArray(options)) {
      if (typeof options[0] === 'string') {
        return setList().filter(option => option.label === selectedValue);
      }
      return options.filter(option => option.label === selectedValue);
    }

    if (selectedValue && typeof selectedValue === 'string' && !Array.isArray(options)) {
      let grouped, ungrouped;

      if (selectedValueId) {
        grouped = options[Object.keys(options)[0]].filter(option => option.id === selectedValueId);
        ungrouped = options[Object.keys(options)[1]].filter(
          option => option.id === selectedValueId,
        );
      } else {
        grouped = options[Object.keys(options)[0]].filter(option => option.label === selectedValue);
        ungrouped = options[Object.keys(options)[1]].filter(
          option => option.label === selectedValue,
        );
      }

      if (grouped.length > 0) {
        grouped[0]['__optgroup'] = Object.keys(options)[0];
        return grouped;
      }
      ungrouped[0]['__optgroup'] = Object.keys(options)[1];
      return ungrouped;
    }

    if (
      !props.useReactHookForm &&
      props.hasDefaultValue &&
      options &&
      options.length > 0 &&
      typeof options[0] === 'string'
    ) {
      if (props.defaultValue) {
        return selectOption([{ label: props.defaultValue, id: 0 }]);
      }
      if (isBankTemplate()) {
        // no selection in template creation mode
        return null;
      }
      return selectOption([{ label: options[0], id: 0 }]);
    }
    return selectedValue;
  };

  const selectOption = value => {
    if (props.input) {
      props.input.onChange(_.get(value, `0.${props.labelKey}`, []), value[0]);
    } else {
      props.onChange(_.get(value, `0.${props.labelKey}`, []), value[0]);
    }
  };

  const sortArrayOfObjects = (a, b) => {
    const { labelKey } = props;
    const labelKeyA = a[labelKey].toUpperCase();
    const labelKeyB = b[labelKey].toUpperCase();
    let comparison = 0;
    if (labelKeyA > labelKeyB) {
      comparison = 1;
    } else if (labelKeyA < labelKeyB) {
      comparison = -1;
    }
    return comparison;
  };

  const setList = () => {
    const { sortList, labelKey, options: _options, input } = props;
    let options = _options;
    if (!_options) {
      return [];
    }
    if (_options.length && typeof _options[0] === 'string') {
      if (input && input.value && typeof input.value === 'string') {
        if (!_options.find(option => option.toUpperCase() === input.value.toUpperCase())) {
          _options.push(input.value);
        }
      }
      options = _options.map((item, index) => ({ label: item, id: index }));
    }
    if (sortList && options && options.length > 0) {
      if (labelKey) {
        return options.sort(sortArrayOfObjects);
      } else {
        return options.sort();
      }
    } else {
      return options;
    }
  };

  const handleChange = e => {
    if (props.useReactHookForm) {
      props.onChange({
        target: {
          name: props.name,
          value: e && e.value[0] && e.value[0].label ? e.value[0].label : undefined,
        },
      });
    } else {
      selectOption(e.value);
    }
  };

  const {
    label,
    placeholder,
    labelKey,
    valueKey,
    disabled,
    divCss,
    meta,
    clearable,
    filterable,
    multiple,
    addNewValue,
    dataTestid,
    errorHookFormMessage,
  } = props;

  const customStyleDisabled = disabled ? 'dropdown-select__disabled' : '';
  const displayErrorMessage = meta && meta.touched && meta.error;
  const errorMode = displayErrorMessage || errorHookFormMessage ? 'error' : '';

  return (
    <div className={`${divCss} dropdown-select ${errorMode}`} ref={myRef} data-testid={dataTestid}>
      <span className={`${customStyleDisabled} dropdown-select__label ${errorMode}`}>
        {label}
        {props.tooltipContent && (
          <div>
            <Tooltip
              content={props.tooltipContent}
              placement={props.placement}
              mountNode={myRef.current}
              overrides={styles.tooltip}
            >
              <IconQuestionmark
                width="15px"
                height="15px"
                title={props.tooltipTitle}
                alt={props.tooltipAlt}
              />
            </Tooltip>
          </div>
        )}
      </span>
      <Select
        inputRef={props.innerRef}
        value={setValue()}
        options={setList()}
        valueKey={valueKey}
        labelKey={labelKey}
        onChange={e => handleChange(e)}
        multi={multiple}
        disabled={disabled}
        placeholder={placeholder}
        overrides={label === "Numéro d'agence" ? styles.bankNameDropdown : styles.dropdown}
        clearable={clearable}
        searchable={filterable}
        creatable={addNewValue}
        mountNode={myRef.current}
        maxDropdownHeight="200px"
        error={errorMode}
      />
      {displayErrorMessage && <span className="form-text text-muted error">{meta.error}</span>}
      {errorHookFormMessage && <p className={`error`}>{errorHookFormMessage}</p>}
    </div>
  );
};

DropdownSelect.defaultProps = {
  label: '',
  labelKey: 'label',
  disabled: false,
  addNewValue: false,
  sortList: true,
  maxLengthSelection: 95,
  noResultText: 'Aucun résultat.',
  clearable: true,
  filterable: true,
  multiple: false,
  hasDefaultValue: true,
};

DropdownSelect.propTypes = {
  maxLengthSelection: PropTypes.number,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selectedValueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  input: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  sortList: PropTypes.bool,
  disabled: PropTypes.bool,
  topClass: PropTypes.string,
  dropdownHeight: PropTypes.number,
  multiple: PropTypes.bool,
  includeSelectAll: PropTypes.bool,
  divCss: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string,
  filterable: PropTypes.bool,
  valueKey: PropTypes.string,
  clearable: PropTypes.bool,
  addNewValue: PropTypes.bool,
  hasDefaultValue: PropTypes.bool,
  dataTestid: PropTypes.string,
  defaultValue: PropTypes.string,
  tooltipContent: PropTypes.node,
  placement: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipAlt: PropTypes.string,
  useReactHookForm: PropTypes.bool,
};

const ForwardRefDropdownSelect = React.forwardRef((props, ref) => (
  <DropdownSelect innerRef={ref} {...props} />
));

ForwardRefDropdownSelect.propTypes = DropdownSelect.propTypes;

export default DropdownSelect;
