// dot regex
import { string } from 'prop-types';

export const commaRegExp = new RegExp(/\./g);

// white space regex
export const whiteSpaceRegExp = new RegExp(/ /g);

// digit number regex : 0123456789
export const nineDigitRegExp = new RegExp(/([0-9]+)/, 'g');

// format thousand number : 125 000 675
export const thousandSeparatorRegExp = new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g');

// format number with 12 decimal and thousand  : 125 475,012345678912
export const nineDigitWith12decimalCommaOptionRegExp = new RegExp(
  /(([0-9]+)(,{0,1}[0-9]{0,12}){0,1})/,
  'g',
);
// chech if comma exist
export const isCommaNumberRegExp = new RegExp(/(,){1}/, 'g');

// nine digit number max regex
export const maxNineDigitNumberRegExp = new RegExp(/(^.{0,9})/, 'g');

// chech if comma exist
export const castAmountToNumber = amount =>
  +`${amount}`.replaceAll(' ', '').replaceAll(',', '.').trim();

export const castCurrencyFormatedToNumber = amount =>
  +`${amount}`.replaceAll(' ', '').replaceAll(',', '').trim();

export const removeAllSpaceIfNotEmpty = (value, defaultValue) => {
  if (typeof value !== 'string' || string === '') {
    return defaultValue;
  }
  let newValue = value.trim().replace(/\s/g, '');
  if (newValue === '') {
    return defaultValue;
  }
  return newValue;
};

export const normalizeFrenchSiren = (value, previousValue) => {
  if (value) {
    let newValue = value;
    newValue = newValue.replace(/\s/g, '');

    if (newValue.length === 0) {
      return newValue;
    }

    if (newValue.match(maxNineDigitNumberRegExp)) {
      return newValue.match(maxNineDigitNumberRegExp)[0];
    }

    return previousValue;
  } else {
    return value;
  }
};

export const normalizeAllSiren = (value, previousValue) => {
  if (value) {
    let newValue = value;
    newValue.replace(/ /g, '');

    if (newValue.length === 0) {
      return newValue;
    }

    if (newValue.match(/(^.{0,9})/)) {
      return newValue.match(/(^.{0,9})/)[0];
    }

    return previousValue;
  } else {
    return value;
  }
};

export const normalizeZip = (value, previousValue) => {
  let newValue = value;
  newValue.replace(/ /g, '');

  if (newValue.length === 0) {
    return newValue;
  }

  if (newValue.match(/([0-9]{0,5})/)) {
    return newValue.match(/([0-9]{0,5})/)[0];
  }

  return previousValue;
};

export const normalizePart = (value, previousValue, formValues) => {
  let newValue = value;
  if (
    formValues &&
    (formValues.investment_nb_part_patch ||
      formValues.company_kind_provider_contributed_nb_part_patch)
  ) {
    newValue = newValue.toString();
    newValue = newValue.replace(commaRegExp, ',');
    newValue = newValue.replace(whiteSpaceRegExp, '');
  }

  if (newValue.length === 0) {
    return newValue;
  }

  // chech and format part number of investment
  if (
    formValues &&
    (formValues.investment_nb_part_patch ||
      formValues.company_kind_provider_contributed_nb_part_patch) &&
    newValue.match(nineDigitRegExp)
  ) {
    // keep the first match of the correspondence table
    newValue = newValue.match(nineDigitRegExp)[0];
    return newValue.replace(thousandSeparatorRegExp, ' ');
    // chech and format part amount of investment
  }

  // company_kind_provider_investment_nb_part_patch
  return previousValue;
};

export const normalizeInteger = value => {
  const newValue = value.replace(/[^0-9.]/g, '');
  return newValue.replace(thousandSeparatorRegExp, ' ');
};

export const normalizeDecimal = value => {
  let newValue = value;
  newValue = newValue.toString();
  newValue = newValue.replace(commaRegExp, ',');
  newValue = newValue.replace(whiteSpaceRegExp, '');
  if (newValue.length === 0) {
    return newValue;
  }
  if (newValue.match(nineDigitWith12decimalCommaOptionRegExp)) {
    newValue = newValue.match(nineDigitWith12decimalCommaOptionRegExp)[0];

    if (newValue.match(isCommaNumberRegExp)) {
      let splitThousand = newValue.split(',');
      let formatThousand = splitThousand[0].replace(thousandSeparatorRegExp, ' ');
      return formatThousand + ',' + splitThousand[1];
    } else {
      return newValue.replace(thousandSeparatorRegExp, ' ');
    }
  }
};

export const normalizePartAmount = (value, previousValue, formValues) => {
  let newValue = value;

  if (
    formValues &&
    (formValues.investment_part_amount_patch || formValues.company_kind_provider_compensation_patch)
  ) {
    newValue = newValue.toString();
    newValue = newValue.replace(commaRegExp, ',');
    newValue = newValue.replace(whiteSpaceRegExp, '');
  }

  if (newValue.length === 0) {
    return newValue;
  }

  // chech and format part number of investment
  if (
    formValues &&
    (formValues.investment_part_amount_patch ||
      formValues.company_kind_provider_compensation_patch) &&
    newValue.match(nineDigitWith12decimalCommaOptionRegExp)
  ) {
    // keep the first match of the correspondence table
    newValue = newValue.match(nineDigitWith12decimalCommaOptionRegExp)[0];
    if (newValue.match(isCommaNumberRegExp)) {
      let splitThousand = newValue.split(',');
      let formatThousand = splitThousand[0].replace(thousandSeparatorRegExp, ' ');
      return formatThousand + ',' + splitThousand[1];
    } else {
      return newValue.replace(thousandSeparatorRegExp, ' ');
    }
  }
  return previousValue;
};
