// Const
import * as investorConst from '../actions/const/investor.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';
import * as commonConst from '../actions/const/common.const';
import * as dashboardConst from '../../pages/dashboard/common/const/dashboard.const';

const initState = {
  id: '',
  email: '',
  surname: '',
  name: '',
  title: '',
  address: '',
  city: '',
  country: 'France',
  phone: '',
  zip: '',
  iban: '',
};

const buyer = (state = initState, action) => {
  switch (action.type) {
    case investorConst.RESET_STATE:
    case 'CLEAR_EVERYTHING':
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case commonConst.INIT_STATE:
    case landingConst.INIT_VIEW_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
      return initState;

    case landingConst.CLEAR_DATA: {
      return {
        ...initState,
        email: state.email,
      };
    }

    case investorConst.SET_INVESTOR_EMAIL:
      return { ...state, email: action.email };

    case investorConst.INIT_INVESTOR:
      return {
        ...state,
        address: action.investor.address,
        city: action.investor.city,
        country: action.investor.country,
        email: action.investor.email,
        id: action.investor.id,
        name: action.investor.name,
        phone: action.investor.phone,
        surname: action.investor.surname,
        title: action.investor.title,
        zip: action.investor.zip,
        iban: action.investor.iban,
      };

    case dashboardConst.INIT_INVESTOR: {
      return {
        ...state,
        id: action.investor.id,
        email: action.investor.email,
        name: action.investor.name,
        surname: action.investor.surname,
      };
    }

    case investDetailsConst.SET_INVESTOR_DATA:
      return { ...state, ...action.investor };

    default:
      return state;
  }
};

export default buyer;
