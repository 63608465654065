import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

/**
 *
 * @param {*} props
 * @param {string} props.labelText - Checkbox label string
 * @param {function} [props.onChange] - Method to get checked on change (return checked value)
 * @param {boolean} [props.isDefaultChecked] - Set default checked
 */

const InputCheckbox = props => {
  const { labelText, defaultChecked, onChange, divCss, disabled } = props;

  const sizeClassname = props.size ? `checkbox--${props.size}` : '';
  const disabledClassname = disabled ? 'checkbox--disabled' : '';
  return (
    <div className={`checkbox ${sizeClassname} ${disabledClassname} ${divCss}`}>
      <label className="checkbox__label">
        <input
          {...props.input}
          className="checkbox__input"
          type="checkbox"
          onChange={event => {
            if (onChange) {
              onChange(event.target);
            }
            if (props.input && props.input.onChange) {
              props.input.onChange(event.target);
            }
          }}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
        <span>{labelText}</span>
      </label>
    </div>
  );
};

InputCheckbox.propTypes = {
  labelText: PropTypes.string.isRequired,
  isDefaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
  defaultChecked: PropTypes.bool,
  divCss: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
};

InputCheckbox.defaultProps = {
  size: 'medium',
};

export default InputCheckbox;
