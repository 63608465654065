import { utils } from '@utocat/catalizr-const-sharing-lib';

// list the possible states for a user account
export const possibleAccountStatus = {
  NOT_CHECKED: 'not checked',
  DOES_NOT_EXIST: 'does not exist',
  INACTIVE: 'inactive',
  ACTIVE: '',
};

export const entityPathFromUserType = {
  investor: 'investors',
  company: 'companies',
  bank: 'user-bank',
};

export const User = {
  INVESTOR: 'investor',
  COMPANY: 'company',
  BANK: 'bank',
};

export const getEntityPathFromUserType = type => {
  if (utils.users.isUserRole(type) === false) {
    return User.BANK;
  }
  if (utils.users.isBankRole(type)) {
    return User.BANK;
  }
  return type;
};

export const getEntityPathApiFromUserType = type => {
  if (utils.users.isUserRole(type) === false) {
    return entityPathFromUserType.bank;
  }
  if (utils.users.isBankRole(type)) {
    return entityPathFromUserType.bank;
  }
  return entityPathFromUserType[type];
};
