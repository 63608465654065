// Const
import * as bankConst from '../const/bank.const';

// API
import { getBankKit, getBankList, getAgencyList, getUsersBankByBankName } from '../../api/bank.api';

// Helper
import { getSubdomain } from '../../utils/helper';

export const fetchBankKit = (subdomain = getSubdomain()) => ({
  type: bankConst.GET_BANK_KIT,
  payload: getBankKit(subdomain),
});

export const fetchUsersBank = (bankName = getSubdomain()) => ({
  type: bankConst.GET_USERS_BANK_LIST,
  payload: getUsersBankByBankName(bankName),
});

export const fetchBankList = () => ({
  type: bankConst.GET_BANK_LIST,
  payload: getBankList(),
});

export const fetchAgencyList = () => {
  return {
    type: bankConst.GET_BANK_AGENCY_LIST,
    payload: getAgencyList(getSubdomain()),
  };
};

export const setBankKit = bankKit => ({ type: bankConst.SET_BANK_KIT, bankKit });
export const setBankList = bankList => ({ type: bankConst.SET_BANK_LIST, bankList });
export const setAgencyList = agencyList => ({ type: bankConst.SET_BANK_AGENCY_LIST, agencyList });
export const setCurrentBankAgency = currentBankAgency => ({
  type: bankConst.SET_CURRENT_BANK_AGENCY,
  currentBankAgency,
});
