import { get, post, put } from './apicaller';

export const getInvestorById = id =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${id}`, []);

export const getInvestorByUuidFromShareholding = investorUuid =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${investorUuid}/shareholding`, []);

export const updateInvestor = (id, body) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/api/investors/${id}`, body, []);

export const createInvestor = body =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/investors`, body, []);

export const getInvestorByEmail = (email, allDomains = false) => {
  // This param is provided if we don't know the subdomain linked to the investor email we want to check
  const allDomainsParamProvided = allDomains ? '?alldomains=true' : '';
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/investors/email/${email}${allDomainsParamProvided}`,
    [404, 409],
  );
};

export const checkSuggestedOperations = (advisorEmail, investorEmail) => {
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/draft/check?investorEmail=${investorEmail}&advisorEmail=${advisorEmail}`,
    [404],
  );
};

export const getInvestorAccountsByEmail = (email, draftDomain = null) => {
  const params = new URLSearchParams();
  Object.entries({ draftDomain }).forEach(([key, value]) => {
    if (['string', 'object', 'number'].includes(typeof value)) {
      params.set(key, value);
    }
  });
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/investors/${email}/accounts?${params.toString()}`,
    [404],
  );
};
