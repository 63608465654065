// Const
import * as commonConst from '../const/common.const';

// Api
import { getPotentialPlacesByAddress, getInstrumentTypes } from '../../api/common.api';

export const setPotentialPlaces = potentialPlaces => ({
  type: commonConst.SET_POTENTIAL_PLACES,
  potentialPlaces,
});

export const setIsLoading = isLoading => ({
  type: commonConst.SET_IS_LOADING,
  isLoading,
});

export const setDraftMode = () => ({
  type: commonConst.SET_DRAFT_MODE,
});

export const setEditionDraftMode = value => ({
  type: commonConst.SET_DRAFT_MODE_EDITION,
  payload: value,
});

export const clearDraftMode = () => ({
  type: commonConst.CLEAR_DRAFT_MODE,
});

export const getPotentialPlaces = value => ({
  type: commonConst.GET_POTENTIAL_PLACES,
  payload: getPotentialPlacesByAddress(value),
});

export const clearRedux = () => ({ type: commonConst.CLEAR_REDUX });
export const clearLogout = () => ({ type: commonConst.CLEAR_LOGOUT });

export const setInformationMessage = message => ({
  type: commonConst.SET_INFORMATION_MESSAGE,
  message,
});

export const setErrorMessage = message => ({
  type: commonConst.SET_ERROR_MESSAGE,
  message,
});

export const clearInformationMessages = () => ({ type: commonConst.CLEAR_MESSAGES });

export const clearMessagesEveryXTime = time => dispatch => {
  setTimeout(() => {
    dispatch(clearInformationMessages());
  }, time);
};

export const initState = () => ({ type: commonConst.INIT_STATE });

export const setGoNext = goNext => ({
  type: commonConst.SET_GO_NEXT,
  goNext,
});

export const getCurrenciesList = currencies => ({
  type: commonConst.GET_CURRENCIES,
  currencies,
});

export const getCountriesList = countries => ({
  type: commonConst.GET_COUNTRIES,
  countries,
});

export const getAllBanners = banners => ({
  type: commonConst.GET_BANNERS,
  banners,
});

export const getInstrumentTypesList = () => ({
  type: commonConst.GET_INSTRUMENT_TYPES,
  payload: getInstrumentTypes(),
});

export const refreshTemplateDocumentList = documentList => ({
  type: commonConst.REFRESH_TEMPLATE_DOCUMENT_LIST,
  documentList,
});

export const editedTemplateDocument = document => ({
  type: commonConst.EDITED_TEMPLATE_DOCUMENT,
  document,
});

export const refreshCustomDocumentList = documentList => ({
  type: commonConst.REFRESH_CUSTOM_DOCUMENT_LIST,
  documentList,
});

export const refreshCustomFieldList = customFieldList => ({
  type: commonConst.REFRESH_CUSTOM_FIELD_LIST,
  customFieldList,
});
