import React from 'react';
import PropTypes from 'prop-types';
import IconBellSvg from './svg/icon-bell-svg';
import './styles.scss';

const IconBell = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="bell">
      <IconBellSvg className="bell__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconBell.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconBell;
