import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomSpinner from './components';
import * as Styled from './styled';

const SpinnerWrapper = props => {
  const { children, isLoading, isFullScreen, description } = props;
  return isLoading ? (
    <Styled.CustomWrapper data-testid="parent" isFullScreen={isFullScreen}>
      <CustomSpinner data-testid="child" />
      {description && <span>{description}</span>}
    </Styled.CustomWrapper>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

SpinnerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool,
  description: PropTypes.string,
};

export default SpinnerWrapper;
