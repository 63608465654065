import React from 'react';
import PropTypes from 'prop-types';

import './banner.scss';
import { Info, AlertTriangle, AlertCircle } from 'react-feather';

const Banner = props => {
  const createMarkup = html => {
    return { __html: html };
  };

  const getBannerIcon = criticality => {
    let bannerIcon = <Info size={42} />;
    switch (criticality) {
      case 'INFO':
        bannerIcon = <Info size={42} />;
        break;

      case 'DANGER':
        bannerIcon = <AlertCircle size={42} />;
        break;

      case 'WARNING':
        bannerIcon = <AlertTriangle size={42} />;
        break;

      default:
        break;
    }
    return bannerIcon;
  };

  const { bannerTitle, bannerMessage, bannerCriticality } = props;
  const bannerCriticalityStyle = bannerCriticality ? `banner--${bannerCriticality}` : '';
  let bannerIcon = getBannerIcon(bannerCriticality);

  return (
    <div className={`banner ${bannerCriticalityStyle}`}>
      <div className="banner__icon">{bannerIcon}</div>
      <div className="banner__content">
        <h1 className="banner__title">{bannerTitle}</h1>
        <div
          className="banner__message"
          dangerouslySetInnerHTML={createMarkup(bannerMessage)}
        ></div>
      </div>
    </div>
  );
};

Banner.defaultProps = {
  bannerCriticality: 'INFO',
  bannerTitle: '',
  bannerMessage: '',
};

Banner.propTypes = {
  bannerCriticality: PropTypes.oneOf(['INFO', 'WARNING', 'DANGER']).isRequired,
  bannerTitle: PropTypes.string.isRequired,
  bannerMessage: PropTypes.string.isRequired,
};

export default Banner;
