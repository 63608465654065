import React from 'react';
import PropTypes from 'prop-types';

const IconEmailOpenedSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M21.03 6.29L12 .64 2.97 6.29C2.39 6.64 2 7.27 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-.73-.39-1.36-.97-1.71M20 18H4v-8l8 5 8-5v8m-8-5L4 8l8-5 8 5-8 5z" />
    </svg>
  );
};

IconEmailOpenedSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailOpenedSvg;
