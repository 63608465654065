import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Footer from '../../../components/Footer/Footer';
import DropdownSelect from '../../../components/DropdownSelect';
import Button from '../../../components/Button';
import SpinnerWrapper from '../../../components/Spinner';

// Utils
import { getNewBankUrl } from '../../../shared/utils/helper';

// Assets
import CatalizrSquareLogo from '../../../iconsPictos/iconCatalizrSquare';

import '../styles.scss';

const Portal = props => {
  const [banks, setBanks] = useState([]);
  const [initFulfilled, setInitFulfilled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);
  const [isSSOEnabledForGroup, setIsSSOEnabledForGroup] = useState(false);
  const [logo, setLogo] = useState('');
  const [selectedBank, setSelectedBank] = useState([]);
  const [ssoRedirectUrl, setSSORedirectUrl] = useState('');
  const [query] = useState(new URLSearchParams(location.search));

  useEffect(() => {
    (async () => {
      try {
        const portalResult = await props.fetchBankKit();
        const ssoInfos = await props.fetchSSOStatus();
        const portalData = portalResult.value.data.data;
        const ssoData = ssoInfos.value.data.data;

        if (portalResult.value.status === 200) {
          if (portalData.bankUrl.length) {
            setBanks(portalData.bankUrl);
            setSelectedBank([portalData.bankUrl[0]]);
          }
          setLogo(portalData.bank.logo);

          if (portalData.isSSOEnabledForGroup) {
            setIsSSOEnabledForGroup(portalData.isSSOEnabledForGroup);
            setSSORedirectUrl(ssoData.redirectUrl || '');
            setLogo(portalData.groupLogo);
          }
        }

        if (ssoInfos.value.status === 200 && ssoData.enabled) {
          if (ssoData.roles.includes('advisor')) {
            setIsSSOEnabled(ssoData.enabled);
            setSSORedirectUrl(ssoData.redirectUrl || '');
          }
        }

        setIsLoading(false);
      } catch (error) {
        setInitFulfilled(false);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="portal__content">
      {query.get('error') ? (
        <div
          className="login__redirect-feedback alert-danger"
          data-testid="login__redirect-feedback"
        >
          {query.get('error')}
        </div>
      ) : null}

      <div className="login__main">
        <CatalizrSquareLogo width="100" height="100" alt="Catalizr logo" title="Catalizr logo" />
        <h1>Catalizr</h1>
        <div className="login__form">
          {initFulfilled ? (
            <SpinnerWrapper isLoading={isLoading}>
              {banks?.length > 0 ? (
                <DropdownSelect
                  cssId="portal__inputdrop-bank"
                  label="Choisissez votre banque"
                  labelKey="name"
                  valueKey="url"
                  filterable={false}
                  divCss="col w-2"
                  topClass="portal__dropdown"
                  options={banks}
                  selectedValue={selectedBank}
                  onChange={elem => {
                    setSelectedBank(banks.filter(x => x.name === elem));
                  }}
                  clearable={false}
                />
              ) : (
                logo && (
                  <img
                    className="bank-logo"
                    src={`${process.env.REACT_APP_BACKEND_URL}/api${logo}`}
                    alt="bank logo"
                  />
                )
              )}

              {(isSSOEnabled || isSSOEnabledForGroup) && ssoRedirectUrl ? (
                <div className="buttons">
                  <Button
                    text="Cliquer ici pour vous connecter"
                    cssId="portal__button"
                    topClass="portal__button"
                    type="button"
                    design="blue"
                    onClick={() => {
                      window.location.assign(ssoRedirectUrl);
                    }}
                  />
                </div>
              ) : (
                <div className="buttons">
                  <Button
                    text="Créer un compte"
                    cssId="portal__button"
                    topClass="portal__button"
                    type="button"
                    design="blue"
                    onClick={() => {
                      // Redirect to create account
                      window.location.assign(
                        selectedBank?.length > 0
                          ? getNewBankUrl(selectedBank[0].url, '/create/account')
                          : '/create/account',
                      );
                    }}
                  />

                  <Button
                    text="Se connecter"
                    cssId="portal__button"
                    topClass="portal__button connect-button"
                    type="button"
                    design="blue"
                    onClick={() => {
                      // Redirect to login
                      window.location.assign(
                        selectedBank?.length > 0
                          ? getNewBankUrl(selectedBank[0].url, '/login')
                          : '/login',
                      );
                    }}
                  />
                </div>
              )}
            </SpinnerWrapper>
          ) : (
            <div className="portal__error-message">
              Un problème est survenu, essayez de rafraîchir cette page. Si le problème persiste,
              n’hésitez pas à vous rapprocher de notre support :{' '}
              <a href="mailto:support@catalizr.eu">support@catalizr.eu</a>.
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

Portal.propTypes = {
  fetchBankKit: PropTypes.func.isRequired,
  fetchSSOStatus: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default Portal;
