import React from 'react';
import PropTypes from 'prop-types';
import IconEmailOpenedSvg from './svg/icon-email-opened-svg';
import './styles.scss';

const IconEmailOpened = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="email__opened">
      <IconEmailOpenedSvg
        className="email__opened__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconEmailOpened.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailOpened;
