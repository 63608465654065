import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import defaultReducer from './redux/reducer';
import dashboardReducer from './pages/dashboard/common/reducers/dashboard.reducer';
import loginReducer from './pages/login/reducers/login.reducer';
import surveyReducer from './pages/survey/reducers/survey.reducers';
import registrationReducer from './pages/registration/reducers/registration.reducer';
import sharedReducers from './shared/reducers';
import sharedReducersModals from './shared/modals/addSharedDoc/reducers';
import uploadDocumentsFormReducer from './pages/upload-documents-form/reducers/uploadDocumentsForm.reducer';
import InvestDetailsFormReducer from './pages/invest-details/reducers/invest-details.reducer';
import investmentsBulkReducer from './pages/investments-bulk/reducers/investments-bulk.reducer';
import dividendCouponReducer from './pages/dividends-coupons/reducers/dividend-payment-declaration.reducer';
import fundAppealReducer from './pages/funds/fund-appeal/reducers/fund-appeal.reducer';

const rootFormReducer = combineReducers({
  default: defaultReducer,
  dashboard: dashboardReducer,
  form: formReducer,
  login: loginReducer,
  registration: registrationReducer,
  uploadDocumentsForm: uploadDocumentsFormReducer,
  survey: surveyReducer,
  investDetails: InvestDetailsFormReducer,
  ...sharedReducers,
  ...sharedReducersModals,
  investmentsBulk: investmentsBulkReducer,
  dividendCoupon: dividendCouponReducer,
  fundAppeal: fundAppealReducer,
});

export default rootFormReducer;
