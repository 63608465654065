import { formatFloatValue } from '../../../shared/utils/CatalizrFormUtils';
import { castCurrencyFormatedToNumber } from '../../../shared/utils/normalize';
import {
  GET_DIVIDEND_PAYMENT_LIST,
  GET_DIVIDEND_PAYMENT_LIST_PENDING,
  GET_DIVIDEND_PAYMENT_LIST_FULFILLED,
  GET_DIVIDEND_PAYMENT_LIST_REJECTED,
  UPDATE_DIVIDEND_PAYMENT_POSITIONS_FIELD,
  UPDATE_DIVIDEND_PAYMENT_GROUPED_COUNTER_FIELD,
  UPDATE_DIVIDEND_PAYMENT_COST_TO_PAY,
  POST_DIVIDEND_PAYMENT_CONFIRMATION_PENDING,
  POST_DIVIDEND_PAYMENT_CONFIRMATION_FULFILLED,
  POST_DIVIDEND_PAYMENT_CONFIRMATION_REJECTED,
  RESET_DECLARATION,
  GET_DIVIDEND_PAYMENT_HISTORY_PENDING,
  GET_DIVIDEND_PAYMENT_HISTORY_FULFILLED,
  GET_DIVIDEND_PAYMENT_HISTORY_REJECTED,
  GET_DIVIDEND_PAYMENT_HISTORY,
  GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_PENDING,
  GET_DIVIDEND_PAYMENT_HISTORY_DETAIL,
  GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_FULFILLED,
  GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_REJECTED,
} from '../const/dividend-payment-declaration';

import CurrencyFormatter from 'currency-formatter';

import { groupBy } from 'lodash';

const defaultState = {
  collection: [],
  groupedInvestorCounters: [],
  history: {
    collections: [],
    isLoading: false,
    error: null,
    limit: 10,
    page: 1,
    total: 1,
  },
  historyDetail: {
    collections: [],
    isLoading: false,
    error: null,
    limit: 10,
    page: 1,
    total: 1,
  },
  limit: 10,
  page: 1,
  total: 1,
  isLoading: false,
  error: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_DIVIDEND_PAYMENT_LIST:
      return { ...state, ...action.payload };
    case GET_DIVIDEND_PAYMENT_LIST_PENDING:
    case POST_DIVIDEND_PAYMENT_CONFIRMATION_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DIVIDEND_PAYMENT_LIST_FULFILLED: {
      const groupedByTypeCategory = groupBy(state.collection, el =>
        el.fund_isin
          ? `${el.part_type};${el.category};${el.fund_isin}`
          : `${el.part_type};${el.category}`,
      );

      return {
        ...state,
        groupedInvestorCounters: Object.keys(groupedByTypeCategory).map((group, index) => {
          const nb_investor = groupedByTypeCategory[group].reduce((acc, currentValue) => {
            if (!acc.includes(currentValue.investor_name_surname)) {
              return [...acc, currentValue.investor_name_surname];
            }
            return acc;
          }, []).length;

          return {
            id: index + 1,
            part_type: groupedByTypeCategory[group][0].part_type,
            category: groupedByTypeCategory[group][0].category,
            fund_isin: groupedByTypeCategory[group][0].fund_isin,
            fund_name: groupedByTypeCategory[group][0].fund_name,
            part_currency: groupedByTypeCategory[group][0].part_currency,
            nb_investor,
          };
        }),
        isLoading: false,
        error: null,
      };
    }
    case POST_DIVIDEND_PAYMENT_CONFIRMATION_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case GET_DIVIDEND_PAYMENT_LIST_REJECTED:
    case POST_DIVIDEND_PAYMENT_CONFIRMATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: 'Une erreur est survenue.',
      };
    case UPDATE_DIVIDEND_PAYMENT_GROUPED_COUNTER_FIELD:
      return {
        ...state,
        groupedInvestorCounters: state.groupedInvestorCounters.map(row =>
          row.id === action.payload.id
            ? {
                ...row,
                ...action.payload.value,
              }
            : row,
        ),
      };
    case UPDATE_DIVIDEND_PAYMENT_POSITIONS_FIELD:
      return {
        ...state,
        collection: state.collection.map(row =>
          row.id === action.payload.id
            ? {
                ...row,
                ...action.payload.value,
              }
            : row,
        ),
      };
    case UPDATE_DIVIDEND_PAYMENT_COST_TO_PAY: {
      const collection = state.collection.map(c => {
        const currentAmountPerPart = state.groupedInvestorCounters.find(
          g => g.part_type === c.part_type && g.category === c.category,
        );

        if (
          currentAmountPerPart.amount_per_part &&
          (!currentAmountPerPart.currency || currentAmountPerPart.currency !== '%')
        ) {
          return {
            ...c,
            amount_to_deposit: CurrencyFormatter.format(
              formatFloatValue(
                +castCurrencyFormatedToNumber(currentAmountPerPart.amount_per_part) *
                  c.confirmed_quantity,
              ),
              {
                code: currentAmountPerPart.part_currency,
              },
            ),
          };
        }

        if (
          currentAmountPerPart.amount_per_part &&
          currentAmountPerPart.currency === '%' &&
          c.part_amount
        ) {
          return {
            ...c,
            amount_to_deposit: CurrencyFormatter.format(
              formatFloatValue(
                ((+castCurrencyFormatedToNumber(currentAmountPerPart.amount_per_part) *
                  c.part_amount) /
                  100) *
                  c.confirmed_quantity,
              ),
              {
                code: currentAmountPerPart.part_currency,
              },
            ),
          };
        }

        return c;
      });

      return {
        ...state,
        collection,
      };
    }
    case RESET_DECLARATION:
      return {
        ...state,
        collection: state.collection.map(c => ({
          ...c,
          amount_to_deposit: undefined,
          confirmed_quantity: c.quantity,
        })),
        groupedInvestorCounters: state.groupedInvestorCounters.map(g => ({
          ...g,
          amount_per_part: undefined,
          date_deposit: undefined,
        })),
      };

    // For history
    case GET_DIVIDEND_PAYMENT_HISTORY_PENDING:
      return {
        ...state,
        history: {
          isLoading: true,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          ...action?.payload,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY_FULFILLED:
      return {
        ...state,
        history: {
          ...state.history,
          isLoading: false,
          error: null,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY_REJECTED:
      return {
        ...state,
        history: {
          isLoading: false,
          error: 'Une erreur est survenue',
        },
      };

    // For history detail
    case GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        historyDetail: {
          isLoading: true,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY_DETAIL:
      return {
        ...state,
        historyDetail: {
          ...state.historyDetail,
          ...action?.payload,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_FULFILLED:
      return {
        ...state,
        historyDetail: {
          ...state.historyDetail,
          isLoading: false,
          error: null,
        },
      };
    case GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_REJECTED:
      return {
        ...state,
        historyDetail: {
          isLoading: false,
          error: 'Une erreur est survenue',
        },
      };

    default:
      return state;
  }
};

export default reducer;
