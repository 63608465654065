import * as React from 'react';
import PropTypes from 'prop-types';

const IconAlertTriangleSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      {...props}
    >
      <title>{title}</title>
      <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01" />
    </svg>
  );
};

IconAlertTriangleSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconAlertTriangleSvg;
