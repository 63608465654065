export const SET_JOURNEY = 'SET_JOURNEY';
export const SET_INVESTMENT_LINK_ID = 'SET_INVESTMENT_LINK_ID';
export const SET_DOCUMENTS_TO_UPLOAD = 'SET_DOCUMENTS_TO_UPLOAD';
export const RESET_DOCUMENTS_MISSING = 'RESET_DOCUMENTS_MISSING';
export const SET_DOCUMENT_MISSING = 'SET_DOCUMENT_MISSING';
export const UNSET_DOCUMENT_MISSING = 'UNSET_DOCUMENT_MISSING';
export const SET_ORIGINAL_JOURNEY_ID = 'SET_ORIGINAL_JOURNEY_ID';
export const SET_INTERNAL_SERVER_ERROR = 'SET_INTERNAL_SERVER_ERROR';
export const SET_SOCKET_ID = 'SET_SOCKET_ID';
export const SET_UPLOAD_ATTESTATION_LETTER = 'SET_UPLOAD_ATTESTATION_LETTER';
