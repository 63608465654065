import * as movementOrderConst from '../actions/const/movement-order.const';
import { STATUS_CODE_TO_VALIDATE } from '../utils/CatalizrConstants';

const initState = {
  data: [],
  totalItems: 0,
  totalItemsToValidate: 0,
};

const movementOrders = (state = initState, action) => {
  switch (action.type) {
    case movementOrderConst.SET_MOVEMENT_ORDER_LIST_FULFILLED:
      return {
        ...state,
        data: action.payload.data || [],
        totalItems: action.payload.data?.length || 0,
        totalItemsToValidate: action.payload.data?.filter(movementOrder =>
          STATUS_CODE_TO_VALIDATE.includes(movementOrder.statusCode),
        ).length,
      };
    default:
      return state;
  }
};

export default movementOrders;
