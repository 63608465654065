import { createIntl, createIntlCache } from 'react-intl';
import { intlFlattenTexts } from './shared/utils/intl-locale';

const intlCache = createIntlCache();

export let intl = createIntl(
  {
    locale: 'fr',
    defaultLocale: 'fr',
    messages: {},
  },
  intlCache,
);

const loadMessagesInLocale = async locale => {
  const messages = await import(`./locales/${locale}.json`);
  return intlFlattenTexts(messages.default);
};

export const updateIntl = async locale => {
  const messages = await loadMessagesInLocale(locale);
  intl = createIntl(
    {
      locale,
      messages,
      defaultLocale: 'fr',
    },
    intlCache,
  );
  return intl;
};

// for test only
export const resetIntl = () => {
  intl = createIntl(
    {
      locale: 'fr',
      defaultLocale: 'fr',
      messages: {},
    },
    intlCache,
  );
};
