import * as investmentsBulkConst from '../actions/investments-bulk.const';
import * as commonConst from '../../../shared/actions/const/common.const';

const defaultState = {
  isLoading: false,
  step: 0,
  esignatureId: '',
  esignatureEnvelopeUrl: '',
  filtersOptions: {
    companies: [],
    operation_types: [],
    banks: [],
    status: [],
    categories: [],
  },
  filtersSelected: {
    companies: '',
    operation_types: '',
    banks: '',
    status: '',
    categories: '',
  },
  operationsList: { data: [], totalItems: 0 },
  operationsOnCurrentPage: [],
  rowsSelectedByCheckbox: {},
  selectAll: false,
  documentsList: [],
  currentPage: 1,
  perPage: 50,
  currentSort: {
    id: '',
    sort: 'asc',
  },
  errorMessage: '',
  bulkForm: {
    open_date: '',
    planned_close_date: '',
    part_level: 100,
    close_date: '',
    ownership_date: '',
  },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case commonConst.CLEAR_REDUX:
    case investmentsBulkConst.BULK_INIT_VIEW:
      return {
        ...defaultState,
      };
    case investmentsBulkConst.BULK_OPERATIONS_SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case investmentsBulkConst.BULK_OPERATIONS_SET_PER_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
        perPage: action.perPage,
      };
    case investmentsBulkConst.BULK_OPERATIONS_SET_CURRENT_SORT:
      return {
        ...state,
        currentSort: action.currentSort,
      };
    case investmentsBulkConst.BULK_GET_DYNAMIC_FILTERS_REJECTED:
      return {
        ...state,
        errorMessage:
          'Erreur durant la récupération des filtres, veuillez réessayer ultérieurement',
      };
    case investmentsBulkConst.BULK_GET_DYNAMIC_FILTERS_FULFILLED:
      return {
        ...state,
        filtersOptions: action.filters,
        errorMessage: '',
      };
    case investmentsBulkConst.BULK_SET_FILTERS_SELECTED:
      return {
        ...state,
        filtersSelected: action.filters,
      };
    case investmentsBulkConst.BULK_POST_FILTERS_FULFILLED:
      return {
        ...state,
        operationsList: {
          ...state.operationsList,
          data: action.list.operations,
          totalItems: action.list.totalItems,
        },
        errorMessage: '',
        bulkForm: defaultState.bulkForm,
        documentsList: [],
      };
    case investmentsBulkConst.BULK_POST_FILTERS_REJECTED:
      return {
        ...state,
        errorMessage:
          'Erreur lors de la récupérations des investissements, veuillez réessayer ultérieurement ou contacter le support : support@catalizr.eu',
        operationsList: { data: [], totalItems: 0 },
        operationsOnCurrentPage: [],
        rowsSelectedByCheckbox: {},
        currentPage: 1,
        selectAll: false,
      };
    case investmentsBulkConst.BULK_RESET_OPERATIONS_LIST:
      return {
        ...state,
        operationsList: { data: [], totalItems: 0 },
        operationsOnCurrentPage: [],
        rowsSelectedByCheckbox: {},
        currentPage: 1,
        selectAll: false,
        bulkForm: defaultState.bulkForm,
        documentsList: [],
      };
    case investmentsBulkConst.BULK_SET_SELECT_ALL_CHECKBOXES:
      return {
        ...state,
        selectAll: action.selectAll,
      };
    case investmentsBulkConst.BULK_SET_SELECTED_LIST:
      return {
        ...state,
        rowsSelectedByCheckbox: action.rowsSelected,
        bulkForm: defaultState.bulkForm,
        documentsList: [],
      };
    case investmentsBulkConst.BULK_SET_STEP:
      return {
        ...state,
        step: action.step,
        bulkForm: defaultState.bulkForm,
        errorMessage: '',
      };
    case investmentsBulkConst.BULK_GET_DOCUMENTS_LIST_PENDING:
    case investmentsBulkConst.BULK_GENERATE_SIGNATURE_ENVELOPE_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case investmentsBulkConst.BULK_GET_DOCUMENTS_LIST_FULFILLED:
      return {
        ...state,
        documentsList: action.documentsList,
        isLoading: false,
      };
    case investmentsBulkConst.BULK_DISPLAY_REDIRECTION:
      return {
        ...state,
        displayDocusignRedirection: action.displayDocusignRedirection,
      };
    case investmentsBulkConst.BULK_GET_DOCUMENTS_LIST_REJECTED:
    case investmentsBulkConst.BULK_CREATE_ESIGNATURE_REJECTED:
    case investmentsBulkConst.BULK_GENERATE_SIGNATURE_ENVELOPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage:
          'Erreur lors du chargement, veuillez réessayer ultérieurement ou contacter le support : support@catalizr.eu',
      };
    case investmentsBulkConst.BULK_CREATE_ESIGNATURE_FULFILLED:
      return {
        ...state,
        esignatureId: action.esignatureId,
      };
    case investmentsBulkConst.BULK_SUBMIT_FORM_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case investmentsBulkConst.BULK_SUBMIT_FORM_REJECTED:
      return {
        ...state,
        isLoading: false,
      };
    case investmentsBulkConst.BULK_SUBMIT_FORM_FULFILLED: {
      // Avoid duplicate generated documents
      let totalDocs = state.documentsList.filter(doc => doc.origin === 'upload');
      totalDocs = totalDocs.concat(action.generatedDocuments);
      return {
        ...state,
        bulkForm: action.form,
        isLoading: false,
        documentsList: totalDocs,
      };
    }
    case investmentsBulkConst.BULK_GENERATE_SIGNATURE_ENVELOPE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        esignatureEnvelopeUrl: action.envelopeUrl,
      };
    default:
      return state;
  }
};

export default reducer;
