import { getStore } from '../../store';
import axios from 'axios';

// To desactivate bugReport if needed
const bugReportIsActive = false;

export const bugReport = err => {
  if (bugReportIsActive && err) {
    // Object returned to api when a bug is detected in front
    let body = {
      store: getStore().getState(),
      error: err.toString(),
      errorResponse: err.response,
      url: window.location.href,
      userAgent: navigator.userAgent,
    };

    // Retry to send bug report to support@catalizr 5 times max every 5 seconds.
    const maxRetry = 5;

    // Retry to send bug report if catch err
    const axiosRetry = (err, nbRetry) => {
      if (err) {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/front/bugreport`, body).catch(err => {
          setTimeout(() => {
            if (nbRetry < maxRetry) {
              nbRetry += 1;
              axiosRetry(err, nbRetry);
            }
          }, 5000);
        });
      }
    };

    // Api call to send bug report to ZenDesk
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/front/bugreport`, body).catch(err => {
      // If PAYLOAD IS TOO LARGE, we send just user state from store
      if (err.response.status === 413) {
        body.store = { user: getStore().getState().user };
      }
      // Retry to send bug report to support@catalizr 5 times max every 5 seconds.
      axiosRetry(err, 0);
    });
  }
};
