import React from 'react';
import PropTypes from 'prop-types';
import IconSupportSvg from './svg/icon-support-svg';
import './styles.scss';

const IconSupport = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="support">
      <IconSupportSvg
        className="support__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconSupport.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconSupport;
