// Const
import * as defaultConst from './const';

export const setJourney = journeyName => {
  return { type: defaultConst.SET_JOURNEY, journeyName };
};
export const setInvestmentLinkId = id => ({ type: defaultConst.SET_INVESTMENT_LINK_ID, id });
export const setDocumentsToUpload = documents => ({
  type: defaultConst.SET_DOCUMENTS_TO_UPLOAD,
  documents,
});
export const resetDocumentsMissing = () => ({ type: defaultConst.RESET_DOCUMENTS_MISSING });
export const setDocumentMissing = documentType => ({
  type: defaultConst.SET_DOCUMENT_MISSING,
  documentType,
});
export const unsetDocumentMissing = documentType => ({
  type: defaultConst.UNSET_DOCUMENT_MISSING,
  documentType,
});
export const setOriginalJourneyId = id => ({ type: defaultConst.SET_ORIGINAL_JOURNEY_ID, id });
export const setInternalError = () => ({ type: defaultConst.SET_INTERNAL_SERVER_ERROR });
export const setSocketId = newId => ({ type: defaultConst.SET_SOCKET_ID, newId });

export const setUploadAttestationLetter = uploadAttestationLetter => ({
  type: defaultConst.SET_UPLOAD_ATTESTATION_LETTER,
  uploadAttestationLetter,
});
