export default {
  tooltip: {
    Arrow: {
      style: () => ({}),
    },
    Body: {
      style: () => ({
        maxWidth: '500px',
        maxHeight: '190px',
        display: 'flex',
        JustifyContent: 'center',
        alignItems: 'center',
        border: '1px solid white',
      }),
    },
    Inner: {
      style: () => ({
        fontSize: '13px',
      }),
    },
  },

  block: {
    Block: {
      style: () => ({}),
    },
  },
};
