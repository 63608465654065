import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Login from '../components/Login';
import {
  checkAccountIsActive,
  resetPassword,
  initView,
  onSubmit,
  displayRedirectedAccessDenied,
  displayRedirectedUnavailable,
  setIsLoading,
  setErrorMessage,
  clearMessages,
  setBanners,
  checkFeatureIsActive,
  addThemeToUser,
} from '../actions/login.actions';
import { fetchBankKit } from '../../../shared/actions/actions/bank.actions';
import {
  setUserEmailLogin,
  userInfos,
  userLogout,
  getUserPending,
} from '../../../shared/actions/actions/user.actions';
import { setJourney } from '../../../redux/actions';

const Container = props => {
  const state = useSelector(state => ({
    login: state.login,
    journey: state.default.journey,
    banners: state.common.banners,
  }));
  const dispatch = useDispatch();
  return (
    <Login
      {...props}
      {...state}
      setUserEmailLogin={email => dispatch(setUserEmailLogin(email))}
      checkAccountIsActive={email => dispatch(checkAccountIsActive(email))}
      checkFeatureIsActive={role => dispatch(checkFeatureIsActive(role))}
      resetPassword={(email, captcha) => dispatch(resetPassword(email, captcha))}
      initView={(location, history) => dispatch(initView(location, history))}
      logout={() => dispatch(userLogout())}
      userInfos={() => dispatch(userInfos())}
      setJourney={journey => dispatch(setJourney(journey))}
      onSubmit={(email, password, history, location, options) =>
        dispatch(onSubmit(email, password, history, location, options))
      }
      displayRedirectedAccessDenied={() => dispatch(displayRedirectedAccessDenied())}
      displayRedirectedUnavailable={() => dispatch(displayRedirectedUnavailable())}
      fetchBankKit={() => dispatch(fetchBankKit())}
      setAccountIsLoading={() => dispatch(getUserPending())}
      setResetPasswordIsLoading={() => dispatch(setIsLoading())}
      setErrorMessage={message => dispatch(setErrorMessage(message))}
      clearMessages={() => dispatch(clearMessages())}
      getAllBanners={() => dispatch(setBanners())}
      addThemeToUser={data => dispatch(addThemeToUser(data))}
    />
  );
};

export default withRouter(Container);
