import React from 'react';
import PropTypes from 'prop-types';
import IconFlagSvg from './svg/icon-flag-svg';
import './styles.scss';

const IconFlag = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="flag">
      <IconFlagSvg className="flag__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconFlag.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconFlag;
