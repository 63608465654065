import React from 'react';
import PropTypes from 'prop-types';
import IconEmailDeliveredSvg from './svg/icon-email-delivered-svg';
import './styles.scss';

const IconEmailDelivered = props => {
  const { title, alt, width, height, active } = props;
  return (
    <div className={active ? 'email__delivered__active' : 'email__delivered'}>
      <IconEmailDeliveredSvg
        className={active ? 'email__delivered__active__icon' : 'email__delivered__icon'}
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconEmailDelivered.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default IconEmailDelivered;
