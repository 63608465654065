import PropTypes from 'prop-types';
import { useEffect } from 'react';

const SSO = ({ history, connectSSOActive }) => {
  useEffect(() => {
    connectSSOActive(history);
  }, []);
};

SSO.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,

  // ACTIONS
  connectSSOActive: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default SSO;
