const initState = {
  fundShareList: [],
};

// TODO to delete if not used by Guy
const fundReducer = (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default fundReducer;
