import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Portal from '../components/Portal';

import { fetchBankKit } from '../../../shared/actions/actions/bank.actions';
import { fetchSSOStatus } from '../../../shared/actions/actions/sso.actions';

const mapDispatchToProps = dispatch => ({
  fetchBankKit: () => dispatch(fetchBankKit()),
  fetchSSOStatus: () => dispatch(fetchSSOStatus()),
});

export default withRouter(connect(null, mapDispatchToProps)(Portal));
