export const GET_BANK_LIST = 'BANK_GET_BANK_LIST';
export const GET_BANK_LIST_PENDING = 'BANK_GET_BANK_LIST_PENDING';
export const GET_BANK_LIST_FULFILLED = 'BANK_GET_BANK_LIST_FULFILLED';
export const GET_BANK_LIST_REJECTED = 'BANK_GET_BANK_LIST_REJECTED';
export const SET_BANK_LIST = 'BANK_SET_BANK_LIST';

export const GET_BANK_KIT = 'BANK_GET_BANK_KIT';
export const GET_BANK_KIT_PENDING = 'BANK_GET_BANK_KIT_PENDING';
export const GET_BANK_KIT_FULFILLED = 'BANK_GET_BANK_KIT_FULFILLED';
export const GET_BANK_KIT_REJECTED = 'BANK_GET_BANK_KIT_REJECTED';
export const SET_BANK_KIT = 'BANK_SET_BANK_KIT';

export const SET_BANK_AGENCY_LIST = 'BANK_SET_BANK_AGENCY_LIST';
export const GET_BANK_AGENCY_LIST = 'BANK_GET_BANK_AGENCY_LIST';
export const GET_BANK_AGENCY_LIST_FULFILLED = 'BANK_GET_BANK_AGENCY_LIST_FULFILLED';
export const GET_BANK_AGENCY_LIST_PENDING = 'BANK_GET_BANK_AGENCY_LIST_PENDING';
export const GET_BANK_AGENCY_LIST_REJECTED = 'BANK_GET_BANK_AGENCY_LIST_REJECTED';
export const SET_CURRENT_BANK_AGENCY = 'BANK_SET_CURRENT_BANK_AGENCY';

export const GET_USERS_BANK_LIST = 'BANK_GET_USERS_BANK_LIST';
export const GET_USERS_BANK_LIST_PENDING = 'BANK_GET_USERS_BANK_LIST_PENDING';
export const GET_USERS_BANK_LIST_FULFILLED = 'BANK_GET_USERS_BANK_LIST_FULFILLED';
export const GET_USERS_BANK_LIST_REJECTED = 'BANK_GET_USERS_BANK_LIST_REJECTED';
