import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cgu from '../components/CGUCatalizr';

const Container = props => {
  const state = useSelector(state => ({
    user: state.user,
    bank: state.bank,
  }));
  return <Cgu {...props} {...state} />;
};

export default withRouter(Container);
