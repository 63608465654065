import { intl } from '../../intlConfig';

export const useIntl = () => intl;

/**
 * Transform nested object to flat object useful for internationalization
 *
 * @param {object} nestedMessages - Nested object with all displayed texts
 * @param {string} prefix
 * @returns {object} New flat object with all displayed texts
 */
export function intlFlattenTexts(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, intlFlattenTexts(value, prefixedKey));
    }

    return messages;
  }, {});
}
