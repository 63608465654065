import React from 'react';
import PropTypes from 'prop-types';
import CatalizrSquareSvg from './svg/icon-catalizr-square-svg';
import './styles.scss';

const IconCatalizrSquare = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="catalizr__logo__square">
      <CatalizrSquareSvg
        className="catalizr__logo__square__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconCatalizrSquare.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconCatalizrSquare;
