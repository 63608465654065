import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
/**
 * FIXME 344 Ko included in minified main.js because of this
 * Launch npm run build, then npm run analyseBuild to see content of minified files
 * Can be resolved with react lazy components : https://legacy.reactjs.org/docs/code-splitting.html#reactlazy
 */
import * as Icon from 'react-feather';

const Button = ({ icon, cssId, style, topClass, design, text, ...props }) => {
  // use the proper css class according to the 'design' prop
  const designClass = design ? `button__style-${design}` : 'button__style-secondary';
  const ButtonIcon = typeof icon === 'string' ? Icon[icon] : icon;
  return (
    <button
      className={`${designClass} ${topClass} button__style-basic`}
      id={cssId}
      style={style}
      data-testid={cssId}
      {...props}
    >
      {icon && (
        <span className="button__icon">{typeof icon === 'string' ? <ButtonIcon /> : icon}</span>
      )}
      <span>{text}</span>
    </button>
  );
};

Button.defaultProps = {
  cssId: null,
  design: 'secondary',
  disabled: false,
  icon: null,
  onClick: () => {},
  onMouseEnter: () => {},
  topClass: '',
  type: 'button',
};

Button.propTypes = {
  cssId: PropTypes.string, // cssId to identify the button
  design: PropTypes.string, // the style to use for this button
  disabled: PropTypes.bool, // true to disable the button
  form: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func, // function to call on click
  onMouseEnter: PropTypes.func, // function to call when the mouse enters the button
  style: PropTypes.object,
  text: PropTypes.string.isRequired, // text to display on the button
  topClass: PropTypes.string, // css classes to apply on that button
  type: PropTypes.string, // Use to set type submit
};

export default Button;
