import bank from './bank.reducer';
import buyer from './buyer.reducer';
import common from './common.reducer';
import company from './company.reducer';
import documents from './documents.reducer';
import financialBroker from './financial-broker.reducer';
import funds from './fund.reducer';
import investment from './investment.reducer';
import investor from './investor.reducer';
import libraryDocument from './library.reducer';
import movementOrders from './movement-order.reducer';
import sso from './sso.reducer';
import tableParams from './table-params.reducer';
import user from './user.reducer';

const reducers = {
  bank,
  buyer,
  common,
  company,
  documents,
  financialBroker,
  funds,
  investment,
  investor,
  libraryDocument,
  movementOrders,
  sso,
  tableParams,
  user,
};

export default reducers;
