// Const
import * as companyConst from '../const/company.const';

// Api
import { getPlaceDetailsById } from '../../api/common.api';
import {
  getCompaniesListByName,
  getCompaniesListBySiren,
  getInseeCompanyDatasBySiren,
} from '../../api/company.api';

export const getCompanyDetails = id => ({
  type: companyConst.GET_COMPANY_DETAILS,
  payload: getPlaceDetailsById(id),
});

export const getPotentialCompaniesByName = (name, sirenAvailable) => ({
  type: companyConst.GET_COMPANY_POTENTIAL_PLACES,
  payload: getCompaniesListByName(name, sirenAvailable),
});

export const getPotentialCompaniesBySiren = siren => ({
  type: companyConst.GET_COMPANY_POTENTIAL_PLACES,
  payload: getCompaniesListBySiren(siren),
});

export const getCompanyInseeDatasBySiren = siren => ({
  type: companyConst.GET_COMPANY_DATA_FROM_INSEE,
  payload: getInseeCompanyDatasBySiren(siren),
});

export const setAutocompleteInseeSirenSuccess = value => ({
  type: companyConst.SET_COMPANY_AUTOCOMPLETE_INSEE_SIREN,
  value,
});

export const setCeasedCompanyAlert = isCeasedCompany => ({
  type: companyConst.SET_COMPANY_CEASED_INSEE_SIREN,
  isCeasedCompany,
});

export const setCompanyData = company => ({ type: companyConst.SET_COMPANY_DATA, company });
export const setCompanyKindProviderData = companyKindProvider => ({
  type: companyConst.SET_COMPANY_KIND_PROVIDER_DATA,
  companyKindProvider,
});

export const setPotentialCompanies = potentialCompanies => ({
  type: companyConst.SET_COMPANY_POTENTIAL_PLACES,
  potentialCompanies,
});

export const setSelectedCompany = suggestion => ({
  type: companyConst.SET_SELECTED_COMPANY,
  suggestion,
});

export const resetSelectedCompany = () => ({
  type: companyConst.RESET_SELECTED_COMPANY,
});

export const setCompanyBossPhone = phone => ({
  type: companyConst.SET_COMPANY_BOSS_PHONE,
  phone,
});

export const setCompanyUserPhone = phone => ({
  type: companyConst.SET_COMPANY_USER_PHONE,
  phone,
});

export const setSiren = siren => ({
  type: companyConst.SET_COMPANY_SIREN,
  siren,
});

export const resetIdCompany = () => ({
  type: companyConst.RESET_ID_COMPANY,
});

export const resetCompanyData = () => ({
  type: companyConst.RESET_COMPANY_DATA,
});

export const setLegalFormsList = legalForms => ({
  type: companyConst.SET_COMPANIES_LEGAL_FORMS,
  legalForms,
});

export const setBossStatusList = bossStatus => ({
  type: companyConst.SET_COMPANIES_BOSS_STATUS,
  bossStatus,
});
