import { createTheme } from 'baseui';
import styles from '../../scss/_export.scss';

function parseScssVar(scssStr) {
  if (!scssStr || typeof scssStr !== typeof '') {
    return scssStr;
  }

  // Lists and maps are surrounded by single quotes, e.g. "'[ \"string in list\", 5, \"5px\" ]'"
  // Remove them if they exist so they can be parsed correctly.
  const jsValue = scssStr.replace(/(^['"])|(['"]$)/g, '');

  try {
    // JSON-formatted string from within SCSS file
    return JSON.parse(jsValue);
  } catch (errorParsingJsonGeneratedInUtilScssFile) {
    try {
      // Value was likely an SCSS literal string; attempt parsing it manually.
      // Example: inspect($my-map) => '(num: 10, numWithUnits: 5px, str: hello, color: #fff, "keyAsStr": false, other: null)'
      return JSON.parse(
        scssStr
          .replace('(', '{')
          .replace(')', '}')
          // JSON values: convert any collection of word characters followed by a comma or bracket to a string
          .replace(/: ?([^,}]+)([,}])/g, ': "$1"$2')
          // JSON keys: space/bracket/comma as first character, not already a string, anything not colon or
          // space (rules out JSON values), ended by colon
          .replace(/([\s{,])(?!")([^:\s]+)+:/g, '$1"$2":'),
      );
    } catch (errorParsingScssStringLiteral) {
      return jsValue;
    }
  }
}

const primitives = {
  default: parseScssVar(styles.default),
  shared: parseScssVar(styles.shared),
  ca: parseScssVar(styles.ca),
};

export const default_theme = createTheme(
  {
    primaryFontFamily: 'Poppins',
    primary: primitives.default['primary-color'],
  },
  {
    borders: {
      inputBorderRadius: primitives.default['button-radius'],
    },
    colors: {
      borderSelected: primitives.default['primary-color'],
      inputBorder: primitives.default['primary-color'],
      inputFill: primitives.default['white'],
      inputFillActive: primitives.default['white'],
      inputPlaceholder: primitives.shared['grey-extra-light'],
      inputPlaceholderDisabled: primitives.shared['grey-extra-light'],
      menuFontDefault: primitives.default['primary-color'],
      menuFontHighlighted: primitives.default['primary-color'],
      menuFontSelected: primitives.default['primary-color'],
      tooltipBackground: primitives.default['primary-color'],
      tooltipText: primitives.shared['white'],
    },
  },
);
export const CA_theme = createTheme(
  {
    primaryFontFamily: 'Poppins',
    primary: primitives.ca['primary-color'],
  },
  {
    borders: {
      inputBorderRadius: primitives.ca['button-radius'],
    },
    colors: {
      borderSelected: primitives.ca['primary-color'],
      inputBorder: primitives.ca['primary-color'],
      inputFill: primitives.default['white'],
      inputFillActive: primitives.default['white'],
      inputPlaceholder: primitives.shared['grey-extra-light'],
      inputPlaceholderDisabled: primitives.shared['grey-extra-light'],
      menuFontDefault: primitives.ca['primary-color'],
      menuFontHighlighted: primitives.ca['primary-color'],
      menuFontSelected: primitives.ca['primary-color'],
      tooltipBackground: primitives.ca['primary-color'],
      tooltipText: primitives.ca['grey-200'],
    },
  },
);
