import * as libraryConst from '../actions/const/library.const';
import * as commonConst from '../actions/const/common.const';

const initState = {
  documents: [],
};

const libraryReducer = (state = initState, action) => {
  switch (action.type) {
    case libraryConst.SET_LIBRARY_DOCUMENT_LIST:
      return {
        documents: action.documents,
      };
    case libraryConst.SET_LIBRARY_DOCUMENT_DELETE:
      return {
        documents: state.documents.filter(doc => {
          return doc.uuid !== action.payload.documentId ? doc : null;
        }),
      };
    case libraryConst.SET_LIBRARY_DOCUMENT_ADD_OR_UPDATE:
      return {
        documents: state.documents.find(doc => (doc && doc.name) === action.payload.document.name)
          ? state.documents.map(doc => {
              return doc.name === action.payload.document.name ? action.payload.document : doc;
            })
          : [...state.documents, action.payload.document],
      };
    case commonConst.CLEAR_LOGOUT_PENDING:
      return {
        documents: [],
      };
    default:
      return state;
  }
};

export default libraryReducer;
