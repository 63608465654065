import React from 'react';
import PropTypes from 'prop-types';
import IconMoneySvg from './svg/icon-money-svg';
import './styles.scss';

const IconMoney = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="money">
      <IconMoneySvg className="money__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconMoney.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconMoney;
