import * as React from 'react';
import PropTypes from 'prop-types';

const IconPowerSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 16 16" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M7.5 1v7h1V1h-1z" />
      <path d="M3 8.812a4.999 4.999 0 012.578-4.375l-.485-.874A6 6 0 1011 3.616l-.501.865A5 5 0 113 8.812z" />
    </svg>
  );
};
IconPowerSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconPowerSvg;
