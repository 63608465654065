export const CHECK_ACCOUNT_IS_ACTIVE = 'LOGIN_CHECK_ACCOUNT_IS_ACTIVE';
export const CHECK_ACCOUNT_IS_ACTIVE_PENDING = 'LOGIN_CHECK_ACCOUNT_IS_ACTIVE_PENDING';
export const CHECK_ACCOUNT_IS_ACTIVE_FULFILLED = 'LOGIN_CHECK_ACCOUNT_IS_ACTIVE_FULFILLED';
export const CHECK_ACCOUNT_IS_ACTIVE_REJECTED = 'LOGIN_CHECK_ACCOUNT_IS_ACTIVE_REJECTED';

export const CREATE_ADVISOR = 'CREATE_ADVISOR';
export const CREATE_ADVISOR_PENDING = 'CREATE_ADVISOR_PENDING';
export const CREATE_ADVISOR_FULFILLED = 'CREATE_ADVISOR_FULFILLED';
export const CREATE_ADVISOR_REJECTED = 'CREATE_ADVISOR_REJECTED';

export const INIT_VIEW = 'REGISTRATION_INIT_VIEW';
export const SET_MESSAGE = 'REGISTRATION_SET_MESSAGE';
