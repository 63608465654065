import * as React from 'react';
import PropTypes from 'prop-types';

const IconQuestionMarkSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      {...props}
    >
      <title>{title}</title>;
      <g>
        <path d="M45.9,11.3C28.3,13.4,14.8,26,11.6,42.6c-4,21.1,9.7,41.7,30.6,46c1.1,0.2,12.4,1.3,20.9-1.5c14.4-5.6,23.2-15.9,25.8-31.3   c0.1-0.3,1.4-12.9-3.8-23.1C78.2,18.6,63,9.2,45.9,11.3z M49.4,74c-3.2,0-5.6-2.4-5.6-5.5c0-3.4,2.3-5.8,5.6-5.8   c3.3-0.1,5.8,2.4,5.8,5.7C55.2,71.6,52.8,74,49.4,74z M61.8,47.9c-1.4,1.2-2.8,2.3-4.3,3.3C55.3,52.7,54,55,54,58c-2.9,0-6,0-9,0   c-0.4-3.2-0.2-6.8,2.7-9.1c1.7-1.3,3.5-2.4,4.9-3.8c1.3-1.4,2.6-3.1,3.2-4.8c0.7-2.2-1-4.8-2.9-5.5c-2.8-1-6.2-0.2-7.4,2.1   c-0.7,1.3-1.1,2.9-1.7,4.5c-2.8,0-5.6,0-8.4,0c-0.9-4.5,1.6-10,5.8-12.7c5.8-3.6,15.7-2.8,20.8,1.8C67.1,35,67,43.4,61.8,47.9z" />
      </g>
    </svg>
  );
};

IconQuestionMarkSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconQuestionMarkSvg;
