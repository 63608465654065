export const colors = {
  primaryColor: '#2a3ea3',
  primaryColorHover: '#172986',
  primaryColorAccent: '#2a3ae3',
  greyButtonBorder: '#8e8e8e',
  greyText: '#5f5f5f',
  greyTimeline: '#9196b2',
  greyButton: '#d1d1d1',
  greyBisabled: '#808080',
  lightBrey: '#eceef6',
  grey: '#d8d8d8',
  yellow: '#efb935',
  lightBlue: '#eceef7',
  skyBlue: '#1aa5df',
  white: '#ffffff',
  overlayGradient: 'rgba(42, 58, 227, 0.8)',
  overlayBkg: '#2a3ae3',
  black: '#000000',
  red: '#a32a2a',
  lightRed: '#eb6862',
  lightRedBright: '#ff6256',
  greyLanding: '#a8a9ab',
  lightBluegrey: '#f3f4f9',
  lightRedTimeline: '#ff6256',
  lightRedRrror: '#f3beba',
  lightGreenTimeline: '#113019',
  lightGreenError: '#9bc3a6',
  greenFruitSalad: '#4A934A',
  lightRedTransparent: 'rgba(255, 98, 86, 0.6)',
  whiteTransparent: 'rgba(255, 255, 255, 0.8)',
  greyBackground: '#f4f5f7',
  greyBlue: '#adb4d9',
  greyBluelight: '#d4d9ed',
  greyLight: '#969696',
  greyExtralight: '#ced4da',
  blueText: '#172986',
  darkgrey: '#858585',
  charcoalBlack: '#201f1f',
  charcoal: '#495057',
  periwinkleGray: '#bfc5e3',
  whiteLilac: '#f4f5fa',
  winterCloud: '#e9ecef',
  warning: '#f4832b',
  warningLight: '#ff9747',
  seaBlue: '#0a7abf',
  seaBlueLight: '#4798ca',
  hawksBlue: '#d0d4e6',
};

export const styled_component_themes = {
  default: {
    primaryColor: '#2a3ea3',
    footerFormBackgroundColor: '#2a3ea3',
  },
  ca: {
    primaryColor: '#007461',
    footerFormBackgroundColor: '#f7f8fa',
  },
};
