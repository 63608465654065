export const GET_DIVIDEND_PAYMENT_LIST = 'GET_DIVIDEND_PAYMENT_LIST';
export const GET_DIVIDEND_PAYMENT_LIST_PENDING = 'GET_DIVIDEND_PAYMENT_LIST_PENDING';
export const GET_DIVIDEND_PAYMENT_LIST_FULFILLED = 'GET_DIVIDEND_PAYMENT_LIST_FULFILLED';
export const GET_DIVIDEND_PAYMENT_LIST_REJECTED = 'GET_DIVIDEND_PAYMENT_LIST_REJECTED';

export const GET_DIVIDEND_PAYMENT_HISTORY = 'GET_DIVIDEND_PAYMENT_HISTORY';
export const GET_DIVIDEND_PAYMENT_HISTORY_PENDING = 'GET_DIVIDEND_PAYMENT_HISTORY_PENDING';
export const GET_DIVIDEND_PAYMENT_HISTORY_FULFILLED = 'GET_DIVIDEND_PAYMENT_HISTORY_FULFILLED';
export const GET_DIVIDEND_PAYMENT_HISTORY_REJECTED = 'GET_DIVIDEND_PAYMENT_HISTORY_REJECTED';

export const GET_DIVIDEND_PAYMENT_HISTORY_DETAIL = 'GET_DIVIDEND_PAYMENT_HISTORY_DETAIL';
export const GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_PENDING =
  'GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_PENDING';
export const GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_FULFILLED =
  'GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_FULFILLED';
export const GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_REJECTED =
  'GET_DIVIDEND_PAYMENT_HISTORY_DETAIL_REJECTED';

export const POST_DIVIDEND_PAYMENT_CONFIRMATION = 'POST_DIVIDEND_PAYMENT_CONFIRMATION';
export const POST_DIVIDEND_PAYMENT_CONFIRMATION_PENDING =
  'POST_DIVIDEND_PAYMENT_CONFIRMATION_PENDING';
export const POST_DIVIDEND_PAYMENT_CONFIRMATION_FULFILLED =
  'POST_DIVIDEND_PAYMENT_CONFIRMATION_FULFILLED';
export const POST_DIVIDEND_PAYMENT_CONFIRMATION_REJECTED =
  'POST_DIVIDEND_PAYMENT_CONFIRMATION_REJECTED';

export const UPDATE_DIVIDEND_PAYMENT_POSITIONS_FIELD = 'UPDATE_DIVIDEND_PAYMENT_POSITIONS_FIELD';
export const UPDATE_DIVIDEND_PAYMENT_GROUPED_COUNTER_FIELD =
  'UPDATE_DIVIDEND_PAYMENT_GROUPED_COUNTER_FIELD';
export const UPDATE_DIVIDEND_PAYMENT_COST_TO_PAY = 'UPDATE_DIVIDEND_PAYMENT_COST_TO_PAY';

export const RESET_DECLARATION = 'RESET_DECLARATION';
