/**
 * Forces the value to 12 uppercase alphanumeric characters
 * @param {*} value ISIN to normalize
 */
export const normalizeIsin = value => {
  if (typeof value !== 'string') {
    return '';
  }
  return value
    .toUpperCase() // convert to uppercase
    .replace(/[^0-9A-Z]/g, '') //strips any non alaphanumeric character
    .slice(0, 12); // constrains the field to 12 characters max
};

export const checkIsin = value => {
  if (typeof value !== 'string') {
    return false;
  }
  const result = value.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]{1}$/);
  return !!result;
};

export const isIsinShareValid = isinShare => {
  if (typeof isinShare !== 'object' || isinShare === null) {
    return false;
  }

  const requiredKeys = ['isin', 'siren', 'part_type', 'category'];
  return requiredKeys.every(key => Object.hasOwn(isinShare, key));
};

export const generateInvalidShareMessage = isinShare =>
  `Le code ISIN "${isinShare.isin}" existe déjà pour la société "${isinShare.companyName}" (SIREN "${isinShare.siren}") dont la nature de titres est "${isinShare.part_type}" et la catégorie est "${isinShare.category}"`;

export const areIsinSharesConflicted = (currentIsinShare, serverIsinShare) => {
  // if ISIN are same, verify associated shares are not identical (company, part type, category)
  if (currentIsinShare.isin === serverIsinShare.isin) {
    return (
      currentIsinShare.siren !== serverIsinShare.siren ||
      currentIsinShare.part_type !== serverIsinShare.part_type ||
      currentIsinShare.category !== serverIsinShare.category
    );
  }
  // else verify associated shares are identical (company, part type, category)
  return (
    currentIsinShare.siren === serverIsinShare.siren &&
    currentIsinShare.part_type === serverIsinShare.part_type &&
    currentIsinShare.category === serverIsinShare.category
  );
};

export const MANAGE_VALUES = {
  ADMINISTRE: {
    label: 'administré',
    value: 'ADM',
  },
  PUR: {
    label: 'pur',
    value: 'PUR',
  },
};
