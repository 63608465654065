export const INIT_VIEW = 'LANDING_INVEST_COMPLETE_INIT_VIEW';
export const INIT_VIEW_PENDING = 'LANDING_BANK_COMPLETE_INIT_VIEW_PENDING';
export const INIT_DEFAULT = 'LANDING_INVEST_COMPLETE_INIT_DEFAULT';
export const INIT_INVESTMENT = 'LANDING_INVEST_COMPLETE_INIT_INVESTMENT';

export const START_NEW_OPERATION_PENDING = 'LANDING_START_NEW_OPERATION_PENDING';
export const START_NEW_OPERATION_FULFILLED = 'LANDING_START_NEW_OPERATION_FULFILLED';
export const START_NEW_OPERATION_REJECTED = 'LANDING_START_NEW_OPERATION_REJECTED';
export const START_NEW_OPERATION_TRANSMIT_FULFILLED =
  'LANDING_TRANSMIT_START_NEW_OPERATION_FULFILLED';

export const CLEAR_DATA = 'LANDING_INVEST_COMPLETE_CLEAR_DATA';
export const EDIT_TRANSMIT_OPERATION_PENDING = 'EDIT_TRANSMIT_OPERATION_PENDING';
export const EDIT_TRANSMIT_OPERATION_FULFILLED = 'EDIT_TRANSMIT_OPERATION_FULFILLED';
export const EDIT_TRANSMIT_OPERATION_REJECTED = 'EDIT_TRANSMIT_OPERATION_REJECTED';

export const START_NEW_TEMPLATE_PENDING = 'LANDING_START_NEW_TEMPLATE_PENDING';
export const START_NEW_TEMPLATE_FULFILLED = 'LANDING_START_NEW_TEMPLATE_FULFILLED';
export const START_NEW_TEMPLATE_REJECTED = 'LANDING_START_NEW_TEMPLATE_REJECTED';

export const TIMEOUT_BEFORE_CLEANING_MSG = 5000;

export const GET_TEMPLATE_DOCUMENTS_PENDING = 'LANDING_GET_TEMPLATE_DOCUMENTS_PENDING';
export const GET_TEMPLATE_DOCUMENTS_FULFILLED = 'LANDING_GET_TEMPLATE_DOCUMENTS_FULFILLED';
export const GET_TEMPLATE_DOCUMENTS_REJECTED = 'LANDING_GET_TEMPLATE_DOCUMENTS_REJECTED';
