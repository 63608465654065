import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Pages
import Login from './pages/login/containers/login.container';
import Registration from './pages/registration/containers/registration.container';
import Password from './pages/password/containers/password.container';
import LegalNotice from './pages/legal-notice/containers/legalNotice.container';
import Portal from './pages/portal/containers/portal.container';
import CGU from './pages/cgu-catalizr/containers/cgu-catalizr.container';
import SSO from './pages/sso/containers/sso.container';

// Components
import User from './components/User';

// Utils
import { bugReport } from './shared/utils/bug-report';

const MyPublicRouter = () => {
  useEffect(() => {
    window.onerror = (message, file, line, col, error) => {
      if (error) {
        bugReport(error);
      }
    };
  }, []);

  return (
    <Router>
      <User>
        <div className="root__main">
          <Switch>
            <Route exact path="/portal" component={Portal} />
            <Route path="/login" render={props => <Login initJourney {...props} />} />
            <Route path="/create/account" component={Registration} />
            <Route path="/account/create" component={Registration} />
            <Route exact path="/password/:token" component={Password} />
            <Route path="/advisor/email" render={() => <Login initJourney detectAdvisor />} />
            <Route path="/investor" component={Login} />
            <Route path="/invest-complete" component={Login} />
            <Route path="/company" component={Login} />
            <Route path="/company-attest" component={Login} />
            <Route path="/company-creation-update" component={Login} />
            <Route path="/legal-notice" render={() => <LegalNotice />} />
            <Route path="/sso" render={() => <SSO />} />
            <Route path="/cgu" render={() => <CGU />} />
            <Route exact path="/" render={props => <Login initJourney {...props} />} />
            <Route component={Login} />
          </Switch>
        </div>
      </User>
    </Router>
  );
};

export default MyPublicRouter;
