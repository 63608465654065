import * as React from 'react';
import PropTypes from 'prop-types';

const IconFlagSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      role="img"
      aria-label={alt}
      width={width}
      height={height}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      {...props}
    >
      <title>{title}</title>
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M5 5v16M19 5v9M5 5a5 5 0 017 0 5 5 0 007 0M5 14a5 5 0 017 0 5 5 0 007 0" />
    </svg>
  );
};

IconFlagSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconFlagSvg;
