import React from 'react';
import PropTypes from 'prop-types';

const IconEmailClickedSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M22 14h-2V7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 01-1-1V4a1 1 0 011-1h18a1 1 0 011 1v10zM4.511 5l7.55 6.662L19.502 5H4.511zM19 22l-3.536-3.536 1.415-1.414L19 19.172l3.536-3.536 1.414 1.414L19 22z" />
    </svg>
  );
};

IconEmailClickedSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailClickedSvg;
