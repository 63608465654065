import * as modalAddSharedDocConst from '../actions/modalAddSharedDoc.const';
import * as commonConst from '../../../actions/const/common.const';

const initState = {
  isLoadingInvestments: false,
  isOpen: false,
  step: 0,
  errorMsg: '',
  myReactTableData: { selected: {}, selectAll: 0, data: [], nbSelected: 0 },
  errorData: { data: [] },
  errorLoadingInvestments: '',
  sharedCurrentDocument: '',
  sharedCurrentOperationType: '',
  userCurrentCompany: '',
  currentPage: 1,
  operationsOnCurrentPage: [],
  multiUploadDocumentsList: [],
  multiUploadOperationsList: [],
  multiUploadCompaniesList: [],
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
      return initState;
    case modalAddSharedDocConst.INIT_STATE: {
      return {
        isLoadingInvestments: false,
        search: '',
        isOpen: false,
        step: 0,
        errorMsg: '',
        myReactTableData: { selected: {}, selectAll: 0, data: [], nbSelected: 0 },
        errorData: { data: [] },
        errorLoadingInvestments: '',
        sharedCurrentDocument: '',
        sharedCurrentOperationType: '',
        userCurrentCompany: '',
        currentPage: 1,
        operationsOnCurrentPage: [],
        multiUploadCompaniesList: [],
        multiUploadDocumentsList: [],
        multiUploadOperationsList: state.multiUploadOperationsList,
      };
    }
    case modalAddSharedDocConst.INIT_PARTIAL_STATE: {
      return {
        isLoadingInvestments: false,
        search: '',
        isOpen: true,
        step: 0,
        errorMsg: '',
        myReactTableData: { selected: {}, selectAll: 0, data: [], nbSelected: 0 },
        errorData: { data: [] },
        errorLoadingInvestments: '',
        sharedCurrentDocument: '',
        sharedCurrentOperationType: state.sharedCurrentOperationType,
        userCurrentCompany: '',
        currentPage: 1,
        operationsOnCurrentPage: [],
        multiUploadDocumentsList: state.multiUploadDocumentsList,
        multiUploadOperationsList: state.multiUploadOperationsList,
        multiUploadCompaniesList: state.multiUploadCompaniesList,
      };
    }
    case modalAddSharedDocConst.SET_SHARED_DOCUMENTS_LIST: {
      return {
        ...state,
        multiUploadDocumentsList: action.list,
      };
    }
    case modalAddSharedDocConst.SET_SHARED_OPERATIONS_LIST: {
      return {
        ...state,
        multiUploadOperationsList: action.list,
      };
    }
    case modalAddSharedDocConst.SET_IS_OPEN: {
      return {
        ...state,
        isOpen: action.open,
      };
    }
    case modalAddSharedDocConst.SET_CURRENT_COMPANY: {
      return {
        ...state,
        userCurrentCompany: action.userCurrentCompany,
      };
    }
    case modalAddSharedDocConst.SET_SHARED_CURRENT_OPERATION_TYPE: {
      return {
        ...state,
        sharedCurrentOperationType: action.operationType,
      };
    }
    case modalAddSharedDocConst.SET_SHARED_CURRENT_DOCUMENT: {
      return {
        ...state,
        sharedCurrentDocument: action.current,
      };
    }
    case modalAddSharedDocConst.SET_SHARED_DELETE_CURRENT_DOCUMENT: {
      let newSharedCurrentDocument = state.sharedCurrentDocument;
      newSharedCurrentDocument.id = undefined;
      return {
        ...state,
        sharedCurrentDocument: newSharedCurrentDocument,
      };
    }
    case modalAddSharedDocConst.SET_SEND_RESULT_PENDING: {
      return {
        ...state,
        step: 2,
      };
    }
    case modalAddSharedDocConst.SET_SEND_RESULT_FULFILLED: {
      return {
        ...state,
        step: 3,
        isOpen: false,
      };
    }
    case modalAddSharedDocConst.SET_SEND_RESULT_REJECTED: {
      let errorMsg = '';
      if (!action.payload.response.status) {
        errorMsg = 'Erreur interne.';
      } else {
        if (action.payload.response.status === 404) {
          errorMsg = "Impossible d'ajouter le document.";
        } else if (action.payload.response.status === 418) {
          errorMsg = "Erreur : Le document n'a pas pu être ajouté aux opérations suivantes :";
        } else {
          errorMsg = 'Erreur interne.';
        }
      }
      let newModalAddSharedDocErrorData = [];
      const errorsResponse = action.payload.response.data;
      if (
        errorsResponse.data &&
        errorsResponse.data.errors &&
        errorsResponse.data.errors.length > 0
      ) {
        errorsResponse.data.errors.forEach(error => {
          if (error.investment_link_id) {
            // Recherche et ajout de l'element
            state.myReactTableData.data.forEach(element => {
              if (element.mongo_id_link === error.investment_link_id) {
                if (element.mongo_id) {
                  element.url = `/company/dashboard/investment/${element.mongo_id}`;
                }
                newModalAddSharedDocErrorData.push(element);
              }
            });
          }
        });
      }
      return {
        ...state,
        errorMsg: errorMsg,
        step: 4,
        errorData: { data: newModalAddSharedDocErrorData },
      };
    }
    case modalAddSharedDocConst.GET_INVESTMENTS_PENDING:
    case modalAddSharedDocConst.GET_COMPANIES_PENDING: {
      return {
        ...state,
        isLoadingInvestments: true,
      };
    }
    case modalAddSharedDocConst.GET_INVESTMENTS_FULFILLED: {
      return {
        ...state,
        isLoadingInvestments: false,
        errorLoadingInvestments: '',
      };
    }
    case modalAddSharedDocConst.GET_INVESTMENTS_REJECTED: {
      return {
        ...state,
        isLoadingInvestments: false,
        errorLoadingInvestments: 'Erreur lors de la récupération des investissements',
      };
    }
    case modalAddSharedDocConst.SET_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }
    case modalAddSharedDocConst.SET_MY_REACT_TABLE: {
      let object = {
        id: state.myReactTableData.id,
        data: state.myReactTableData.data,
        selectAll: state.myReactTableData.selectAll,
        selected: state.myReactTableData.selected,
        nbSelected: state.myReactTableData.nbSelected,
      };

      if (action.data.id !== undefined) {
        object.id = action.data.id;
      }
      if (action.data.data !== undefined) {
        object.data = action.data.data;
      }
      if (action.data.selected !== undefined) {
        object.selected = action.data.selected;
      }
      if (action.data.selectAll !== undefined) {
        object.selectAll = action.data.selectAll;
      }
      if (action.data.nbSelected !== undefined) {
        object.nbSelected = action.data.nbSelected;
      }
      return {
        ...state,
        myReactTableData: object,
      };
    }
    case modalAddSharedDocConst.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
      };
    }
    case modalAddSharedDocConst.GET_CURRENT_OPERATIONS: {
      return {
        ...state,
        operationsOnCurrentPage: action.operationsOnCurrentPage,
      };
    }
    case modalAddSharedDocConst.GET_COMPANIES_FULFILLED: {
      return {
        ...state,
        isLoadingInvestments: false,
        errorLoadingInvestments: '',
        multiUploadCompaniesList: action.payload.data.data,
      };
    }
    case modalAddSharedDocConst.GET_COMPANIES_REJECTED: {
      return {
        ...state,
        isLoadingInvestments: false,
      };
    }
    case commonConst.CLEAR_REDUX:
      return initState;
    default:
      return state;
  }
};

export default reducer;
