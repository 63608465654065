import * as tableParamsConst from '../actions/const/table-params.const';

const defaultParams = {
  pageIndex: 0,
};

const initState = {
  investmentHistoryList: { ...defaultParams },
  operationTemplateList: { ...defaultParams },
  closedList: { ...defaultParams },
  inProgressList: { ...defaultParams },
  transmitList: { ...defaultParams },
  sharesValuation: { ...defaultParams },
  sharesValuationDetails: { ...defaultParams },
  bulkTable: { ...defaultParams },
  shareHoldingList: { ...defaultParams },
  investorWalletTable: { ...defaultParams },
  positionDetailsTable: { ...defaultParams },
};

export default (state = initState, action) => {
  switch (action.type) {
    case tableParamsConst.SET_PAGE_INDEX:
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          pageIndex: action.pageIndex,
        },
      };
    case tableParamsConst.RESET_PAGE_INDEX:
      return {
        ...state,
        investmentHistoryList: { ...state.investmentHistoryList, pageIndex: 0 },
        operationTemplateList: { ...state.operationTemplateList, pageIndex: 0 },
        closedList: { ...state.closedList, pageIndex: 0 },
        inProgressList: { ...state.inProgressList, pageIndex: 0 },
        transmitList: { ...state.transmitList, pageIndex: 0 },
        sharesValuation: { ...state.sharesValuation, pageIndex: 0 },
        sharesValuationDetails: { ...state.sharesValuationDetails, pageIndex: 0 },
        bulkTable: { ...state.bulkTable, pageIndex: 0 },
        shareHoldingList: { ...state.shareHoldingList, pageIndex: 0 },
        investorWalletTable: { ...state.investorWalletTable, pageIndex: 0 },
        positionDetailsTable: { ...state.positionDetailsTable, pageIndex: 0 },
      };

    default:
      return state;
  }
};
