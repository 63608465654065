import * as React from 'react';
import PropTypes from 'prop-types';

const CatalizrLineSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg
      id={title}
      data-name="Calque 1"
      role="img"
      width={width}
      height={height}
      aria-label={alt}
      {...props}
      viewBox="0 0 1146.4 228.3"
    >
      <title>{title}</title>
      <path
        className="cls-1"
        d="M120.82,54.79a59,59,0,0,1,42,17.39,7.78,7.78,0,0,0,11-11,74.89,74.89,0,1,0,0,106,7.78,7.78,0,0,0-11-11,59.43,59.43,0,0,1-83.94,0,58.72,58.72,0,0,1-16.62-34.19H85.37a36.31,36.31,0,0,0,61.18,17.94,7.78,7.78,0,0,0-11-11c-7.87,7.87-21.57,7.87-29.44,0a20.81,20.81,0,1,1,29.44-29.44,7.78,7.78,0,0,0,11-11,36.26,36.26,0,0,0-61.18,17.94H62.23A58.83,58.83,0,0,1,120.82,54.79Z"
      />
      <path
        className="cls-1"
        d="M169.09,0H59.21A59.28,59.28,0,0,0,0,59.21V169.09A59.28,59.28,0,0,0,59.21,228.3H169.09a59.28,59.28,0,0,0,59.21-59.21V59.21A59.28,59.28,0,0,0,169.09,0Zm43.64,169.09a43.69,43.69,0,0,1-43.64,43.64H59.21a43.69,43.69,0,0,1-43.64-43.64V59.21A43.69,43.69,0,0,1,59.21,15.57H169.09a43.69,43.69,0,0,1,43.64,43.64V169.09Z"
      />
      <path
        className="cls-1"
        d="M315,167.88q-21.57-20.94-21.57-53.14T315,61.6q21.56-20.94,52.73-20.94,24.15,0,42.92,12.79t26.21,35.5h-42.1q-8.26-15.9-26.41-15.89T339.74,84.41q-10.42,11.35-10.42,30.33t10.42,30.33q10.42,11.35,28.58,11.35t26.41-15.89h42.1q-7.43,22.71-26.21,35.5t-42.92,12.79Q336.54,188.83,315,167.88Z"
      />
      <path
        className="cls-1"
        d="M467.58,172.41q-15.39-16.62-15.38-42.6t15.38-42.71Q483,70.37,505.1,70.37t34,16.41V71.61h35.54v116H539.08V170.74Q526.19,189,504.58,189T467.58,172.41Zm63.81-21.61q7.69-7.48,7.69-21.2t-7.69-21.09a24.72,24.72,0,0,0-35.33.1q-7.69,7.5-7.69,21.2t7.69,21.09A24.72,24.72,0,0,0,531.39,150.8Z"
      />
      <path
        className="cls-1"
        d="M655.06,157.45h12.68v30.13H649.66q-19.54,0-30.24-8.63t-10.7-29.62V101.12H597.9V71.61h10.81v-31h35.54v31h23.28v29.51H644.25v48.22Q644.25,157.44,655.06,157.45Z"
      />
      <path
        className="cls-1"
        d="M698.91,172.41q-15.39-16.62-15.38-42.6t15.38-42.71q15.38-16.72,37.51-16.72t34,16.41V71.61h35.54v116H770.4V170.74Q757.52,189,735.9,189T698.91,172.41Zm63.81-21.61q7.69-7.48,7.69-21.2t-7.69-21.09a24.72,24.72,0,0,0-35.33.1q-7.69,7.5-7.69,21.2t7.69,21.09A24.72,24.72,0,0,0,762.71,150.8Z"
      />
      <path className="cls-1" d="M837.13,187.58V40.66h35.54V187.58H837.13Z" />
      <path
        className="cls-1"
        d="M936.27,58.52a20.79,20.79,0,0,1-35.55-14.86,20.79,20.79,0,1,1,41.57,0A20.21,20.21,0,0,1,936.27,58.52ZM903.85,187.58v-116h35.54v116H903.85Z"
      />
      <path
        className="cls-1"
        d="M1051.62,71.61v29.3l-44.48,57.36h44.48v29.3H965.36v-29.3l44.48-57.36H965.36V71.61h86.26Z"
      />
      <path
        className="cls-1"
        d="M1113.14,71.61V93.22q12.47-22.87,33.26-22.86v36.16h-8.73q-12.27,0-18.4,5.83t-6.13,20.36v54.87H1077.6v-116h35.54Z"
      />
    </svg>
  );
};

CatalizrLineSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default CatalizrLineSvg;
