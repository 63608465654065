import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SSO from '../index';
import { connectSSOActive } from '../../../shared/actions/actions/sso.actions';

const Container = props => {
  const state = useSelector(state => ({
    bank: state.bank,
    user: state.user,
    login: state.login,
    sso: state.sso,
    journey: state.default.journey,
    banners: state.common.banners,
  }));
  const dispatch = useDispatch();
  return (
    <SSO {...props} {...state} connectSSOActive={history => dispatch(connectSSOActive(history))} />
  );
};

export default withRouter(Container);
