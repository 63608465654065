import { bugReport } from './bug-report';

let bugReportCalled = false;
let timeout;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState) {
      return JSON.parse(serializedState);
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

/**
 * Take a json object and save it to the local storage as a string
 * @param {*} state redux state
 */
export const saveState = state => {
  // We set timeout to prevent localStorage.setItem() to render too many times
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    try {
      localStorage.setItem('state', JSON.stringify(cleanStateForSave(state)));
    } catch (err) {
      // We prevent QUOTA_EXCEEDED err from sending too much bugReport emails
      if (!bugReportCalled) {
        bugReportCalled = true;
        bugReport(err);
      }
    }
  }, 1000);
};

export const cleanStateForSave = state => {
  // HACK We don't save these big datas on localStorage to prevent localStorage from QUOTA_EXCEEDED error
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      inProgress: { ...state.dashboard.inProgress, data: [] },
      suggestedOperations: { ...state.dashboard.suggestedOperations, data: [] },
      transmit: { ...state.dashboard.transmit, data: [] },
      wallet: { ...state.dashboard.wallet, data: [] },
      cancelled: { ...state.dashboard.cancelled, data: [] },
      sharesValuationList: { ...state.dashboard.sharesValuationList, data: [] },
    },
  };
};
