import React from 'react';

import { utils } from '@utocat/catalizr-const-sharing-lib';

// Components
import Topbar from '../../../components/Topbar';

// Utils
import { getSubdomain } from '../../../shared/utils/helper';

// Style
import '../style.scss';

/**
 * information trouvés dans les mentions légales de la caisse CA en cherchant DPO
 * https://www.credit-agricole.fr/subdomain/particulier/informations/mentions-legales.html
 */
const CA_DETAILS = {
  'ca-centrest': {
    dpoMail: 'dpo@ca-centrest.fr',
    address:
      'Crédit Agricole Centre-Est , Délégué à la Protection des données – DPO, - 1, rue Pierre de Truchis de Lays - 69410 CHAMPAGNE AU MONT D’OR',
  },
  'ca-aquitaine': {
    dpoMail: 'dpo@ca-aquitaine.fr',
    address: 'CREDIT AGRICOLE Aquitaine DPO 106 quai de Bacalan 33300 BORDEAUX',
  },
  'ca-briepicardie': {
    dpoMail: 'dpo@ca-briepicardie.fr',
    address:
      'Caisse Régionale de Crédit Agricole Brie Picardie – DRC - DPO - 500, rue St-Fuscien 80095 Amiens CEDEX 3',
  },
  'ca-nord-est': {
    dpoMail: 'dpo@ca-nord-est.fr',
    address: 'DPO - 25 rue Libergier - 51088 REIMS Cedex',
  },
  'ca-normandie': {
    dpoMail: ' dpo@ca-normandie.fr',
    address:
      'Caisse Régionale de Crédit Agricole Mutuel de Normandie - DPO (Ingénierie de la Donnée), 15 esplanade Brillaud de Laujardière - CS 25014 - 14050 CAEN CEDEX 4',
  },
  'ca-tourainepoitou': {
    dpoMail: 'dpo@ca-tourainepoitou.fr',
    address:
      'CREDIT AGRICOLE de la Touraine et du Poitou – DPO – 18, rue Salvador Allende - CS50307 - 86008 POITIERS CEDEX 1',
  },
  'ca-nmp': {
    dpoMail: 'dpo@ca-nmp.fr',
    address:
      'Caisse Régionale de Crédit Agricole Mutuel Nord Midi-Pyrénées - DPO - 219 avenue François Verdier - 81022 ALBI Cedex 9',
  },
  'ca-des-savoie': {
    dpoMail: 'protection.des.donnees@ca-des-savoie.fr',
    address:
      'Caisse Régionale du Crédit Agricole Mutuel des Savoie - Service Risques et Conformité - 1 220 avenue Costa de Beauregard - 73 290 La Motte Servolex',
  },
  'ca-norddefrance': {
    dpoMail: 'dpo@ca-norddefrance.fr',
    address: 'Crédit Agricole Nord de France - 10, avenue Foch - BP 369 - 59020 LILLE',
  },
  'ca-alsace-vosges': {
    dpoMail: 'dpo@ca-alsace-vosges.fr',
    address:
      'Caisse Régionale : Crédit Agricole Alsace Vosges - DPO - 1, place de la Gare - BP 20440 - 67008 Strasbourg Cedex',
  },
  'ca-loirehauteloire': {
    dpoMail: 'dpo@ca-loirehauteloire.fr',
    address:
      "Caisse Régionale de Crédit Agricole Mutuel Loire Haute-Loire, à l'attention du Délégué à la Protection des données (DPO), 94 Rue Bergson, BP 524, 42007 Saint-Etienne Cedex 1",
  },
  'ca-centreloire': {
    dpoMail: 'dpo@ca-centreloire.fr',
    address:
      'Caisse Régionale par courrier à l’adresse Crédit Agricole Centre Loire DPO - 8 Allée Samuel Paty - 18920 - BOURGES cedex 9',
  },
  'ca-centreouest': {
    dpoMail: 'dpo@ca-centreouest.fr',
    address:
      'Caisse régionale de Crédit Agricole Mutuel du Centre Ouest DPO 29 boulevard de Vanteaux BP 509 - 87044 Limoges Cedex',
  },
  'ca-valdefrance': {
    dpoMail: 'dpo@ca-valdefrance.fr',
    address: 'Crédit Agricole Val de France – DPO – 1 Rue Daniel Boutet – 28000 Chartres',
  },
  'ca-alpesprovence': {
    dpoMail: 'dpo@ca-alpesprovence.fr',
    address:
      'Crédit Agricole Alpes Provence - Délégué à la protection des données personnelles - 25 chemin des Trois Cyprès CS 70392 - 13097 Aix en Provence Cedex 2',
  },
  lcl: {
    dpoMail: 'protection_des_donnees@lcl.fr',
    address:
      'Délégué à la protection des données LCL – BC 206-09 – 20, avenue de Paris, 94811 VILLEJUIF Cedex',
  },
  // bank information are expected, find a fake data rgpd contact below
  nortia: {
    dpoMail: 'protection_des_donnees@nortia.fr',
    address: 'Délégué à la protection des données Nortia – 215, avenue le Notre, 59100 ROUBAIX',
  },
};

const CGUCatalizr = () => {
  const subdomain = getSubdomain();

  const getArt16 = () => {
    if (utils.banks.isAffiliatedCATitre(subdomain)) {
      return getArt16ForCAContent();
    }
    return getArt16Content();
  };

  const getArt16Content = () => {
    return (
      <section>
        <h2 className="title">16 – COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</h2>
        <p>
          La société UTOCAT respecte l'ensemble des dispositions applicables en matière de
          protection de la vie privée et notamment la loi du 6 janvier 1978 relative à la protection
          de la vie privée à l'égard des traitements de données à caractère personnel telle
          qu'amendée, et le Règlement Général sur la Protection des Données personnelles.
        </p>
        <p>
          UTOCAT collecte et conserve les données à caractère personnel que l'Utilisateur lui a
          volontairement fournies. Ainsi, sont notamment réputées être des données à caractère
          personnel relatives à l'identité, numéro de téléphone, adresse email, domicile, du
          Compte-titres de l'Investisseur, à l'adresse IP de l'ordinateur d'une personne physique.
        </p>
        <p>
          L'Utilisateur est informé et accepte que UTOCAT en sa qualité de sous-traitant du
          traitement collecte et procède à des traitements sur ses Données personnelles à des fins
          de :
        </p>
        <ul>
          <li>
            respect de toute disposition légale ou réglementaire applicable, notamment en matière de
            prévention du blanchiment d'argent et du financement du terrorisme ;
          </li>
          <li>
            traitement/gestion et archivage des Opérations de chargement des Données et des
            Opérations d'investissement ;
          </li>
          <li>
            contrôle et de prévention des incidents et irrégularités (lutte contre la fraude et tout
            autre type d'abus) ;
          </li>
          <li>traitement des demandes de l'Utilisateur ;</li>
          <li>contrôle de la qualité du service ;</li>
          <li>et de proposition de nouveaux services.</li>
        </ul>
        <p>
          L'Utilisateur est informé et accepte que UTOCAT en sa qualité de responsable du traitement
          collecte et procède à des traitements sur ses Données personnelles à des fins de :
        </p>
        <ul>
          <li>établissement de statistiques d'usage des Services et de mesure d'audience.</li>
        </ul>
        <p>
          L'Utilisateur est informé que ses données personnelles peuvent être enregistrées dans un
          ou plusieurs fichiers dans le respect de la législation applicable et accepte que les
          données collectées soient enregistrées et traitées conformément aux finalités précédemment
          énoncées.
        </p>
        <p>
          L'Utilisateur accepte que les données personnelles strictement nécessaires à la
          réalisation d'une au moins des finalités mentionnées ci-dessus ou à ce qui est requis en
          vertu de la réglementation applicable, soient communiquées par UTOCAT :
        </p>
        <ul>
          <li>
            aux sous-traitants et prestataires de services externalisés dont l'intervention est
            nécessaire ;
          </li>
          <li>
            aux parties prenantes de l'Opération d'investissement pour qui, la connaissance de ses
            Données personnelles est nécessaire ;
          </li>
          <li>aux responsables de traitement pour le compte desquels UTOCAT est sous-traitant.</li>
        </ul>
        <p>
          L'Utilisateur accepte que la communication de ses informations personnelles aux personnes
          susvisées puisse se faire, conformément aux conditions précédemment définies, vers un
          autre pays de l'Union Européenne et également vers un pays non membre de l'Union
          Européenne qui garantit un niveau de protection adéquat eu égard à la loi précitée.
          Toutefois, à ce jour, l'ensemble des serveurs utilisés par UTOCAT se situe en France.
        </p>
        <p>
          Les Données personnelles des Utilisateurs sont conservées par UTOCAT pendant toute la
          durée de l'investissement à laquelle est ajoutée une période de cinq ans. A la fin de
          cette période, une demande est envoyée au Délégué à la Protection des Données personnelles
          de la Banque et, le cas échéant, de l'Intermédiaire, pour savoir si lesdites données
          peuvent être supprimées.
        </p>
        <p>
          L'Utilisateur a un droit d'accès aux données personnelles le concernant et est informé
          qu'il peut consulter à tout moment les informations qu'il a communiquées à UTOCAT.
          L'Utilisateur a le droit d'obtenir la rectification des données inexactes qui le
          concernent.
        </p>
        <p>
          Le droit d'opposition de l'Utilisateur peut être exercé auprès de UTOCAT pour les
          traitements envisagés relatifs à des Services promus par UTOCAT.
        </p>
        <p>
          Les droits d'opposition, d'accès et de rectification peuvent être exercés gratuitement sur
          demande adressée à UTOCAT par courrier électronique à l'adresse email suivante : dpo [at]
          utocat.com
        </p>
        <p>
          Enfin, il est précisé que seul le pôle “support” d'UTOCAT est amené à prendre
          connaissance, en cas de besoin, aux Données personnelles des Utilisateurs.
        </p>
      </section>
    );
  };

  const getArt16ForCAContent = () => {
    const CaDetails = CA_DETAILS[subdomain];
    if (typeof CaDetails.dpoMail !== 'string' || typeof CaDetails.address !== 'string') {
      console.error('Missing CA data for CGU');
    }
    return (
      <section>
        <h2 className="title">16 – COLLECTE ET TRAITEMENT DES DONNEES A CARACTERE PERSONNEL</h2>
        <p>
          La société UTOCAT respecte l’ensemble des dispositions applicables en matière de
          protection de la vie privée et notamment de la loi du 6 janvier 1978 relative à la
          protection de la vie privée à l’égard des traitements de données à caractère personnel
          telle qu’amendée, et le Règlement Général sur la Protection des Données personnelles.
        </p>
        <p>
          UTOCAT agit en qualité de sous-traitant s'agissant de la collecte des données personnelles
          de l'utilisateur. A ce titre, UTOCAT collecte et conserve les données à caractère
          personnel que l’utilisateur lui a volontairement fournies. Ainsi, sont notamment réputées
          être des données à caractère personnel relatives à l’identité, numéro de téléphone,
          adresse email, domicile, compte-titres de l’investisseur, à l’adresse IP de l’ordinateur
          d’une personne physique.
        </p>
        <p>
          UTOCAT agit en qualité de sous-traitant pour les finalités de traitements de données à
          caractère personnel ci-après :
        </p>
        <ul>
          <li>
            respect de toute disposition légale ou réglementaire applicable, notamment en matière de
            prévention du blanchiment d’argent et du financement du terrorisme ;
          </li>
          <li>
            traitement/gestion et archivage des Opérations de chargement des Données et des
            Opérations d’investissement ;
          </li>
          <li>
            contrôle et de prévention des incidents et irrégularités (lutte contre la fraude et tout
            autre type d’abus) ;
          </li>
          <li>traitement des demandes de l’Utilisateur ;</li>
          <li>contrôle de la qualité du service ;</li>
          <li>et de proposition de nouveaux services.</li>
        </ul>
        <p>UTOCAT agit en qualité de responsable de traitement pour les finalités ci-après :</p>
        <ul>
          <li>établissement de statistiques d'usage des Services et de mesure d'audience.</li>
        </ul>
        <p>
          L'Utilisateur est informé que ses données personnelles peuvent être enregistrées dans un
          ou plusieurs fichiers dans le respect de la législation applicable et accepte que les
          données collectées soient enregistrées et traitées conformément aux finalités précédemment
          énoncées.
        </p>
        <p>
          L'Utilisateur accepte que les données personnelles strictement nécessaires à la
          réalisation d'une au moins des finalités mentionnées ci-dessus ou à ce qui est requis en
          vertu de la réglementation applicable, soient communiquées par UTOCAT :
        </p>
        <ul>
          <li>
            aux sous-traitants et prestataires de services externalisés dont l'intervention est
            nécessaire ;
          </li>
          <li>
            aux parties prenantes de l'Opération d'investissement pour qui la connaissance de ses
            Données personnelles est nécessaire ;
          </li>
          <li>aux responsables de traitement pour le compte desquels UTOCAT est sous-traitant.</li>
        </ul>
        <p>
          L'Utilisateur accepte que la communication de ses informations personnelles aux personnes
          susvisées puisse se faire, conformément aux conditions précédemment définies, vers un
          autre pays de l'Union Européenne et également vers un pays non membre de l'Union
          Européenne qui garantit un niveau de protection adéquat eu égard à la loi précitée.
          Toutefois, à ce jour, l'ensemble des serveurs utilisés par UTOCAT se situe en France.
        </p>
        <p>
          Les Données personnelles des Utilisateurs sont conservées par UTOCAT pendant toute la
          durée de l'investissement à laquelle est ajoutée une période de cinq ans. À la fin de
          cette période, une demande est envoyée au Délégué à la Protection des Données personnelles
          de la Banque et, le cas échéant, de l'Intermédiaire, pour savoir si lesdites données
          peuvent être supprimées.
        </p>
        <p>
          L’information relative aux traitements de données que l'Utilisateur communique figure
          alors dans la Politique de protection des données personnelles de la Caisse régionale,
          disponible sur son site internet à l’adresse suivante :{' '}
          <a
            href={`https://www.credit-agricole.fr/${subdomain}/particulier/informations/politique-de-protection-des-donnees-personnelles-de-la-caisse-regionale.html`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`https://www.credit-agricole.fr/${subdomain}/particulier/informations/politique-de-protection-des-donnees-personnelles-de-la-caisse-regionale.html`}
          </a>
          .
        </p>
        <p>L’Utilisateur peut à tout moment, dans les conditions prévues par la loi :</p>
        <ul>
          <li>accéder à ses données personnelles ;</li>
          <li>les faire rectifier ;</li>
          <li>
            s'opposer :
            <ul>
              <li>
                à leur traitement pour des raisons tenant à sa situation particulière, lorsque la
                base juridique du traitement est l’intérêt légitime de la Caisse régionale ou de
                tiers (sauf à ce que la Caisse régionale ne prouve qu’il existe des motifs légitimes
                et impérieux pour ce traitement qui prévalent sur ses intérêts et ses droits et
                libertés, ou pour la constatation, l’exercice ou la défense de droit en justice) ;
              </li>
              <li>
                à tout moment et sans justification, à leur traitement à des fins de prospection
                commerciale par la Caisse régionale ou par des tiers ;
              </li>
            </ul>
          </li>
          <li>
            demander leur effacement : l'Utilisateur peut demander la suppression de ses données
            personnelles, et en particulier lorsque les données ne sont plus nécessaires au regard
            des finalités pour lesquelles elles ont été collectées, à l’exception notamment des
            traitements nécessaires au respect d’une obligation légale ou à la constatation, à
            l’exercice ou la défense de droit en justice ;
          </li>
          <li>
            demander la limitation de leur traitement : l'Utilisateur peut la suspension ou la
            restriction du traitement de ses données ;
          </li>
          <li>
            demander leur portabilité : lorsque le traitement est automatisé et est fondé sur le
            consentement ou l’exécution du contrat ou de mesures précontractuelles que l'Utilisateur
            a fournies et/ou leur transfert à un tiers ;
          </li>
          <li>
            communiquer des instructions sur leur sort en cas de décès : l'Utilisateur peut définir
            des directives relatives à la conservation, à l’effacement et à la communication de ses
            données à caractère personnel, applicables après son décès.
          </li>
        </ul>
        <p>
          L'Utilisateur peut enfin, lorsque le traitement a pour base légale le consentement,
          retirer ce consentement pour l’avenir et mettre ainsi fin au traitement de ses données,
          étant précisé que le retrait du consentement ne remet pas en cause la licéité des
          traitements effectués jusqu’alors.
        </p>
        <p>
          Si l'Utilisateur souhaite exercer l’un de ses droits, il lui suffit d’écrire, en indiquant
          le(s) droit(s) qu'il souhaite exercer ainsi que tout élément permettant si nécessaire son
          identification (pièce d’identité, numéro de contrat) par courrier électronique à l’adresse
          suivante : {CaDetails.dpoMail} ou par lettre simple signée à :{' '}
          {CA_DETAILS[subdomain].address}.
        </p>
        <p>
          L'Utilisateur est prié de noter que l’exercice de certains de ces droits pourra empêcher
          la Caisse régionale de fournir, selon les cas, certains produits ou services.
        </p>
        <p>
          L'Utilisateur peut également en cas de contestation, former une réclamation auprès de la
          CNIL dont le site internet est accessible à l’adresse suivante :{' '}
          <a href="https://www.cnil.fr" target="_blank" rel="noopener noreferrer">
            https://www.cnil.fr
          </a>
          .
        </p>
      </section>
    );
  };

  return (
    <div>
      <Topbar hideAccountInfos />
      <div className="container wrapper">
        <div className="main-title">
          <h1 className="title title--bold title--center">
            Catalizr -Conditions Générales d'Utilisation
          </h1>
          <h1 className="title title--center">Version février 2022</h1>
        </div>
        <section>
          <p>
            Conclues entre <br />
            L'Utilisateur du Site Internet Catalizr,
            <br />
            et,
            <br />
            UTOCAT, société par actions simplifiée au capital de 16 991 Euros dont le siège social
            est situé au 165, avenue de Bretagne, 59000 Lille, inscrite au Registre du Commerce et
            des Sociétés de Lille sous le numéro 807 681 424, représentée par Clément Francomme.
          </p>
          <p>
            L'Utilisateur peut à tout moment consulter les présentes Conditions Générales
            d'Utilisation, les reproduire, les stocker sur son ordinateur ou sur un autre support,
            les transférer par courrier électronique ou les imprimer sur papier de manière à les
            conserver.
          </p>
        </section>
        <div className="content">
          <section>
            <h2 className="title">1 - OBJET</h2>
            <div>
              <p>
                Les présentes « Conditions Générales d'Utilisation » ou « CGU » sont consultables à
                tout moment sur le Site Internet{' '}
                <a
                  target="_blank"
                  href="https://www.utocat.com/cgu-catalizr/"
                  rel="noopener noreferrer"
                >
                  (https://www.utocat.com/cgu-catalizr/)
                </a>
                . Elles régissent les termes et conditions :
              </p>
              <ul>
                <li>
                  de l'ouverture d'un Compte d'utilisation sur le Site Internet Catalizr afin de
                  bénéficier des services proposés ;
                </li>
                <li>de l'utilisation dudit compte par l'Utilisateur ;</li>
                <li>de la fourniture des services par Catalizr ;</li>
                <li>
                  et plus généralement, de la manière dont l'Utilisateur est amené à utiliser les
                  Services proposés sur le Site Internet.
                </li>
              </ul>
            </div>
            <p>
              UTOCAT attire l'attention de l'Utilisateur sur le contenu desdites CGU et l'invite à
              les lire avant d'utiliser le Site Internet.
            </p>
          </section>
          <section>
            <h2 className="title">2 – DÉFINITIONS</h2>
            <div>
              <p>
                Les termes utilisés dans les présentes CGU auront, lorsqu'ils sont utilisés avec la
                première lettre en majuscule et indépendamment du fait qu'ils soient utilisés au
                singulier ou au pluriel, le sens défini ci-après :
              </p>
              <p>
                <span className="title">Banque : </span> désigne la personne morale détenant le
                Compte-titres de l'Investisseur et requérant la fourniture de documents générés par
                Catalizr pour l'inscription des titres émis par l'Emetteur dans le Compte-titres de
                l'Investisseur.
              </p>
              <p>
                <span className="title">Compte d'utilisation : </span> désigne l'espace créé sur le
                Site Internet par l'Utilisateur permettant d'effectuer les Opérations de chargement
                des Données.
              </p>
              <p>
                <span className="title">Compte-titres de l'Investisseur : </span> désigne le
                compte-titres, le PEA (plan d'épargne en actions), le PEA-PME (plan d'épargne en
                actions pour les petites et moyennes entreprises), le PER (plan épargne retraite),
                l'assurance vie ou encore le FAS (fonds d'assurance spécialisé) de l'Investisseur,
                et plus largement tout compte dans lequel peuvent être inscrits les titres
                financiers dont l'Investisseur est titulaire.
              </p>
              <p>
                <span className="title">Données : </span> désigne l'ensemble des informations
                fournies par l'Utilisateur (notamment mais non exhaustivement les données
                personnelles, les données de connexion) et générées par UTOCAT à travers ses
                Services.
              </p>
              <p>
                <span className="title">Données personnelles : </span> désigne l'ensemble des
                données personnelles de l'Utilisateur au sens de la réglementation en vigueur.
              </p>
              <p>
                <span className="title">Emetteur : </span> désigne la personne morale à l'origine de
                l'Opération d'investissement.
              </p>
              <p>
                <span className="title">Intermédiaire :</span> désigne toute personne morale
                intervenant, le cas échéant, en qualité d'intermédiaire dans l'Opération
                d'Investissement (pour illustration, un prestataire de services de financement
                participatif) et procédant à l'Opération de chargement des Données aux fins de les
                transmettre à la Banque de l'Investisseur pour l'inscription des titres émis par
                l'Emetteur dans le Compte-titres de l'Investisseur.
              </p>
              <p>
                <span className="title">Investisseur : </span> désigne la personne physique partie à
                l'Opération d'investissement souhaitant inscrire les titres issues de ladite
                Opération dans son Compte-titres détenu dans les livres de la Banque.
              </p>
              <p>
                <span className="title">Opération de chargement des Données : </span> désigne les
                opérations tendant (i) à renseigner les informations requises par le Site Internet
                pour accomplir les formalités liées à l'Opération d'investissement, notamment liées
                aux exigences légales et réglementaires, (ii) à télécharger les documents requis par
                le Site Internet pour accomplir les formalités liées à l'Opération d'investissement
                ou les documents générés par Catalizr, et (iii) à visualiser l'état d'avancement du
                dossier d'investissement.
              </p>
              <p>
                <span className="title">Opération d'investissement :</span> désigne la souscription,
                l'achat ou la vente de titres émis par l'Emetteur et destinés à être inscrits, ou
                déjà inscrits, dans le Compte-titres de l'Investisseur.
              </p>
              <div>
                <p>
                  <span className="title">Services : </span> désigne les fonctionnalités offertes
                  par UTOCAT sur le Site Internet, à savoir :
                </p>
                <ul>
                  <li>
                    la génération des documents requis par la Banque pour le traitement de ceux-ci
                    au format exigé par les dispositions fiscales en vigueur ;
                  </li>
                  <li>
                    le traitement de l'ensemble des documents nécessaires pour l'Opération
                    d'investissement ;
                  </li>
                  <li>
                    la transmission des dossiers d'investissement par email à la Banque et la
                    gestion des retours d'informations ;
                  </li>
                  <li>
                    l'envoi annuel à la Banque des informations nécessaires à la circularisation des
                    titres, notamment la propriété et la valeur des titres ;
                  </li>
                  <li>
                    la conservation de preuves numériques et la certification des données des
                    Utilisateurs ou des Opérations (sur option, il est possible de recourir à la
                    technologie blockchain).
                  </li>
                </ul>
              </div>
              <p>
                <span className="title">Site Internet : </span> désigne le site internet dont l'URL
                se termine par catalizr.eu à partir duquel UTOCAT propose les Services.
              </p>
              <p>
                <span className="title">Utilisateur :</span> désigne toute personne physique ou
                morale, Investisseur, Emetteur,Banque ou Intermédiaire, ou leur représentant,
                disposant d'un Compte d'utilisation.
              </p>
            </div>
            <p>
              UTOCAT attire l'attention de l'Utilisateur sur le contenu desdites CGU et l'invite à
              les lire avant d'utiliser le Site Internet.
            </p>
          </section>
          <section>
            <h2 className="title">3 – OUVERTURE DU COMPTE D'UTILISATION</h2>
            <div>
              <p>
                L'Utilisateur doit satisfaire la procédure d'ouverture de Compte d'utilisation
                ci-après décrite.
              </p>
              <div>
                <h3 className="title title--italic">
                  3.1 – Déclarations préalables de l'Utilisateur
                </h3>
                <p>
                  L'Utilisateur, personne morale ou physique majeure capable, déclare expressément
                  avoir la capacité et/ou avoir reçu les autorisations requises pour utiliser les
                  Services fournis par UTOCAT et garantit UTOCAT contre toute responsabilité pouvant
                  résulter pour elle d'une fausse déclaration.
                </p>
                <p>
                  L'Utilisateur déclare agir pour son compte. L'Utilisateur a l'obligation
                  d'utiliser les services fournis par UTOCAT de bonne foi, à des fins licites
                  uniquement, et dans le respect des présentes dispositions.
                </p>
              </div>
              <div>
                <h3 className="title title--italic">3.2 – Création d'un Compte d'utilisation</h3>
                <p>
                  Pour effectuer les Opérations de chargement de Données, ou pour consulter le
                  processus, tout Utilisateur doit créer un Compte d'utilisation à partir d'une
                  sollicitation émise électroniquement par UTOCAT, la Banque ou l'Intermédiaire.
                </p>
                <p>
                  Lors de cette première connexion sur le Site Internet et afin de bénéficier des
                  Services proposés sur celui-ci, l'Utilisateur sera invité à se créer un Compte
                  d'utilisation à partir d'une adresse email et d'un mot de passe (ci-après “les
                  Identifiants”) qu'il aura choisi.
                </p>
                <p>La gestion des Identifiants est décrite à l'article 4.</p>
              </div>
              <div>
                <h3 className="title title--italic">
                  3.3 – Acceptation d'ouverture de Compte d'utilisation
                </h3>
                <p>
                  UTOCAT peut refuser d'ouvrir un Compte d'utilisation pour tout motif sans avoir à
                  justifier sa décision. Celle-ci ne pourra pas donner lieu au versement
                  d'indemnités.
                </p>
              </div>
            </div>
          </section>
          <section>
            <h2 className="title">4 – GESTION DES IDENTIFIANTS</h2>
            <p>
              Les identifiants de l'Utilisateur comprennent généralement plusieurs codes, logins ou
              mots de passe permettant d'accéder au Compte d'utilisation et de l'utiliser.
            </p>
            <p>
              Ces identifiants sont strictement personnels et confidentiels. L'Utilisateur s'engage
              par conséquent à les garder secrets et à ne pas les divulguer sous quelque forme que
              ce soit. En outre, l'Utilisateur ne peut en aucun cas céder ni concéder ses
              identifiants, à titre gratuit ou onéreux.
            </p>
            <p>
              En conséquence, toute utilisation des identifiants de l'Utilisateur est effectuée sous
              son entière responsabilité.
            </p>
            <p>
              En cas de perte, de vol ou d'utilisation détournée d'un ou de plusieurs des éléments
              composant ses identifiants, l'Utilisateur s'engage à en informer UTOCAT dans les plus
              brefs délais à l'adresse support [at] utocat.com. Par mesure de sécurité, UTOCAT
              pourra donc annuler les identifiants de l'Utilisateur après réception et traitement de
              cette information. Le cas échéant, UTOCAT communiquera à l'Utilisateur de nouveaux
              identifiants. A défaut d'information d'UTOCAT, et sauf preuve contraire, toute
              connexion ou transmission d'ordres ou de données effectuées au moyen des identifiants
              de l'Utilisateur sera réputée provenir de ce dernier et sera sous sa responsabilité
              exclusive.
            </p>
          </section>
          <section>
            <h2 className="title">5 – SIGNATURE ELECTRONIQUE</h2>
            <p>
              L'Utilisateur sera amené dans certains cas à (i) contresigner des documents qu'il aura
              lui-même téléchargé sur le Site Internet ou (ii) signer des documents téléchargés par
              un autre Utilisateur ou générés par Catalizr.
            </p>
            <p>
              Pour ce faire, UTOCAT a recours aux services d'un prestataire spécialisé en signature
              électronique.
            </p>
            <p>
              Ce prestataire met à disposition d'UTOCAT un module de signature électronique.
              L'Utilisateur s'engage à respecter les conditions générales d'utilisation dudit
              module.
            </p>
            <p>
              Le prestataire spécialisé en signature électronique ainsi qu'UTOCAT délèguent la
              responsabilité de procéder à la vérification d'identité d'un Utilisateur signataire
              permettant de répondre aux exigences du “Règlement eIDAS” (Règlement N° 910/2014 du
              Parlement européen et du Conseil du 23 juillet 2014 sur l'identification électronique
              et les services de confiance pour les transactions électroniques au sein du marché
              intérieur et abrogeant la directive 1999/93/CE) à la Banque et/ou, le cas échéant, à
              l'Intermédiaire, ce que l'Utilisateur reconnaît et accepte expressément.
            </p>
          </section>
          <section>
            <h2 className="title">6 – RECOURS A LA TECHNOLOGIE BLOCKCHAIN</h2>
            <p>
              Dans le cadre de ses Services proposés sur le Site Internet, UTOCAT peut recourir à la
              technologie blockchain.
            </p>
            <p>
              Dans le cadre de ses Services proposés sur le Site Internet, UTOCAT peut recourir à la
              technologie blockchain.
            </p>
            <p>Le recours à la technologie blockchain est à la discrétion du client d'UTOCAT.</p>
            <p>
              UTOCAT s'engage à ne jamais inscrire dans la blockchain les Données personnelles des
              Utilisateurs.
            </p>
          </section>
          <section>
            <h2 className="title">7 – RECONNAISSANCE OPTIQUE DE CARACTERES</h2>
            <p>
              Dans le cadre de ses Services proposés sur le Site Internet, UTOCAT peut proposer à
              son client d'utiliser un module de reconnaissance optique de caractères.
            </p>
            <p>
              Ce module permet de lire les documents pour, notamment, mais non exhaustivement,
              identifier la présence de certaines mentions ou de certains signes.
              <br />
              Pour ce faire, UTOCAT a recours aux services d'un prestataire technique.
            </p>
            <p>
              L'Utilisateur s'engage à respecter les conditions générales d'utilisation dudit
              module.
            </p>
            <p>Le recours à ce module est à la discrétion du client d'UTOCAT.</p>
          </section>
          <section>
            <h2 className="title">8 – UTILISATION DE GOOGLE reCAPTCHA</h2>
            <p>
              Le Site peut utiliser “Google reCAPTCHA” (ci-après “reCAPTCHA”) dont le fournisseur
              est Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis
              (“Google”).
            </p>
            <p>
              Cet outil permet à UTOCAT de vérifier si la saisie de données sur le Site Internet est
              faite par une personne humaine ou par un programme automatisé. ReCAPTCHA analyse le
              comportement du visiteur du Site Internet en fonction de différentes caractéristiques.
              Cette analyse commence automatiquement dès que le visiteur du site web entre sur le
              Site Internet. Pour l'analyse, reCAPTCHA évalue diverses informations (par exemple,
              l'adresse IP, la durée de visite du visiteur sur le site Web ou les mouvements de la
              souris de l'utilisateur). Les données collectées lors de l'analyse sont utilisées par
              Google.
            </p>
            <p>
              Les analyses de reCAPTCHA se déroulent entièrement en arrière-plan. Les visiteurs du
              Site Internet ne sont pas informés qu'une analyse est en cours.
            </p>
            <p>
              UTOCAT a un intérêt légitime à protéger le Site Internet contre l'espionnage
              automatisé abusif et le SPAM.
            </p>
          </section>
          <section>
            <h2 className="title">9 – REPORTING</h2>
            <p>
              Lorsqu'un Investisseur renseigne des informations dans les champs prévus à cet effet
              sur le Site Internet ou télécharge des documents sur le Site Internet, UTOCAT envoie
              automatiquement une notification, sous forme d'email ou autre, à la Banque ou, le cas
              échéant, à l'Intermédiaire.
            </p>
          </section>
          <section>
            <h2 className="title">10 – RÉCLAMATIONS</h2>
            <p>
              Les réclamations qui portent sur les relations entre deux, ou plus, Utilisateurs ne
              sont pas recevables auprès de UTOCAT. Seules celles qui portent sur l'indisponibilité
              du Site Internet ou les Services sont visées par le présent article, en dehors des cas
              de maintenance courante et des cas d'indisponibilité résultants de tiers.
            </p>
            <p>
              Les réclamations (contestations, droits d'opposition, d'accès et de rectification,
              etc.) peuvent être exercées gratuitement sur demande adressée à UTOCAT par courrier
              électronique à l'adresse email suivante : support [at] utocat.com
            </p>
            <p>
              Toute contestation ou requête doit être notifiée à UTOCAT par l'Utilisateur dans les
              plus brefs délais à compter du jour où l'Utilisateur en a eu connaissance ou est
              présumé en avoir eu connaissance ou dans tout autre délai plus long prévu par des
              dispositions particulières ou par la loi.
            </p>
            <p>
              Les réclamations seront traitées sous deux mois maximum à compter de leur réception.
            </p>
          </section>
          <section>
            <div>
              <h3 className="title--italic">11.1 – Obligation de notification</h3>
              <p>
                L'Utilisateur a l'obligation d'informer immédiatement UTOCAT en cas de soupçon
                d'accès ou d'utilisation frauduleuse de son Compte d'utilisation ou de tout
                événement susceptible de mener à une telle utilisation, tels que et de manière non
                limitative : la perte, la divulgation accidentelle ou le détournement de ses
                identifiants de Compte d'utilisation.
              </p>
              <p>
                Cette notification doit s'effectuer par l'envoi d'un courrier électronique à
                l'adresse email suivante : support [at] utocat.com.
              </p>
            </div>
            <div>
              <h3 className="title--italic">11.2 – Prévention</h3>
              <p>
                UTOCAT fera ses meilleurs efforts pour empêcher toute autre utilisation du Compte
                d'utilisation.
              </p>
            </div>
            <div>
              <h3 className="title--italic">11.3 – Utilisation de cookies</h3>
              <p>
                UTOCAT informe l'Utilisateur que dans le cadre des Services, des cookies (fichiers
                envoyés par le serveur UTOCAT et qui s'enregistrent sur le disque dur de
                l'ordinateur de l'internaute) pourront être utilisés. Ces cookies servent avant tout
                à améliorer le fonctionnement du Service.
              </p>
              <p>
                Dans cet objectif, UTOCAT fait notamment appel à Hotjar pour optimiser la qualité et
                l'ergonomie des Services. Hotjar utilise des cookies pour collecter de manière
                anonyme des données sur le comportement et les actions des Utilisateurs.
              </p>
              <p>
                L'Utilisateur est informé qu'il peut refuser les cookies auprès de UTOCAT dans les
                paramètres de son navigateur.
              </p>
              <p>
                Pour en savoir plus, la{' '}
                <a
                  href="https://www.utocat.com/politique-de-confidentialite"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Politique de confidentialité et la Charte d'utilisation des cookies
                </a>{' '}
                sont disponibles sur le site internet d'UTOCAT.
              </p>
            </div>
            <div>
              <h3 className="title--italic">11.4 – Interruption des Services</h3>
              <p>
                UTOCAT s'engage à mettre en œuvre tous les moyens raisonnables à sa disposition pour
                assurer un service permanent. UTOCAT ne garantit toutefois pas l'accès continu,
                ininterrompu aux Services. En conséquence, UTOCAT ne pourra pas être tenu pour
                responsable du retard et/ou de la non accessibilité totale ou partielle aux
                Services, dès lors qu'ils résultent de facteurs échappant au contrôle raisonnable
                d'UTOCAT.
              </p>
              <p>
                L'Utilisateur est informé que UTOCAT peut interrompre, occasionnellement, l'accès à
                tout ou partie des Services pour permettre les réparations, maintenances, ajouts de
                fonctionnalité, en cas de suspicion de tentative de piratage, de détournement de
                fonds ou de tout autre risque d'atteinte sur demandes ou instructions émanant de
                personnes ou autorités compétentes habilitées. UTOCAT ne peut en aucun cas être
                tenue responsable des dommages résultants éventuellement de ces suspensions.
              </p>
              <p>
                Dès la reprise normale du service, UTOCAT mettra en œuvre les efforts raisonnables
                pour traiter les opérations en attente dans les meilleurs délais.
              </p>
            </div>
          </section>
          <section>
            <h2 className="title">12 – RESPONSABILITÉS</h2>
            <p>
              En aucun cas, UTOCAT n'est responsable des dommages indirects, tels que préjudice
              commercial, perte de clientèle, trouble commercial quelconque, perte de bénéfice,
              perte d'image de marque subis par un Utilisateur ou son représentant, ou par un tiers,
              et qui pourraient résulter des Services fournis par UTOCAT. Toute action dirigée
              contre un Utilisateur par un tiers ou un autre Utilisateur est assimilée à un
              préjudice indirect, et en conséquence n'ouvre pas droit à réparation.
            </p>
            <p>
              Sauf stipulation contraire des présentes CGU ou des lois impératives et sans préjudice
              des autres causes d'exclusion ou de limitation de responsabilité prévues par les
              présentes, UTOCAT ne pourra en aucun cas être tenue pour responsable de tout dommage
              causé par un cas de force majeure ou un événement hors de son contrôle ou de toute
              mesure ou dispositions législatives prises par les autorités françaises ou étrangères.
              Sont réputés constituer un cas de force majeure ou un événement hors de son contrôle,
              notamment, mais sans que cela soit limitatif : une panne d'électricité, un incendie ou
              une inondation, la grève de son personnel ou d'un de ses sous-traitant ou
              fournisseurs, un dysfonctionnement des systèmes bancaires, guerre, troubles, émeutes
              ou occupation du territoire par des forces étrangères, négligence d'un tiers dans le
              sens de la jurisprudence et de la doctrine telles que les personnes responsables de la
              livraison d'électricité ou des services de télécommunication.
            </p>
          </section>
          <section>
            <h2 className="title">13 – DÉCÈS OU LIQUIDATION</h2>
            <p>
              En cas de décès de l'Utilisateur personne physique ou de la liquidation de
              l'Utilisateur personne morale, UTOCAT doit en être avisée le plus rapidement possible
              par les ayants droit ou leur mandataire. Si cet avis est donné verbalement, il doit
              être confirmé par écrit. Dès réception de cet écrit, UTOCAT veillera à ce qu'aucune
              nouvelle Opération d'investissement ou Opération de chargement des Données ne soit
              exécutée et procèdera à la clôture du Compte.
            </p>
          </section>
          <section>
            <h2 className="title">14 – PROPRIÉTÉ INTELLECTUELLE</h2>
            <p>
              Aucun droit de propriété intellectuelle relatif à l'utilisation du Service ou aux
              prestations rendues par UTOCAT n'est transféré à l'Utilisateur au titre des présentes
              CGU.
            </p>
            <p>
              L'Utilisateur s'engage à ne pas porter atteinte aux droits détenus par UTOCAT, en
              s'interdisant notamment, toute reproduction, ou adaptation de tout ou partie des
              éléments intellectuels et matériels de UTOCAT et ses accessoires, et ce quel que soit
              le support, actuel et futur.
            </p>
            <p>
              Sauf disposition contraire des présentes, l'ensemble des droits relatifs aux logiciels
              servant à la réalisation des Services sont la propriété pleine et entière de la
              société UTOCAT. Ils font partie de ses secrets de fabrication et informations
              confidentielles sans égard au fait que certaines composantes puissent ou non être
              protégées en l'état actuel de la législation par un droit de propriété intellectuelle.
            </p>
            <p>
              Les logiciels de UTOCAT et, le cas échéant, leur documentation, sont reconnus par
              l'Utilisateur comme œuvre de l'esprit que lui-même et les membres de son personnel,
              s'ils existent, s'obligent à considérer comme telle en s'interdisant de les copier, de
              les reproduire, de les traduire en toute autre langue ou langage, de les adapter, de
              les distribuer à titre gratuit ou onéreux, ou de leur adjoindre tout objet non
              conforme à leurs spécifications.
            </p>
            <p>
              Les marques « UTOCAT » et « CATALIZR » sont la propriété de la société UTOCAT. Sauf
              autorisation expresse d'UTOCAT, l'Utilisateur s'engage à ne pas supprimer la mention
              de ces marques sur tout élément fourni ou mis à sa disposition par UTOCAT, tels que
              logiciel, document ou bannière publicitaire.
            </p>
          </section>
          <section>
            <h2 className="title">15 – CONFIDENTIALITÉ</h2>
            <p>
              L'Utilisateur s'engage à respecter la plus stricte confidentialité concernant
              l'ensemble des techniques, commerciales ou de toute autre nature dont il viendrait à
              avoir connaissance dans le cadre de l'exécution du Service.
            </p>
            <p>
              Cette obligation de confidentialité demeurera en vigueur pendant la durée
              d'utilisation du Service et pendant trois ans suivant la date de résiliation du
              Contrat par lequel l'Utilisateur peut être lié. Cette obligation de confidentialité ne
              s'applique pas aux informations qui sont ou deviennent publiquement disponibles sans
              faute de l'Utilisateur.
            </p>
            <p>
              UTOCAT s'engage à fournir ses meilleurs efforts pour garantir la confidentialité des
              informations fournies par chaque Utilisateur lors de l'utilisation du Service. UTOCAT
              s'engage à ne pas révéler ces informations à un tiers, en dehors des parties prenantes
              à l'Opération d'investissement, de ses sous-traitants ou partenaires techniques et des
              autorités judiciaires, administratives et fiscales.
            </p>
            <p>
              Chaque Utilisateur s'engage à ne pas tenter de découvrir, récupérer et conserver les
              Données d'un autre Utilisateur en dehors des cas requis pour accomplir les formalités
              liées à l'Opération d'investissement.
            </p>
            <p>
              Il est rappelé que seul l'Emetteur dispose de l'information la plus étendue et la plus
              complète sur l'ensemble des parties prenantes à l'Opération d'investissement.
            </p>
          </section>
          {getArt16()}
          <section>
            <h2 className="title">17 - CONVENTION DE PREUVE</h2>
            <p>
              Les communications effectuées par le biais de courrier électronique sont des modes de
              communication valablement admis à titre de preuve par l'Utilisateur et UTOCAT.
            </p>
            <p>
              Toutes les informations enregistrées dans les bases de données informatiques de UTOCAT
              ont, jusqu'à preuve du contraire, la même force probante qu'un écrit signé sur un
              support papier, tant en ce qui concerne leur contenu qu'en ce qui concerne la date et
              l'heure à laquelle ils ont été effectués et/ou reçus. Ces traces inaltérables, sûres
              et fiables sont gravées et conservées dans les systèmes informatiques de UTOCAT.
            </p>
            <p>
              Les documents de UTOCAT reproduisant ces informations, ainsi que les copies ou
              reproductions de documents produits par UTOCAT ont la même force probante que
              l'original, jusqu'à preuve du contraire.
            </p>
          </section>
          <section>
            <h2 className="title">18 – BLOCAGE DU COMPTE</h2>
            <p>
              La suspension temporaire et immédiate du Compte d'utilisation peut être prononcée par
              UTOCAT pour toute raison à l'appréciation de UTOCAT et notamment :
            </p>
            <ul>
              <li>
                si l'Utilisateur n'a pas respecté les dispositions des présentes CGU ou du Contrat
                par lequel il est lié, s'il existe ;
              </li>
              <li>
                si l'Utilisateur a fourni à UTOCAT des données d'identification inexactes, périmées
                ou incomplètes ;
              </li>
              <li>
                en cas de risque de fraude, de blanchiment de capitaux ou financement du terrorisme
                ou de risque pouvant affecter la sécurité du Compte d'utilisation.
              </li>
            </ul>
            <p>
              Cette décision est motivée et notifiée à l'Utilisateur par tout moyen. La suspension
              du Compte d'utilisation ayant pour objet de protéger l'Utilisateur, elle ne pourra en
              aucun cas donner lieu au versement de dommages intérêts au profit de ce dernier.
            </p>
            <p>La réactivation du Compte d'utilisation se fera à la discrétion de UTOCAT.</p>
          </section>
          <section>
            <h2 className="title">19 – MODIFICATION DES PRESENTES CGU</h2>
            <p>
              UTOCAT se réserve le droit de modifier unilatéralement les présentes CGU en cas de
              modification du Service (notamment ajout d'une ou plusieurs fonctionnalités et
              suppression d'une ou plusieurs fonctionnalités) et pour respecter les dispositions
              légales en vigueur.
            </p>
          </section>
          <section>
            <h2 className="title">20 – GENERALITES</h2>
            <p>
              Au cas où des formalités administratives seraient nécessaires au titre de l'exécution
              des présentes CGU, UTOCAT et l'Utilisateur se prêteront mutuelle assistance pour la
              régularisation de ces formalités.
            </p>
            <p>
              Si l'une quelconque des stipulations non substantielles des CGU est nulle au regard
              d'une règle de droit en vigueur, elle sera réputée non écrite, mais n'entraînera pas
              la nullité des présentes CGU.
            </p>
            <p>
              Le fait pour l'une des Parties de ne pas se prévaloir d'un manquement par l'autre
              Partie à l'une quelconque des obligations visées dans les présentes ne saurait être
              interprété pour l'avenir comme une renonciation à l'obligation en cause.
            </p>
          </section>
          <section>
            <h2 className="title">21 – DROIT APPLICABLE ET JURIDICTIONS</h2>
            <p>Les présentes Conditions Générales sont régies par le droit français.</p>
            <p>
              Sauf disposition impérative contraire, tout litige relatif à leur exécution, leur
              interprétation ou sa validité, sera porté devant les cours et tribunaux du lieu du
              siège social d'UTOCAT.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

CGUCatalizr.propTypes = {};

export default CGUCatalizr;
