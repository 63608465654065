import React from 'react';
import PropTypes from 'prop-types';
import IconPowerSvg from './svg/icon-power-svg';
import './styles.scss';

const IconPower = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="power">
      <IconPowerSvg className="power__icon" title={title} alt={alt} width={width} height={height} />
    </div>
  );
};

IconPower.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconPower;
