import React from 'react';
import PropTypes from 'prop-types';

const IconEmailNoStatusSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M22.1 21.5L2.4 1.7 1.1 3l1.5 1.5c-.3.4-.6.9-.6 1.5v12c0 1.1.9 2 2 2h14.1l2.7 2.7 1.3-1.2M4 18V8l5.6 3.5 6.5 6.5H4M9.2 6l-2-2H20c1.1 0 2 .9 2 2v12c0 .2 0 .5-.1.7L20 16.8V8l-5.4 3.4-1.2-1.2L20 6H9.2z" />
    </svg>
  );
};

IconEmailNoStatusSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailNoStatusSvg;
