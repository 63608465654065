/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Button from '../../../components/Button';
import InputField from '../../../components/InputField';
import InputCheckbox from '../../../components/InputCheckbox';
import SpinnerWrapper from '../../../components/Spinner';
import IconQuestionmark from '../../../iconsPictos/iconQuestionMark';

// Actions
import { fetchSSOStatus } from '../../../shared/actions/actions/sso.actions';

// Utils
import { possibleAccountStatus } from '../../../shared/utils/user';

import '../styles.scss';

const LoginForm = props => {
  const user = useSelector(state => state.user);
  const sso = useSelector(state => state.sso);
  const bankLogo = useSelector(state => state.bank.logo);

  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(localStorage.getItem('rememberme') ? true : false);
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem('rememberme') ? localStorage.getItem('rememberme') : user.email,
  );
  const dispatch = useDispatch();

  function handleChange(e) {
    setUserEmail(e.target.value);
  }

  const {
    login,
    setUserEmailLogin,
    resetPassword,
    onSubmit,
    checkAccountIsActive,
    history,
    location,
    setErrorMessage,
    clearMessages,
  } = props;

  const locationSearch = new URLSearchParams(location.search);

  useEffect(() => {
    if (locationSearch.get('SSORedirect') === 'true') {
      dispatch(fetchSSOStatus());
    }
  }, []);

  useEffect(() => {
    if (
      sso.enabled &&
      sso.redirectUrl &&
      (sso.roles?.includes(user.type) || locationSearch.get('SSORedirect') === 'true')
    ) {
      if (!location.pathname.includes('/login')) {
        localStorage.setItem('ssoRedirectUrl', location.pathname);
      }
      window.location.assign(sso.redirectUrl);
      return;
    }
  }, [sso.enabled, user.type]);

  const rememberMe = email => {
    if (email) {
      localStorage.setItem('rememberme', email);
    }
  };

  const forgetMe = () => {
    localStorage.removeItem('rememberme');
  };

  // define what happens when we click on the "connexion button"
  const handleFormSubmit = (status, onSubmit, checkAccountIsActive, history, location, options) => {
    const userEmailToTrim = user.email || userEmail;
    const email = userEmailToTrim.trim();
    // if the account exists and the connexion button is clicked, we login as usual
    // if the 'allow.link_forgot_password' is on for this account we display : LINK TO RESET PASSWORD
    if (status === possibleAccountStatus.NOT_CHECKED) {
      checkAccountIsActive(email);
    } else {
      onSubmit(email, password, history, location, options);
    }
    if (remember) {
      rememberMe(email);
    }
  };

  // create a password input field that will be used if the account is active
  // display the reset password link if the 'allow.link_forgot_password' is enable for the user role account
  // role like backoffice not allowed this feature because account is shared by several persons (template email doesn't exist)
  const forgetPwdLinkIsAuthorized = login.isFeatureEnable;
  const passwordInput = (
    <div>
      <InputField
        topClass="login__input-field form-group"
        type="password"
        cssId="login-form-password"
        label="Mot de passe"
        placeholder="Mot de passe"
        onChange={e => setPassword(e.target.value)}
        passwordCanBeShown={true}
      />
      {/* LINK TO RESET PASSWORD */}
      {forgetPwdLinkIsAuthorized && (
        <div className="login__forget__password">
          <IconQuestionmark
            width="15px"
            height="15px"
            title="forget password"
            alt="forget password"
            noTheme={true}
          />
          &nbsp;
          <a
            id="login__link-forgot-pwd"
            data-testid="login__forgot-password-link"
            onClick={() =>
              userEmail
                ? resetPassword(userEmail)
                : setErrorMessage(
                    'Merci de renseigner votre email pour réinitialiser votre mot de passe',
                  )
            }
          >
            Mot de passe oublié ?
          </a>
        </div>
      )}
    </div>
  );

  const isExpiredPwd = login.expiredPwd || login.errorMessage === 'force_reset_password';

  // display the password input or nothing according to the status of the account
  const passwordInputToUse =
    user.status !== possibleAccountStatus.NOT_CHECKED ? passwordInput : null;

  // (Password input is hidden, email is setted) OR (password input is displayed, setted and email too)
  const isButtonDisabled = !(
    (!passwordInputToUse && user.email && user.email.length) ||
    (passwordInputToUse && password.length && user.email && user.email.length) ||
    userEmail
  );

  const loginButton = (
    <Button
      text="Connexion"
      disabled={isButtonDisabled || isExpiredPwd}
      design="primary"
      cssId="login__form-button"
      type="submit"
    />
  );

  let forgotPassword =
    login.errorMessage ===
    "Vous devez activer votre compte en cliquant sur le lien de l'email de confirmation. Si vous ne l'avez pas reçu veuillez" ? (
      <a
        id="login__link-forgot-pwd"
        data-testid="login__forgot-password-link"
        onClick={() => resetPassword(user.email)}
      >
        cliquer ici
      </a>
    ) : null;

  const forceResetPassword = (
    <div className="login__feedback alert-danger" data-testid="login__error-feedback">
      Conformément à nos règles de sécurité, votre mot de passe doit être renouvelé périodiquement.
      Un email vous permettant de définir un nouveau mot de passe vient de vous être envoyé.
    </div>
  );

  // Email input field
  const emailInput = (
    <div>
      <InputField
        topClass="login__input-field form-group"
        type="email"
        cssId="login-form-email"
        label="Identifiant ou email"
        placeholder="Ex : exemple@email.com"
        onChange={event => {
          handleChange(event);
          setUserEmailLogin(event.target.value.toLowerCase());
          clearMessages();
        }}
        restrictTo="[^<>()\[\]\\,;:\s]"
        initialValue={userEmail}
      />
      <InputCheckbox
        size="small"
        labelText="Mémoriser mon identifiant"
        defaultChecked={remember}
        onChange={isChecked => {
          if (!isChecked.checked) {
            forgetMe();
          }
          setRemember(isChecked.checked);
        }}
      />
    </div>
  );

  return (
    <form
      data-testid="login__form"
      onSubmit={e => {
        e.preventDefault();
        handleFormSubmit(user.status, onSubmit, checkAccountIsActive, history, location);
      }}
      className="login__form"
    >
      {bankLogo && (
        <img
          className="bank-logo"
          src={`${process.env.REACT_APP_BACKEND_URL}/api${bankLogo}`}
          alt="bank logo"
        />
      )}

      {/* EMAIL INPUT FIELD */}
      {emailInput}
      {remember}
      {/* PASSWORD INPUT FIELD */}
      {!isExpiredPwd ? passwordInputToUse : null}

      {/* POTENTIAL ERROR MESSAGE */}
      {login.errorMessage && !isExpiredPwd ? (
        <div className="login__feedback alert-danger" data-testid="login__error-feedback">
          {login.errorMessage} {forgotPassword}
        </div>
      ) : null}

      {isExpiredPwd ? forceResetPassword : null}

      {/* POTENTIAL INFORMATION MESSAGE */}
      {login.infoMessage ? (
        <div className="login__feedback alert-success" data-testid="login__info-feedback">
          {login.infoMessage}
        </div>
      ) : null}

      {/* LOGIN BUTTON */}
      <div className="login__main__footer">
        {login.loadingInProgress ? (
          <SpinnerWrapper isLoading={login.loadingInProgress} />
        ) : (
          loginButton
        )}
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  // TODO comment this
  login: PropTypes.shape({
    loadingInProgress: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    pwdExpired: PropTypes.bool,
    infoMessage: PropTypes.string.isRequired,
    isFeatureEnable: PropTypes.bool.isRequired,
    expiredPwd: PropTypes.bool,
  }).isRequired,
  // trigger actions
  setUserEmailLogin: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  checkAccountIsActive: PropTypes.func.isRequired,
  setAccountIsLoading: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  setResetPasswordIsLoading: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
};

export default LoginForm;
