const useReactHookFormDataStorage = () => {
  const getStorage = () =>
    localStorage || window.localStorage || sessionStorage || window.sessionStorage;

  const saveFormDataToStorage = (formData, storageKey) => {
    const serializedData = JSON.stringify(formData);
    getStorage().setItem(storageKey, serializedData);
  };

  const loadFormDataFromStorage = storageKey => {
    const storedData = getStorage().getItem(storageKey);
    return storedData ? JSON.parse(storedData) : null;
  };

  const clearFormDataStorage = storageKey => {
    getStorage().removeItem(storageKey);
  };

  return {
    loadFormDataFromStorage,
    clearFormDataStorage,
    saveFormDataToStorage,
  };
};

export default useReactHookFormDataStorage;
