// Const
import * as defaultConst from './const';
import * as landingConst from '../pages/landing/const/landing.const';
import * as commonConst from '../shared/actions/const/common.const';
import * as investDetailsConst from '../pages/invest-details/actions/invest-details.const';
import { JOURNEY } from '../shared/utils/JourneyUtils';

const initStateApp = {
  // the journey defines the transitions between views and many details
  journey: '',
  // investment link id to send mail with a link for investor
  investmentLinkId: '',
  // this is the id from catalizr inside pathname
  originalJourneyId: '',
  // list of document types flagged as not available by the user
  documentsMissing: [],
  // unique ID used to id the client for the asynchronous connexion with backend
  socketId: null,
  // flag indicating if the user asked to upload the attestation letter
  uploadAttestationLetter: false,
};

const defaultReducer = (state = initStateApp, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
      return initStateApp;

    case defaultConst.SET_INTERNAL_SERVER_ERROR:
    case landingConst.START_NEW_OPERATION_REJECTED:
    case investDetailsConst.INIT_FORM_REJECTED:
    case investDetailsConst.SUBMIT_FORM_REJECTED:
    case investDetailsConst.SUBMIT_FORM_FULFILLED:
    case landingConst.CLEAR_DATA:
      return { ...state };

    case defaultConst.SET_JOURNEY:
      if (action.journeyName === '' || Object.values(JOURNEY).includes(action.journeyName)) {
        return { ...state, journey: action.journeyName };
      }
      return state;

    case defaultConst.SET_INVESTMENT_LINK_ID:
      return { ...state, investmentLinkId: action.id };

    case defaultConst.RESET_DOCUMENTS_MISSING:
      return { ...state, documentsMissing: [] };

    case defaultConst.SET_DOCUMENT_MISSING:
      return { ...state, documentsMissing: [...state.documentsMissing, action.documentType] };

    case defaultConst.UNSET_DOCUMENT_MISSING:
      return {
        ...state,
        documentsMissing: state.documentsMissing.filter(d => d !== action.documentType),
      };

    case defaultConst.SET_ORIGINAL_JOURNEY_ID:
      return { ...state, originalJourneyId: action.id };

    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case commonConst.CLEAR_REDUX:
      return {
        ...state,
        investmentLinkId: '',
        // originalJourneyId /!\ Don't reset for /login investor when go to http://localhost:8080/invest-complete/5c52b91eb8cbd60063f822c4
        documentsMissing: [],
      };

    case defaultConst.SET_SOCKET_ID:
      return { ...state, socketId: action.newId };

    case landingConst.INIT_VIEW_PENDING:
      return { ...state, documentsMissing: [] };

    case landingConst.INIT_DEFAULT:
      return { ...state, investmentLinkId: action.id, documentsMissing: [] };

    case landingConst.START_NEW_OPERATION_FULFILLED:
      return { ...state, investmentLinkId: action.investmentLinkId };

    case defaultConst.SET_UPLOAD_ATTESTATION_LETTER:
      return { ...state, uploadAttestationLetter: action.uploadAttestationLetter };

    default:
      return state;
  }
};

export default defaultReducer;
