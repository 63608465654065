import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import CatalizrLoader from './components/CatalizrLoader';
import PublicRouter from './public-router';

// Set baseweb themes
import { BaseProvider } from 'baseui';
import { default_theme, CA_theme } from './shared/utils/themeUtils';
// Set styled components themes
import { ThemeProvider } from 'styled-components';
import { styled_component_themes } from './styled.components/variables';
import './main.scss';
// Internationalization
import { RawIntlProvider } from 'react-intl';
import { intl, updateIntl } from './intlConfig';

const PrivateRouter = loadable(() => import('./private-router'), {
  fallback: <CatalizrLoader />,
});

interface RootState {
  user: {
    locale: string;
    logged: boolean;
    theme: string;
  };
  bank: any;
}

const THEME = {
  DEFAULT: 'default',
  CA: 'ca',
  LCL: 'default',
};

const App = () => {
  const user = useSelector((state: RootState) => state.user);
  const bank = useSelector((state: RootState) => state.bank);
  const [baseUITheme, setBaseUITheme] = useState(THEME.DEFAULT);
  const overlayDiv = document.getElementById('overlay');
  const rootDiv = document.getElementById('root');
  const locale = user.locale;
  const [currentIntl, setCurrentIntl] = useState(intl);

  useEffect(() => {
    const updateLanguage = async () => {
      const newIntl = await updateIntl(locale);
      setCurrentIntl(newIntl);
    };
    updateLanguage();
  }, [locale]);

  if (overlayDiv && rootDiv) {
    overlayDiv.className = `theme--${user.theme}`;
    rootDiv.className = `theme--${user.theme}`;
  }

  useEffect(() => {
    setBaseUITheme(user.theme);
  }, [user.theme]);

  const manageBaseUITheme = (theme: string) => {
    return (
      {
        [THEME.CA]: CA_theme,
        [THEME.LCL]: default_theme,
      }[theme] || default_theme
    );
  };

  const manageStyledComponentTheme = (theme: string) => {
    return (
      {
        [THEME.CA]: styled_component_themes.ca,
        [THEME.LCL]: styled_component_themes.default,
      }[theme] || styled_component_themes.default
    );
  };

  return (
    <RawIntlProvider value={currentIntl}>
      <BaseProvider theme={manageBaseUITheme(baseUITheme) || default_theme}>
        <ThemeProvider theme={manageStyledComponentTheme(baseUITheme)}>
          <div className="app">
            {user.logged ? <PrivateRouter user={user} bank={bank} /> : <PublicRouter />}
          </div>
        </ThemeProvider>
      </BaseProvider>
    </RawIntlProvider>
  );
};

export default App;
