import * as investorConst from '../const/investor.const';
import { getInvestorAccountsByEmail as getInvestorAccountsByEmailApi } from '../../api/investor.api';
import { getCurrentPortfolioType } from '../../utils/CatalizrUtils';

const setInvestorAccountListPending = () => ({
  type: investorConst.INVESTOR_ACCOUNT_LIST_PENDING,
});

const setInvestorAccountListFulfilled = () => ({
  type: investorConst.INVESTOR_ACCOUNT_LIST_FULFILLED,
});

const setInvestorAccountListRejected = () => ({
  type: investorConst.INVESTOR_ACCOUNT_LIST_REJECTED,
});

export const resetInvestorState = () => ({ type: investorConst.RESET_STATE });
export const resetInvestorOnEmailDoesNotExist = () => ({
  type: investorConst.RESET_INVESTOR_ON_EMAIL_DOES_NOT_EXIST,
});
export const setInvestorEmail = email => ({ type: investorConst.SET_INVESTOR_EMAIL, email });
export const initInvestor = investor => {
  return {
    type: investorConst.INIT_INVESTOR,
    investor: { ...investor, currentPortfolioType: getCurrentPortfolioType() },
  };
};
export const getInvestorAccountsByEmail = email => async dispatch => {
  dispatch(setInvestorAccountListPending());
  try {
    const accounts = await getInvestorAccountsByEmailApi(email);
    dispatch(setInvestorAccountListFulfilled());
    return accounts.data;
  } catch (error) {
    dispatch(setInvestorAccountListRejected());
    throw new Error(`Error while getting accounts for email ${email} ${error}`);
  }
};
