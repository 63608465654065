import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LegalNotice from '../components/LegalNotice';
import { initView } from '../actions/legalNotice.actions';

const Container = props => {
  const state = useSelector(state => ({
    user: state.user,
    bank: state.bank,
  }));
  const dispatch = useDispatch();
  return <LegalNotice {...props} {...state} initView={() => dispatch(initView())} />;
};

export default withRouter(Container);
