// Const
import * as bankConst from '../actions/const/bank.const';
import * as commonConst from '../actions/const/common.const';

const initState = {
  features: [],
  featuresAsInvestor: [],
  featuresAsCompany: [],
  bankList: [],
  agencyList: [],
  advisorsList: [],
  operationTypeList: [],
  currentBankAgency: '',
  name: '',
  theme: '',
  logo: '',
  legal_notice_link: '',
  minimum_total_invest: '',
  charged_account: '',
  trading_account: false,
  draft_owner: '',
  membership_code: '',
  value: '',
};

const bankReducer = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
      return initState;

    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_LOGOUT_PENDING:
      return {
        ...initState,
      };

    case bankConst.GET_BANK_LIST_FULFILLED:
      return { ...state, bankList: action.payload.data };

    case bankConst.SET_BANK_LIST:
      return { ...state, bankList: action.bankList };

    case bankConst.SET_BANK_KIT:
      return { ...state, name: action.bankKit.name, logo: action.bankKit.logo };

    case bankConst.GET_BANK_KIT:
    case bankConst.GET_BANK_KIT_PENDING:
      return { ...state, name: '', logo: '' };

    case bankConst.GET_BANK_KIT_FULFILLED: {
      let bankData = action.payload.data.data.bank;
      const featuresIfUserIsNotLogged = bankData.features;
      return {
        ...state,
        name: bankData.name,
        logo: bankData.logo,
        theme: bankData.theme,
        legal_notice_link: bankData.legal_notice_link,
        features:
          action.payload.user && action.payload.user.logged
            ? bankData.features
            : featuresIfUserIsNotLogged,
        featuresAsInvestor:
          action.payload.user && action.payload.user.logged
            ? bankData.featuresAsInvestor || []
            : [],
        featuresAsCompany:
          action.payload.user && action.payload.user.logged ? bankData.featuresAsCompany || [] : [],
        minimum_total_invest: action.payload.data.data.minimum_total_invest,
        charged_account: bankData.charged_account,
        trading_account: bankData.trading_account,
        draft_owner: bankData.draft_owner,
        membership_code: bankData.membership_code,
        hasFundInvestors: bankData.hasFundInvestors,
        operationTypeList: bankData.operationTypeList,
        agencyList: bankData.agencyList,
        value: bankData.value,
      };
    }

    case bankConst.GET_BANK_AGENCY_LIST_FULFILLED:
      return { ...state, agencyList: action.payload.data };

    case bankConst.GET_BANK_AGENCY_LIST:
    case bankConst.GET_BANK_AGENCY_LIST_PENDING:
      return { ...state, agencyList: [] };

    case bankConst.SET_BANK_AGENCY_LIST:
      return { ...state, agencyList: action.bankAgencyList };

    case bankConst.SET_CURRENT_BANK_AGENCY:
      return { ...state, currentBankAgency: action.currentBankAgency };

    case bankConst.GET_USERS_BANK_LIST_FULFILLED:
      return { ...state, advisorsList: action.payload.data };

    case bankConst.GET_USERS_BANK_LIST:
    case bankConst.GET_USERS_BANK_LIST_PENDING:
      return { ...state, advisorsList: [] };

    default:
      return state;
  }
};

export default bankReducer;
