// Const
import * as SSOConst from '../actions/const/sso.const';
import * as commonConst from '../actions/const/common.const';

const initState = {
  enabled: false,
  connectedWithSSO: false,
};

const SSOReducer = (state = initState, action) => {
  switch (action.type) {
    case commonConst.INIT_STATE:
      return initState;

    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_LOGOUT_PENDING:
      return {
        ...initState,
      };

    case SSOConst.GET_SSO_STATUS:
    case SSOConst.GET_SSO_STATUS_PENDING:
    case SSOConst.GET_SSO_STATUS_REJECTED:
    case SSOConst.GET_SSO_STATUS_BY_EMAIL_REJECTED:
      return {
        ...state,
        enabled: false,
        roles: [],
      };

    case SSOConst.GET_SSO_STATUS_BY_EMAIL_FULFILLED:
    case SSOConst.GET_SSO_STATUS_FULFILLED:
      return {
        ...state,
        enabled: action.payload.data.data.enabled,
        redirectUrl: action.payload.data.data.redirectUrl,
        roles: action.payload.data.data.roles,
      };

    case SSOConst.CONNECT_SSO_FULFILLED:
      return { ...state, connectedWithSSO: true };

    case SSOConst.CONNECT_SSO_REJECTED:
      return { ...state, connectedWithSSO: false };

    default:
      return state;
  }
};

export default SSOReducer;
