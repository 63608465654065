import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import Password from '../components/Password';

// Actions
import { setOriginalJourneyId, setJourney } from '../../../redux/actions';
import { setUserEmail } from '../../../shared/actions/actions/user.actions';
import { initView } from '../actions/password.actions';

const Container = props => {
  const state = useSelector(state => ({
    user: state.user,
    bank: state.bank,
  }));
  const dispatch = useDispatch();
  return (
    <Password
      {...props}
      {...state}
      setOriginalJourneyId={id => dispatch(setOriginalJourneyId(id))}
      setUserEmail={email => dispatch(setUserEmail(email))}
      setJourney={journeyName => dispatch(setJourney(journeyName))}
      initView={() => dispatch(initView())}
    />
  );
};

export default withRouter(Container);
