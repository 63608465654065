import * as fundAppealConst from '../const/fund-appeal.const';

const defaultState = {
  isLoading: false,
  simulateFundAppeal: [],
  fundAppealForm: {
    fundName: [],
    appealRate: '',
    bank: [],
    paymentDate: null,
    sharePremium: [],
  },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case fundAppealConst.SET_SIMULATE_FUND_APPEAL:
      return {
        ...state,
        simulateFundAppeal: action.fundAppeal,
      };
    default:
      return state;
  }
};

export default reducer;
