import React from 'react';
import PropTypes from 'prop-types';
import IconChatSvg from './svg/icon-chat-svg';
import './styles.scss';

const IconChat = props => {
  const { title, alt, width, height, active } = props;

  return (
    <div className={active ? 'chat__active' : 'chat'}>
      <IconChatSvg
        className={active ? 'chat__active__icon' : 'chat__icon'}
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconChat.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default IconChat;
