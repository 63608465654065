export const INIT_STATE = 'MODAL_ADD_SHARED_DOC_INIT_STATE';
export const INIT_PARTIAL_STATE = 'MODAL_ADD_SHARED_DOC_INIT_PARTIAL_STATE';
export const SET_IS_OPEN = 'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_IS_OPEN';
export const SET_STEP = 'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_STEP';
export const SET_MY_REACT_TABLE = 'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_MY_REACT_TABLE';
export const SET_SEND_RESULT = 'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_SEND_RESULT';
export const SET_SEND_RESULT_PENDING =
  'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_SEND_RESULT_PENDING';
export const SET_SEND_RESULT_FULFILLED =
  'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_SEND_RESULT_FULFILLED';
export const SET_SEND_RESULT_REJECTED =
  'MODAL_ADD_SHARED_DOC_SET_MODAL_ADD_SHARED_DOC_SEND_RESULT_REJECTED';
export const GET_INVESTMENTS_PENDING = 'MODAL_ADD_SHARED_DOC_GET_INVESTMENTS_PENDING';
export const GET_INVESTMENTS_FULFILLED = 'MODAL_ADD_SHARED_DOC_GET_INVESTMENTS_FULFILLED';
export const GET_INVESTMENTS_REJECTED = 'MODAL_ADD_SHARED_DOC_GET_INVESTMENTS_REJECTED';
export const GET_LISTS_PENDING = 'MODAL_ADD_SHARED_DOC_GET_LISTS_PENDING';
export const GET_LISTS_FULFILLED = 'MODAL_ADD_SHARED_DOC_GET_LISTS_FULFILLED';
export const GET_LISTS_REJECTED = 'MODAL_ADD_SHARED_DOC_GET_LISTS_REJECTED';
export const SET_SHARED_DOCUMENTS_LIST = 'MODAL_ADD_SHARED_DOC_DOCUMENTS_LIST';
export const SET_SHARED_CURRENT_DOCUMENT = 'MODAL_ADD_SHARED_DOC_CURRENT_DOCUMENT';
export const SET_SHARED_CURRENT_OPERATION_TYPE = 'MODAL_ADD_SHARED_DOC_CURRENT_OPERATION_TYPE';
export const SET_SHARED_DELETE_CURRENT_DOCUMENT = 'MODAL_ADD_SHARED_DOC_DELETE_CURRENT_DOCUMENT';
export const SET_CURRENT_COMPANY = 'MODAL_ADD_SHARED_DOC_SET_CURRENT_COMPANY';
export const SET_SHARED_OPERATIONS_LIST = 'SET_SHARED_OPERATIONS_LIST';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const GET_CURRENT_OPERATIONS = 'GET_CURRENT_OPERATIONS';
export const INVESTMENTS_LIST_MAX_ITEM_SELECTED = 200;
export const INVESTMENTS_LIST_DEFAULT_PAGE_RANGE = 20;
export const GET_COMPANIES_PENDING = 'MODAL_ADD_SHARED_DOC_GET_COMPANIES_PENDING';
export const GET_COMPANIES_FULFILLED = 'MODAL_ADD_SHARED_DOC_GET_COMPANIES_FULFILLED';
export const GET_COMPANIES_REJECTED = 'MODAL_ADD_SHARED_DOC_GET_COMPANIES_REJECTED';
