import React from 'react';
import { ACCESSIBILITY_TYPE, StatefulTooltip } from 'baseui/tooltip';
import { Block } from 'baseui/block';
import PropTypes from 'prop-types';
import styles from './styles';

const Tooltip = props => {
  const { children, content, placement, overrides, mountNode, ignoreBoundary } = props;
  return (
    <StatefulTooltip
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      animateOutTime={500}
      autoFocus
      content={content}
      ignoreBoundary={ignoreBoundary}
      mountNode={mountNode}
      onMouseEnterDelay={500}
      overrides={overrides ? overrides : styles.tooltip}
      placement={placement}
      returnFocus
      showArrow
    >
      <Block overrides={overrides ? overrides : styles.block} tabIndex={0}>
        {children}
      </Block>
    </StatefulTooltip>
  );
};

Tooltip.defaultProps = {
  accessibilityType: 'tooltip',
  placement: 'auto',
  showArrow: true,
  returnFocus: true,
};

Tooltip.propTypes = {
  children: PropTypes.isRequired,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
  overrides: PropTypes.object,
  mountNode: PropTypes.node,
  ignoreBoundary: PropTypes.bool,
};

export default Tooltip;
