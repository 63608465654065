import axios from 'axios';
import { bugReport } from '../utils/bug-report';

export const post = (url, param, codeStatus, config = null) => {
  const options = {
    method: 'POST',
    data: param,
    url,
  };
  // add config opts if provided
  if (config) {
    Object.keys(config).forEach(key => {
      options[key] = config[key];
    });
  }
  // call
  return axios(options).catch(error => {
    bugReportErrorHandler(error, codeStatus);
  });
};

export const get = (url, codeStatus) => {
  return axios.get(url).catch(error => {
    bugReportErrorHandler(error, codeStatus);
  });
};

export const getAsBlob = (url, codeStatus) => {
  return axios.get(url, { responseType: 'blob' }).catch(error => {
    bugReportErrorHandler(error, codeStatus);
  });
};

export const put = (url, param, codeStatus) => {
  return axios.put(url, param).catch(error => {
    bugReportErrorHandler(error, codeStatus);
  });
};

export const patch = (url, param, codeStatus) => {
  return axios.patch(url, param).catch(error => {
    bugReportErrorHandler(error, codeStatus);
  });
};

export const deleteMethod = (url, data, codeStatus) => {
  return axios
    .delete(url, {
      data,
    })
    .catch(error => {
      bugReportErrorHandler(error, codeStatus);
    });
};

/**
 * Handle error sent by the backend and send a bug report if needed
 * @param {object} error the error sent by the backend
 * @param {array} statusListToSkip if empty, process all error code otherwise only the provided status code(s)
 */
const bugReportErrorHandler = (error, statusListToSkip) => {
  const errorMessageToSkip = [
    'No JWT provided. Please see the documentation',
    'Invalid JWT provided',
    'invalid token',
    'no token',
  ];
  const handleSpecificErrorMessage =
    error.response &&
    error.response.data &&
    error.response.data.message &&
    errorMessageToSkip.includes(error.response.data.message);

  const handleAllErrorCodes =
    Array.isArray(statusListToSkip) && statusListToSkip.length === 0 && !handleSpecificErrorMessage;

  const handleSpecificErrorCodes =
    !handleSpecificErrorMessage &&
    !handleAllErrorCodes &&
    error.response &&
    Array.isArray(statusListToSkip) &&
    !statusListToSkip.includes(error.response.status);

  // send bug report
  if (handleAllErrorCodes || handleSpecificErrorCodes) {
    bugReport(error);
  }
  // Redirect to /login if we are not connected.
  if (handleSpecificErrorMessage) {
    // Redirect only if we are not on /login
    if (window.location.pathname !== '/login') {
      window.location = '/login?redirect=true';
    }
  }

  throw error;
};
