import React from 'react';
import PropTypes from 'prop-types';

const IconEmailProblemSvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 24 24" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M13.09 18H4V8l8 5 8-5v5.09c.72.12 1.39.37 2 .72V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9.09c-.05-.33-.09-.66-.09-1 0-.34.04-.67.09-1M20 6l-8 5-8-5h16m.41 13l2.13 2.12-1.42 1.42L19 20.41l-2.12 2.13-1.41-1.42L17.59 19l-2.12-2.12 1.41-1.41L19 17.59l2.12-2.12 1.42 1.41L20.41 19z" />
    </svg>
  );
};

IconEmailProblemSvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailProblemSvg;
