// Api
import { getPlaceDetailsById } from '../../../shared/api/common.api';
import {
  retrieveCompaniesLegalForms,
  retrieveCompaniesBossStatus,
} from '../../../shared/api/company.api';

// Constants
import * as InvestDetailsConst from './invest-details.const';

// Helpers
import { dispatchChangeToInvestDetailsForm } from '../../../shared/utils/CatalizrFormUtils';
import { getPlacesDetails } from '../../../shared/utils/places';
import {
  setLegalFormsList,
  setBossStatusList,
} from '../../../shared/actions/actions/company.actions';
import {
  retrieveCompartments,
  retrieveNominativeStatuses,
  getDistributionFeeBanks,
} from '../../../shared/api/investment.api';
import {
  setCompartments,
  setNominativeStatuses,
  setDistributionFeeBanks,
} from '../../../shared/actions/actions/investment.actions';

// ----------------------------------------------------------------------------------------------
// COMMON REDUX ACTIONS
// ----------------------------------------------------------------------------------------------

export const initFormPending = () => ({ type: InvestDetailsConst.INIT_FORM_PENDING });
export const initFormFulfilled = () => ({ type: InvestDetailsConst.INIT_FORM_FULFILLED });
export const initFormRejected = () => ({ type: InvestDetailsConst.INIT_FORM_REJECTED });

export const submitFormPending = () => ({ type: InvestDetailsConst.SUBMIT_FORM_PENDING });
export const submitFormFulfilled = () => ({ type: InvestDetailsConst.SUBMIT_FORM_FULFILLED });
export const submitFormRejected = () => ({ type: InvestDetailsConst.SUBMIT_FORM_REJECTED });

export const contactSupportPending = () => ({ type: InvestDetailsConst.CONTACT_SUPPORT_PENDING });
export const contactSupportFulfilled = () => ({
  type: InvestDetailsConst.CONTACT_SUPPORT_FULFILLED,
});
export const contactSupportRejected = () => ({ type: InvestDetailsConst.CONTACT_SUPPORT_REJECTED });

export const setInvestmentData = investment => ({
  type: InvestDetailsConst.SET_INVESTMENT_DATA,
  investment,
});

export const setInvestorData = investor => ({
  type: InvestDetailsConst.SET_INVESTOR_DATA,
  investor,
});

export const setCompanyData = company => ({ type: InvestDetailsConst.SET_COMPANY_DATA, company });

export const setFinancialBrokerData = financialBroker => ({
  type: InvestDetailsConst.SET_FINANCIAL_BROKER_DATA,
  data: financialBroker,
});

export const setSirenNotUsed = sirenNotUsed => ({
  type: InvestDetailsConst.SET_COMPANY_SIREN_NOT_USED,
  sirenNotUsed,
});

export const setInvestDetailsError = errorMessage => ({
  type: InvestDetailsConst.SET_INVEST_DETAILS_ERROR,
  errorMessage,
});

export const clearInvestDetailsError = () => ({
  type: InvestDetailsConst.CLEAR_INVEST_DETAILS_ERROR,
});

// ----------------------------------------------------------------------------------------------
// COMMON FUNCTIONS FOR THE INVEST DETAILS FORM
// ----------------------------------------------------------------------------------------------

/**
 * Retrieve the details of the provided place ID (address, zip & city) and dispatch the result to the redux form.
 * @param {*} id the ID of the place to look for
 * @param {*} reduxObjectName the name of the object to store place data into redux form
 */
export const getPlaceDetails = (id, reduxObjectName) => dispatch => {
  if (id && reduxObjectName) {
    getPlaceDetailsById(id).then(res => {
      const { address, zip, city, country } = getPlacesDetails(res.data);
      dispatchChangeToInvestDetailsForm(reduxObjectName + '.address', address, dispatch);
      dispatchChangeToInvestDetailsForm(reduxObjectName + '.zip', zip, dispatch);
      dispatchChangeToInvestDetailsForm(reduxObjectName + '.city', city, dispatch);
      dispatchChangeToInvestDetailsForm(reduxObjectName + '.country', country, dispatch);
    });
  }
};

/**
 * Handles errors coming from the invest-details-form.
 * Dispatches error message to redux
 * @param {*} error stack error received from the validate route
 * @param {func} dispatch the redux dispatch function
 */
export const handleValidateErrors = (error, dispatch) => {
  if (error.response && error.response.status === 500) {
    dispatch(setInvestDetailsError('INTERNAL_SERVER_ERROR'));
  } else if (error.response && error.response.data) {
    dispatch(setInvestDetailsError(error.response.data.message));
  }
};

/**
 * Retrieves the list of legal forms and boss statuses and store it into redux
 * (company & financialBroker reducers)
 * @param {func} dispatch the redux dispatch function
 */
export const initLegalFormsAndBossStatuses = () => async dispatch => {
  // retrieve informations about legal forms
  const companiesLegalForms = await retrieveCompaniesLegalForms();
  if (companiesLegalForms.data && companiesLegalForms.data.data) {
    dispatch(setLegalFormsList(companiesLegalForms.data.data));
  }
  // retrieve informations about boss status
  const companiesBossStatus = await retrieveCompaniesBossStatus();
  if (companiesBossStatus.data && companiesBossStatus.data.data) {
    dispatch(setBossStatusList(companiesBossStatus.data.data));
  }

  const compartments = await retrieveCompartments();
  if (compartments.data) {
    dispatch(setCompartments(compartments.data));
  }

  const nominativeStatuses = await retrieveNominativeStatuses();
  if (nominativeStatuses.data) {
    dispatch(setNominativeStatuses(nominativeStatuses.data));
  }

  const distributionFeeBanks = await getDistributionFeeBanks();
  if (distributionFeeBanks.data) {
    dispatch(setDistributionFeeBanks(distributionFeeBanks.data));
  }
};
