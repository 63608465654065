import React from 'react';
import PropTypes from 'prop-types';
import IconEmailNoStatusSvg from './svg/icon-email-no-status-svg';
import './styles.scss';

const IconEmailNoStatus = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="email__no__status">
      <IconEmailNoStatusSvg
        className="email__no__status__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconEmailNoStatus.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailNoStatus;
