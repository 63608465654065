import React from 'react';
import PropTypes from 'prop-types';
import IconAlertTriangleSvg from './svg/icon-alert-triangle-svg';
import './styles.scss';

const IconAlertTriangle = props => {
  const { title, alt, width, height } = props;

  return (
    <div className="alert__triangle">
      <IconAlertTriangleSvg
        className="alert__triangle__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconAlertTriangle.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconAlertTriangle;
