import { withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';

const CustomSpinner = withStyle(Spinner, ({ $theme }) => {
  return {
    width: '50px',
    height: '50px',
    marginRight: '10px',
    borderLeftWidth: '6px',
    borderRightWidth: '6px',
    borderTopWidth: '6px',
    borderBottomWidth: '6px',
    borderTopColor: $theme.colors.primary,
  };
});

export default CustomSpinner;
