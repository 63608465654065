import React from 'react';
import PropTypes from 'prop-types';

import IconLoader from '../../assets/images/loader.gif';

import './styles.scss';

const Loader = props => (
  <div className={`${props.isFullScreen ? 'loader__content--full-screen' : ''}`}>
    <img src={IconLoader} width="50" height="50" alt="loader" />
  </div>
);

Loader.defaultProps = {
  isFullScreen: false,
};

Loader.propTypes = {
  isFullScreen: PropTypes.bool,
};

export default Loader;
