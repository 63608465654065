export const INIT_DASHBOARD_STATE = 'DASHBOARD_INIT_DASHBOARD_STATE';
export const INIT_DASHBOARD_COUNTERS = 'GET_COUNTERS';
export const INIT_DASHBOARD_COUNTERS_FULFILLED = 'GET_COUNTERS_FULFILLED';
export const GET_INVESTMENTS_EXPORT = 'DASHBOARD_GET_INVESTMENTS_EXPORT';
export const GET_INVESTMENTS = 'DASHBOARD_GET_INVESTMENTS';
export const GET_INVESTMENTS_PENDING = 'DASHBOARD_GET_INVESTMENTS_PENDING';
export const GET_INVESTMENTS_FULFILLED = 'DASHBOARD_GET_INVESTMENTS_FULFILLED';
export const GET_INVESTMENTS_REJECTED = 'DASHBOARD_GET_INVESTMENTS_REJECTED';
export const GET_SUGGESSTED_INVESTMENTS = 'DASHBOARD_GET_SUGGESSTED_INVESTMENTS';
export const GET_SUGGESSTED_INVESTMENTS_FULFILLED =
  'DASHBOARD_GET_SUGGESSTED_INVESTMENTS_FULFILLED';
export const SET_SELECTED_SUGGESTED_OPERATION = 'SET_SELECTED_SUGGESTED_OPERATION';

export const GET_WALLET = 'DASHBOARD_GET_WALLET';
export const GET_WALLET_PENDING = 'DASHBOARD_GET_WALLET_PENDING';
export const GET_WALLET_FULFILLED = 'DASHBOARD_GET_WALLET_FULFILLED';
export const GET_WALLET_REJECTED = 'DASHBOARD_GET_WALLET_REJECTED';
export const INIT_COMPANY = 'DASHBOARD_INIT_COMPANY'; //FIXME: useless ?
export const INIT_INVESTOR = 'FOLLOW_UP_INVESTOR_INIT_INVESTOR';
export const INIT_VIEW = 'DASHBOARD_INIT_VIEW';
export const INVESTMENTS_LIST_DEFAULT_PAGE_RANGE = 10;
export const INVESTMENTS_LIST_DEFAULT_SORT = '';
export const SET_SEARCH = 'DASHBOARD_SET_SEARCH';
export const DISPLAY_INVESTMENT_DETAILS_DASHBOARD = 'DISPLAY_INVESTMENT_DETAILS_DASHBOARD';
export const EDIT_INVESTMENT_DETAILS_DASHBOARD = 'EDIT_INVESTMENT_DETAILS_DASHBOARD';
export const SET_FILTER = 'SET_FILTER';
export const GET_FURTHEST_STEP = 'GET_FURTHEST_STEP';
export const GET_FURTHEST_STEP_PENDING = 'GET_FURTHEST_STEP_PENDING';
export const GET_FURTHEST_STEP_FULFILLED = 'GET_FURTHEST_STEP_FULFILLED';
export const GET_FURTHEST_STEP_REJECTED = 'GET_FURTHEST_STEP_REJECTED';
export const GET_TRANSMIT = 'FOLLOW_UP_ADVISOR_GET_TRANSMIT';
export const GET_TRANSMIT_PENDING = 'FOLLOW_UP_ADVISOR_GET_TRANSMIT_PENDING';
export const GET_TRANSMIT_FULFILLED = 'FOLLOW_UP_ADVISOR_GET_TRANSMIT_FULFILLED';
export const GET_TRANSMIT_REJECTED = 'FOLLOW_UP_ADVISOR_GET_TRANSMIT_REJECTED';
export const GET_CANCELLED = 'FOLLOW_UP_ADVISOR_GET_CANCELLED';
export const GET_CANCELLED_PENDING = 'FOLLOW_UP_ADVISOR_GET_CANCELLED_PENDING';
export const GET_CANCELLED_FULFILLED = 'FOLLOW_UP_ADVISOR_GET_CANCELLED_FULFILLED';
export const GET_CANCELLED_REJECTED = 'FOLLOW_UP_ADVISOR_GET_CANCELLED_REJECTED';
export const SET_CANCELLED_MODAL_IS_OPEN = 'FOLLOW_UP_SET_CANCELLED_MODAL_IS_OPEN';
export const SET_DELETE_MODAL_IS_OPEN = 'FOLLOW_UP_SET_DELETE_MODAL_IS_OPEN';
export const SET_ROLLBACK_MODAL_IS_OPEN = 'FOLLOW_UP_SET_ROLLBACK_MODAL_IS_OPEN';
export const SET_FORWARD_MODAL_IS_OPEN = 'FOLLOW_UP_SET_FORWARD_MODAL_IS_OPEN';
export const SET_PROOF_PAYMENT_MODAL_IS_OPEN = 'FOLLOW_UP_SET_PROOF_PAYMENT_MODAL_IS_OPEN';
export const RESET_CANCELLED_ERROR_MSG = 'FOLLOW_UP_RESET_CANCELLED_ERROR_MSG';
export const GET_OPERATION_COMMENTS = 'GET_OPERATION_COMMENTS';
export const GET_OPERATION_COMMENTS_PENDING = 'GET_OPERATION_COMMENTS_PENDING';
export const GET_OPERATION_COMMENTS_FULFILLED = 'GET_OPERATION_COMMENTS_FULFILLED';
export const GET_OPERATION_COMMENTS_REJECTED = 'GET_OPERATION_COMMENTS_REJECTED';
export const POST_OPERATION_COMMENT = 'POST_OPERATION_COMMENT';
export const POST_OPERATION_COMMENT_PENDING = 'POST_OPERATION_COMMENT_PENDING';
export const POST_OPERATION_COMMENT_FULFILLED = 'POST_OPERATION_COMMENT_FULFILLED';
export const POST_OPERATION_COMMENT_REJECTED = 'POST_OPERATION_COMMENT_REJECTED';
export const SET_CURRENT_COMMENT = 'SET_CURRENT_COMMENT';
export const RESET_CURRENT_COMMENT_AFTER_SUBMIT = 'RESET_CURRENT_COMMENT_AFTER_SUBMIT';
export const DASHBOARD_SET_CURRENT_TAB = 'DASHBOARD_SET_CURRENT_TAB';
export const DASHBOARD_SET_OPENED_FILTERS = 'DASHBOARD_SET_OPENED_FILTERS';
export const DASHBOARD_FILTERS_DATA = 'DASHBOARD_FILTERS_DATA';
export const DASHBOARD_FILTERS_TO_APPLY = 'DASHBOARD_FILTERS_TO_APPLY';
export const DASHBOARD_RESET_FILTERS = 'DASHBOARD_RESET_FILTERS';
export const DASHBOARD_SET_CURRENT_PAGE = 'DASHBOARD_SET_CURRENT_PAGE';
export const DASHBOARD_SET_PER_PAGE = 'DASHBOARD_SET_PER_PAGE';
export const DASHBOARD_SET_CURRENT_SORT = 'DASHBOARD_SET_CURRENT_SORT';
export const REFRESH_REDUX_DATA = 'REFRESH_REDUX_DATA';
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAILS_PENDING = 'GET_EMAILS_PENDING';
export const GET_EMAILS_FULFILLED = 'GET_EMAILS_FULFILLED';
export const GET_EMAILS_REJECTED = 'GET_EMAILS_REJECTED';
export const EMAIL_MODAL_IS_OPEN = 'EMAIL_MODAL_IS_OPEN';
export const RESEND_EMAIL_PENDING = 'RESEND_EMAIL_PENDING';
export const RESEND_EMAIL_FULFILLED = 'RESEND_EMAIL_FULFILLED';
export const RESEND_EMAIL_REJECTED = 'RESEND_EMAIL_REJECTED';
export const GET_LATEST_STEP = 'GET_LATEST_STEP';
export const GET_LATEST_STEP_PENDING = 'GET_LATEST_STEP_PENDING';
export const GET_LATEST_STEP_FULFILLED = 'GET_LATEST_STEP_FULFILLED';
export const GET_LATEST_STEP_REJECTED = 'GET_LATEST_STEP_REJECTED';
export const EDIT_COMPANY_EMAIL_DASHBOARD = 'EDIT_COMPANY_EMAIL_DASHBOARD';
export const EDIT_INVEST_CATEGORY_DASHBOARD = 'EDIT_INVEST_CATEGORY_DASHBOARD';
export const EDIT_COMPANY_INFO = 'EDIT_COMPANY_INFO';
export const EDIT_INVEST_ORIGIN_DASHBOARD = 'EDIT_INVEST_ORIGIN_DASHBOARD';
export const SET_CLOSED_MODAL_IS_OPEN = 'SET_CLOSED_MODAL_IS_OPEN';
export const SET_ISIN_CLOSED_MODAL_IS_OPEN = 'SET_ISIN_CLOSED_MODAL_IS_OPEN';
export const SET_ATTESTATION_MODAL_IS_OPEN = 'SET_ATTESTATION_MODAL_IS_OPEN';

export const GET_SHARES_VALUATION_POSITIONS_LIST = 'GET_SHARES_VALUATION_POSITIONS_LIST';
export const GET_SHARES_VALUATION_POSITIONS_LIST_FULFILLED =
  'GET_SHARES_VALUATION_POSITIONS_LIST_FULFILLED';
export const GET_SHARES_VALUATION_POSITIONS_LIST_PENDING =
  'GET_SHARES_VALUATION_POSITIONS_LIST_PENDING';
export const GET_SHARES_VALUATION_POSITIONS_LIST_REJECTED =
  'GET_SHARES_VALUATION_POSITIONS_LIST_REJECTED';
export const GET_SHARES_VALUATION_LIST = 'GET_SHARES_VALUATION_LIST';

export const GET_SHARES_VALUATION_LIST_FULFILLED = 'GET_SHARES_VALUATION_LIST_FULFILLED';
export const GET_SHARES_VALUATION_LIST_PENDING = 'GET_SHARES_VALUATION_LIST_PENDING';
export const GET_SHARES_VALUATION_LIST_REJECTED = 'GET_SHARES_VALUATION_LIST_REJECTED';
export const SET_SHARES_VALUATION_BACKOFFICE_MODAL_IS_OPEN =
  'SET_SHARES_VALUATION_BACKOFFICE_MODAL_IS_OPEN';
export const SET_SHARES_VALUATION_BACKOFFICE_CAMPAIN_RUNNING =
  'SET_SHARES_VALUATION_BACKOFFICE_CAMPAIN_RUNNING';
export const SET_SHARES_VALUATION_DETAILS_INIT_VIEW = 'SET_SHARES_VALUATION_DETAILS_INIT_VIEW';
export const SET_SHARES_VALUATION_DETAILS_INIT_VIEW_PENDING =
  'SET_SHARES_VALUATION_DETAILS_INIT_VIEW_PENDING';
export const SET_SHARES_VALUATION_DETAILS_INIT_VIEW_FULFILLED =
  'SET_SHARES_VALUATION_DETAILS_INIT_VIEW_FULFILLED';
export const SET_SHARES_VALUATION_DETAILS_INIT_VIEW_REJECTED =
  'SET_SHARES_VALUATION_DETAILS_INIT_VIEW_REJECTED';
export const GET_UPDATED_SHARES_VALUATION_BY_COMPANY = 'GET_UPDATED_SHARES_VALUATION_BY_COMPANY';
export const SET_SUBMIT_COMPANY_SHARES_VALUATION = 'SET_SUBMIT_COMPANY_SHARES_VALUATION';
export const SET_SUBMIT_COMPANY_SHARES_VALUATION_PENDING =
  'SET_SUBMIT_COMPANY_SHARES_VALUATION_PENDING';
export const SET_SUBMIT_COMPANY_SHARES_VALUATION_FULFILLED =
  'SET_SUBMIT_COMPANY_SHARES_VALUATION_FULFILLED';
export const SET_SUBMIT_COMPANY_SHARES_VALUATION_REJECTED =
  'SET_SUBMIT_COMPANY_SHARES_VALUATION_REJECTED';
export const DELETE_DOCUMENT_SHARES_VALUATION_PENDING =
  'DELETE_DOCUMENT_COMPANY_SHARES_VALUATION_PENDING';
export const DELETE_DOCUMENT_SHARES_VALUATION_FULFILLED =
  'DELETE_DOCUMENT_COMPANY_SHARES_VALUATION_FULFILLED';
export const DELETE_DOCUMENT_SHARES_VALUATION_REJECTED =
  'DELETE_DOCUMENT_COMPANY_SHARES_VALUATION_REJECTED';
export const GET_PLATINE_PENDING = 'GET_PLATINE_PENDING';
export const GET_PLATINE_FULFILLED = 'GET_PLATINE_FULFILLED';
export const GET_PLATINE_REJECTED = 'GET_PLATINE_REJECTED';

export const GET_FUND_LIST = 'DASHBOARD_GET_FUND_LIST';
export const GET_FUND_LIST_PENDING = 'DASHBOARD_GET_FUND_LIST_PENDING';
export const GET_FUND_LIST_FULFILLED = 'DASHBOARD_GET_FUND_LIST_FULFILLED';
export const GET_FUND_LIST_REJECTED = 'DASHBOARD_GET_FUND_LIST_REJECTED';

export const GET_SHAREHOLDING_LIST = 'DASHBOARD_GET_SHAREHOLDING_LIST';
export const GET_SHAREHOLDING_LIST_PENDING = 'DASHBOARD_GET_SHAREHOLDING_LIST_PENDING';
export const GET_SHAREHOLDING_LIST_FULFILLED = 'DASHBOARD_GET_SHAREHOLDING_LIST_FULFILLED';
export const GET_SHAREHOLDING_LIST_REJECTED = 'DASHBOARD_GET_SHAREHOLDING_LIST_REJECTED';
export const SET_SHAREHOLDING_DETAILS_INIT_VIEW = 'SET_SHAREHOLDING_DETAILS_INIT_VIEW';
export const SET_SHAREHOLDING_DETAILS_INIT_VIEW_PENDING =
  'SET_SHAREHOLDING_DETAILS_INIT_VIEW_PENDING';
export const SET_SHAREHOLDING_DETAILS_INIT_VIEW_FULFILLED =
  'SET_SHAREHOLDING_DETAILS_INIT_VIEW_FULFILLED';
export const SET_SHAREHOLDING_DETAILS_INIT_VIEW_REJECTED =
  'SET_SHAREHOLDING_DETAILS_INIT_VIEW_REJECTED';
export const SEND_SHAREHOLDING_MANUAL_INSERTION = 'SEND_SHAREHOLDING_MANUAL_INSERTION';
export const SEND_SHAREHOLDING_MANUAL_INSERTION_PENDING =
  'SEND_SHAREHOLDING_MANUAL_INSERTION_PENDING';
export const SEND_SHAREHOLDING_MANUAL_INSERTION_FULFILLED =
  'SEND_SHAREHOLDING_MANUAL_INSERTION_FULFILLED';
export const SEND_SHAREHOLDING_MANUAL_INSERTION_REJECTED =
  'SEND_SHAREHOLDING_MANUAL_INSERTION_REJECTED';
export const DELETE_SHAREHOLDING_MANUALLY_INSERTED = 'DELETE_SHAREHOLDING_MANUALLY_INSERTED';
export const DELETE_SHAREHOLDING_MANUALLY_INSERTED_PENDING =
  'DELETE_SHAREHOLDING_MANUALLY_INSERTED_PENDING';
export const DELETE_SHAREHOLDING_MANUALLY_INSERTED_REJECTED =
  'DELETE_SHAREHOLDING_MANUALLY_INSERTED_REJECTED';
export const DELETE_SHAREHOLDING_MANUALLY_INSERTED_FULFILLED =
  'DELETE_SHAREHOLDING_MANUALLY_INSERTED_FULFILLED';

export const SET_POSITIONS_DETAILS_INIT_VIEW = 'SET_POSITIONS_DETAILS_INIT_VIEW';
export const SET_POSITIONS_DETAILS_INIT_VIEW_PENDING = 'SET_POSITIONS_DETAILS_INIT_VIEW_PENDING';
export const SET_POSITIONS_DETAILS_INIT_VIEW_FULFILLED =
  'SET_POSITIONS_DETAILS_INIT_VIEW_FULFILLED';
export const SET_POSITIONS_DETAILS_INIT_VIEW_REJECTED = 'SET_POSITIONS_DETAILS_INIT_VIEW_REJECTED';
export const GET_INVESTOR_UPDATED_POSITIONS_BY_COMPANY =
  'GET_INVESTOR_UPDATED_POSITIONS_BY_COMPANY';
export const SEND_POSITIONS_UPDATED = 'SEND_POSITIONS_UPDATED';
export const SEND_POSITIONS_UPDATED_PENDING = 'SEND_POSITIONS_UPDATED_PENDING';
export const SEND_POSITIONS_UPDATED_REJECTED = 'SEND_POSITIONS_UPDATED_REJECTED';
export const SEND_POSITIONS_UPDATED_FULFILLED = 'SEND_POSITIONS_UPDATED_FULFILLED';
export const ADD_NEW_INVESTOR_POSITION = 'ADD_NEW_INVESTOR_POSITION';
export const ADD_NEW_INVESTOR_POSITION_PENDING = 'ADD_NEW_INVESTOR_POSITION_PENDING';
export const ADD_NEW_INVESTOR_POSITION_REJECTED = 'ADD_NEW_INVESTOR_POSITION_REJECTED';
export const ADD_NEW_INVESTOR_POSITION_FULFILLED = 'ADD_NEW_INVESTOR_POSITION_FULFILLED';

// OPERATION TEMPLATES (specific to backoffice)
export const GET_OPERATION_TEMPLATES = 'GET_OPERATION_TEMPLATES';
export const GET_OPERATION_TEMPLATES_PENDING = 'GET_OPERATION_TEMPLATES_PENDING';
export const GET_OPERATION_TEMPLATES_FULFILLED = 'GET_OPERATION_TEMPLATES_FULFILLED';
export const GET_OPERATION_TEMPLATES_REJECTED = 'GET_OPERATION_TEMPLATES_REJECTED';

export const CLOSE_OPERATION_ERROR_MESSAGE = 'CLOSE_OPERATION_ERROR_MESSSAGE';

export const SET_OPERATION_FLAG_STATUS = 'SET_OPERATION_FLAG_STATUS';
export const SET_OPERATION_FLAG_STATUS_PENDING = 'SET_OPERATION_FLAG_STATUS_PENDING';
export const SET_OPERATION_FLAG_STATUS_FULFILLED = 'SET_OPERATION_FLAG_STATUS_FULFILLED';
export const SET_OPERATION_FLAG_STATUS_REJECTED = 'SET_OPERATION_FLAG_STATUS_REJECTED';

export const EDIT_SUGGESTED_OPERATION_PENDING = 'EDIT_SUGGESTED_OPERATION_PENDING';
export const EDIT_SUGGESTED_OPERATION_FULFILLED = 'EDIT_SUGGESTED_OPERATION_FULFILLED';
export const EDIT_SUGGESTED_OPERATION_REJECTED = 'EDIT_SUGGESTED_OPERATION_REJECTED';

export const STATUS_DATE = {
  ON_HOLD: 'ON_HOLD',
  CONFIRMED: 'CONFIRMED',
};
