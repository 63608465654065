import * as React from 'react';
import PropTypes from 'prop-types';

const IconMoneySvg = ({ alt, title, width, height, ...props }) => {
  return (
    <svg viewBox="0 0 16 16" role="img" aria-label={alt} width={width} height={height} {...props}>
      <title>{title}</title>
      <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0010.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
    </svg>
  );
};

IconMoneySvg.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconMoneySvg;
