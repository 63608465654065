import React from 'react';
import PropTypes from 'prop-types';
import IconEmailProblemSvg from './svg/icon-email-problem-svg';
import './styles.scss';

const IconEmailProblem = props => {
  const { title, alt, width, height } = props;
  return (
    <div className="email__problem">
      <IconEmailProblemSvg
        className="email__problem__icon"
        title={title}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

IconEmailProblem.propTypes = {
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default IconEmailProblem;
